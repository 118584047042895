import Image from "next/image";

import { Container } from "./Tagline.styled";

export interface TaglineProps {
    isSpinning?: boolean;
    className?: string;
}

export function Tagline({
    className,
    isSpinning = false
}: TaglineProps): JSX.Element {
    return (
        <Container isSpinning={isSpinning} className={className}>
            <Image
                src="/images/now-were-tokkin.svg"
                width={376}
                height={376}
                alt="tagline"
                role="presentation"
                layout="responsive"
            />
        </Container>
    );
}
