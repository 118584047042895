import styled, { css } from "styled-components";

import { headerThemeOptions } from "components/layout/Layout";

import { media } from "constants/media";

function selectMenuButtonColor(
    theme: headerThemeOptions.light | headerThemeOptions.dark
) {
    switch (theme) {
        case headerThemeOptions.light:
            return css`
                > svg > path:first-of-type {
                    stroke: var(--color-brown-50);
                }

                > svg > path:last-of-type {
                    fill: var(--color-brown-50);
                }
            `;
        case headerThemeOptions.dark:
            return css`
                > svg > path:first-of-type {
                    stroke: var(--color-beige-40);
                }

                > svg > path:last-of-type {
                    fill: var(--color-beige-40);
                }
            `;
        default:
            break;
    }
}

export const Container = styled.div<{ isSticky: boolean; darkMode: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;

    /* stylelint-disable order/properties-alphabetical-order */
    ${({ darkMode, isSticky }) =>
        darkMode &&
        !isSticky &&
        css`
            color: var(--color-beige-40);

            ${media.lgUp} {
                position: relative;

                ::before,
                ::after {
                    background-color: var(--color-brown-50);
                    content: "";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100vw;
                    z-index: -1;
                }

                ::before {
                    left: 0;
                }

                ::after {
                    right: 0;
                }
            }
        `}

    /* stylelint-disable order/order */

    ${({ isSticky }) =>
        isSticky
            ? css`
                  background-color: var(--color-beige-50);
                  box-shadow: 0px 1px 0px var(--color-black);
                  height: 56px;
                  padding: 16px 24px;
                  position: fixed;
                  top: 0;
                  transition: 0.3s all ease-in-out;
                  z-index: 100;

                  ${media.lgUp} {
                      height: 80px;
                  }
              `
            : css`
                  align-items: flex-start;
                  height: 88px;
                  margin: auto;
                  max-width: 1200px;
                  padding: 24px;
                  position: relative;

                  ${media.xlUp} {
                      padding: 24px 0;
                  }
              `};

    ${({ darkMode, isSticky }) =>
        darkMode &&
        isSticky &&
        css`
            background: var(--color-brown-50);
        `}; /* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

export const MenuButton = styled.button<{
    theme: headerThemeOptions.light | headerThemeOptions.dark;
    isSticky: boolean;
    darkMode: boolean;
}>`
    background-color: transparent;
    border: 0;
    padding: 0;
    z-index: 10;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ theme, isSticky }) =>
        theme && isSticky
            ? selectMenuButtonColor(headerThemeOptions.light)
            : selectMenuButtonColor(theme)};

    ${({ darkMode }) =>
        darkMode &&
        css`
            > svg > path:first-of-type {
                stroke: var(--color-beige-40);
            }

            > svg > path:last-of-type {
                fill: var(--color-beige-40);
            }
        `}

    ${media.lgUp} {
        display: none;
    }
`;

export const NavigationDesktop = styled.nav`
    display: none;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;
    position: relative;
    width: 100%;
    z-index: 20;

    ul {
        list-style: none;
    }

    a {
        font-family: var(--font-primary);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }

    ${media.lgUp} {
        display: flex;
    }
`;

export const StyledHeader = styled.header<{ darkMode?: boolean }>`
    background-color: transparent;

    ${({ darkMode }) =>
        darkMode &&
        css`
            background-color: var(--color-brown-50);
        `}
`;
