import { ForwardedRef, forwardRef } from "react";

import {
    ButtonLabel,
    CheckboxInput,
    CheckedButton,
    Label,
    StyledCheckbox,
    SwitchWrapper
} from "components/shared/checkbox/Checkbox.styled";

export interface CheckboxProps {
    className?: string;
    /** Label for the checkbox. */
    label: string;
    /** Name used for tracking the value of the input, used by react-hook-forms. */
    name: string;
    /** Active value for the input, read by react-hook-forms. */
    activeValue: string;
    /** Checkbox is disabled and stuck on its default value */
    disabled?: boolean;
    /** Current value of the checkbox, provided by react-hook-forms `watch(name)` */
    currentValue?: string;
    /** Show unchecked state as red */
    showUncheckedAsRed?: boolean;
    /** Determines if the text of the label is bold */
    withThinLabel?: boolean;
}

export const Checkbox = forwardRef(function Checkbox(
    {
        className,
        label,
        name,
        activeValue,
        currentValue,
        disabled,
        withThinLabel,
        ...otherProps
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return (
        <StyledCheckbox className={className}>
            <CheckboxInput
                disabled={disabled}
                id={name}
                name={name}
                ref={ref}
                readOnly
                type="checkbox"
                value={activeValue}
                {...otherProps}
            />
            <ButtonLabel disabled={disabled} htmlFor={name}>
                <SwitchWrapper disabled={disabled}>
                    <CheckedButton
                        isActive={currentValue === activeValue}
                        disabled={disabled}
                    />
                </SwitchWrapper>
                <Label withThinLabel={withThinLabel} disabled={disabled}>
                    {label}
                </Label>
            </ButtonLabel>
        </StyledCheckbox>
    );
});
