import { SHOPPING_BASKET } from "components";

import { Container } from "./ShoppingBasketLink.styled";

import { useCartContext } from "helpers/context/CartContext";

type Props = {
    darkMode?: boolean;
};

export default function ShoppingBasketLink({ darkMode }: Props): JSX.Element {
    const { cartItemsCount } = useCartContext();

    return (
        <Container
            icon={{ icon: SHOPPING_BASKET, size: 24 }}
            variant={darkMode ? "beige" : "orange"}
            noUnderline
            href="/winkelwagen"
            passHref
        >
            <span>{cartItemsCount || 0}</span>
        </Container>
    );
}
