import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const RemoveItemFromCart = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation removeItemFromCart($input: RemoveItemFromCartInput) {
        removeItemFromCart(input: $input) {
            cart {
                ...CartContent
            }
        }
    }
`;
