import styled, { css } from "styled-components";

import {
    GetLinkStyling,
    disabledLinkStyles
} from "components/shared/link/Link.styled";
import { Icon } from "components/shared/icon/Icon";
import { LinkUnderline } from "components/shared/svg/LinkUnderline";

import { ButtonVariant } from "../buttonElement/ButtonElement";

import { media } from "constants/media";

export const Underline = styled(LinkUnderline)``;

function determineSizeStyling(type: string) {
    switch (type) {
        case "small":
            return css`
                font-size: 16px;
                line-height: 22px;
                min-height: 32px;
                padding: 8px 16px;
            `;
        case "round":
            return css`
                border-radius: 50%;
                font-size: 14px;
                line-height: 22px;
                padding: 10px;
            `;
        case "large":
            return css`
                font-size: 20px;
                line-height: 32px;
                padding: 16px 24px;
            `;
        case "simple":
            return css`
                font-size: 16px;
                line-height: 24px;

                ${media.lgUp} {
                    font-size: 18px;
                    line-height: 32px;
                }
            `;
        case "link": // CHECK PLACES WHERE THIS IS USED TO SEE IF IT"S THE SAME AS DESIGN SINCE I HAD TO CHANGE
            return css`
                font-size: 16px;
                font-weight: normal;
                line-height: 32px;
                padding: 8px 16px;

                ${media.lgUp} {
                    font-size: 20px;
                    line-height: 32px;
                    padding: 16px 24px;
                }
            `;
        default:
            return css`
                font-size: 18px;
                line-height: 32px;
                min-height: 45px;
                padding: 16px 0;
            `;
    }
}

function determineTypeStyling(type: string) {
    switch (type) {
        case "secondary":
            return css`
                background-color: var(--color-brown-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);

                :active {
                    background-color: var(--color-brown-40);
                    outline: 8px solid rgba(62, 35, 19, 0.4);
                }

                :hover {
                    background-color: var(--color-brown-40);
                }
            `;
        case "tertiary":
            return css`
                background-color: var(--color-brown-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);

                :active {
                    background-color: var(--color-brown-40);
                    outline: 8px solid rgba(62, 35, 19, 0.4);
                }

                :hover {
                    background-color: var(--color-brown-40);
                }
            `;
        case "ghost":
            return css`
                background-color: transparent;
                color: var(--color-brown-50);
                font-family: var(--font-secondary);
                outline: 2px solid var(--color-brown-50);

                &&& {
                    &:active {
                        background-color: var(--color-beige-50);
                        color: var(--color-brown-40);
                        outline-color: var(--color-brown-40);
                        outline-width: 4px;
                    }

                    &:hover {
                        color: var(--color-brown-40);
                        outline-color: var(--color-brown-40);
                        outline-width: 4px;
                    }
                }
            `;
        case "transparent":
            return css`
                background-color: transparent;
                padding: 0;
            `;
        case "primary":
            return css`
                background-color: var(--color-red-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);
                font-weight: var(--font-weight-normal);

                :active {
                    background-color: var(--color-red-40);
                    outline: 8px solid var(--color-red-40-transparent);
                }

                :hover {
                    background-color: var(--color-red-40);
                }
            `;
        default:
            return;
    }
}

function selectDisabledStyling(type: string) {
    switch (type) {
        case "ghost":
            return css`
                background-color: transparent;
                color: var(--color-beige-60);
                cursor: default;
                outline: 2px solid #d2b5a1;
                pointer-events: none;

                :active,
                :focus,
                :hover {
                    background-color: transparent;
                }
            `;
        default:
            return css`
                background-color: var(--color-beige-45);
                color: var(--color-beige-60);
                cursor: default;
                pointer-events: none;

                :active,
                :focus,
                :hover {
                    background-color: transparent;
                }
            `;
    }
}

export const StyledButton = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;
`;

export const LinkContent = styled.div<{
    disabled: boolean;
    reversedOrder: boolean;
    buttonType: ButtonVariant;
    size: string;
    variant: string;
}>`
    align-items: center;
    border: 0;
    border-radius: 70px;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    transition: all var(--quick-transition-duration) ease-in-out;
    z-index: 1;

    /* stylelint-disable */
    ${({ variant, buttonType, size }) =>
        buttonType === "link" &&
        variant &&
        GetLinkStyling({
            $isSimple: size === "simple",
            showUnderline: true,
            variant
        })};

    ${({ size }) => determineSizeStyling(size)};
    ${({ buttonType }) =>
        buttonType &&
        buttonType !== "link" &&
        determineTypeStyling(buttonType)};
    ${({ reversedOrder }) =>
        reversedOrder &&
        css`
            flex-direction: row-reverse;
        `};
    ${({ disabled, buttonType }) =>
        disabled && buttonType === "link" && disabledLinkStyles};

    ${({ disabled, buttonType }) =>
        disabled &&
        buttonType &&
        buttonType !== "link" &&
        selectDisabledStyling(buttonType)}; /* stylelint-enable */
`;

export const StyledIconWrapper = styled.div<{
    hasChildren?: boolean;
    reversedOrder?: boolean;
}>`
    /* stylelint-disable order/order */
    align-items: center;
    display: flex;
    height: 100%;

    /* stylelint-disable-next-line */
    ${({ hasChildren, reversedOrder }) =>
        hasChildren &&
        css`
            ${reversedOrder ? "padding-left: 4px" : "padding-right: 4px"};

            ${media.mdUp} {
                ${reversedOrder ? "padding-left: 15px" : "padding-right: 15px"};
            }
        `};
`;

export const StyledIcon = styled(Icon)`
    height: 100%;
`;
