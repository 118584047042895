import { gql } from "@apollo/client";

export const CART_CONTENT_FRAGMENT = gql`
    fragment CartContent on Cart {
        id
        total_quantity
        items {
            uid
            quantity
            prices {
                price {
                    value
                    currency
                }
                row_total {
                    value
                    currency
                }
                row_total_including_tax {
                    value
                    currency
                }
                fixed_product_taxes {
                    amount {
                        value
                        value
                    }
                    label
                }
            }
            product {
                sku
                name
                sku
                url_key
                uid
                price_range {
                    maximum_price {
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
                thumbnail {
                    url
                    label
                }
                categories {
                    uid
                }
                ... on BundleProduct {
                    items {
                        title
                        options {
                            quantity
                            product {
                                name
                                sku
                                price_range {
                                    maximum_price {
                                        regular_price {
                                            value
                                            currency
                                        }
                                        final_price {
                                            value
                                            currency
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ... on BundleCartItem {
                bundle_options {
                    label
                    uid
                    values {
                        id
                        label
                        quantity
                        uid
                        price
                    }
                }
            }
        }
        applied_coupons {
            code
        }
        prices {
            grand_total {
                value
                currency
            }
            subtotal_with_discount_excluding_tax {
                value
                currency
            }
            subtotal_excluding_tax {
                value
                currency
            }
            applied_taxes {
                label
                amount {
                    value
                    currency
                }
            }
        }
        selected_payment_method {
            title
            code
        }
        email
        billing_address {
            firstname
            lastname
            company
            street
            city
            telephone
            postcode
            region {
                code
                label
            }
            postcode
            telephone
            country {
                code
                label
            }
        }
        shipping_addresses {
            firstname
            lastname
            city
            street
            postcode
            telephone
            country {
                code
                label
            }
            available_shipping_methods {
                carrier_title
                carrier_code
                method_code
                method_title
                delivery_options {
                    timeslotValue
                    timeslotLabel
                }
                price_incl_tax {
                    currency
                    value
                }
            }
            selected_shipping_method {
                method_code
                method_title
                carrier_code
                carrier_title
                delivery_options {
                    timeslotValue
                    timeslotLabel
                }
                amount {
                    currency
                    value
                }
            }
        }
        available_payment_methods {
            code
            title
            mollie_meta {
                image
            }
            mollie_available_issuers {
                code
                image
                name
            }
        }
    }
`;
