import { useEffect, useRef } from "react";

import { StyledTrustPilotCarousel } from "components/trustPilotWidgets/carousel/TrustPilotCarousel.styled";

function TrustPilotCarousel(): JSX.Element {
    const ref = useRef(null);
    useEffect(() => {
        window?.Trustpilot?.loadFromElement(ref.current, true);
    }, []);

    return (
        <StyledTrustPilotCarousel>
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="nl-NL"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="64809bfcdc5cb52419b44813"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-stars="4,5"
                data-review-languages="nl"
            >
                <a
                    href="https://nl.trustpilot.com/review/mr-fillet.shop"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Trustpilot
                </a>
            </div>
        </StyledTrustPilotCarousel>
    );
}

export default TrustPilotCarousel;
