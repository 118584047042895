import { SvgProps } from "types/input";

export function KnifesIcon({ width = 64, height = 64 }: SvgProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 64 64"
            width={width}
            height={height}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#E5CCBB"
                    d="M0 32C0 12.204 12.665 0 32 0s32 12.204 32 32-12.675 32-32 32S0 51.778 0 32Z"
                />
                <path
                    fill="#fff"
                    d="m23.912 5 21.57 27.948-12.446 9.605-11.954-15.488a15.721 15.721 0 0 1 2.84-22.051L23.913 5Z"
                />
                <path
                    fill="#fff"
                    d="m55.175 45.517-11.58-15.004-5.15 3.975 11.58 15.004 5.15-3.975Z"
                />
                <path
                    fill="#338E84"
                    d="m15.664 13.322 27.141 22.59-10.057 12.084-15.044-12.521a15.721 15.721 0 0 1-2.027-22.141l-.013-.011Z"
                />
                <path
                    fill="#338E84"
                    d="M55.004 46.075 40.436 33.95l-4.163 5.002 14.568 12.124 4.163-5.001Z"
                />
                <path
                    stroke="#3E2313"
                    strokeMiterlimit="10"
                    d="m9.785 21.194 30.679 17.485-7.785 13.658-17.005-9.691A15.722 15.722 0 0 1 9.8 21.202l-.015-.008Z"
                />
                <path
                    stroke="#3E2313"
                    strokeMiterlimit="10"
                    d="M54.264 46.546 35.498 35.851l-3.222 5.653L51.042 52.2l3.222-5.653Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
