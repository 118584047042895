import { useRouter } from "next/router";

import { Container, Usp } from "components/preHeader/PreHeader.styled";
import { Tagline } from "components/layout/Layout.styled";
import { headerThemeOptions } from "components/layout/Layout";
import TrustBox from "components/preHeader/trustBox/TrustBox";

interface PreHeaderTheme {
    theme:
        | headerThemeOptions.light
        | headerThemeOptions.dark
        | headerThemeOptions.recipe;
    darkMode?: boolean;
    showTagline?: boolean;
    taglineIsSpinning?: boolean;
}

export function PreHeader({
    theme,
    showTagline,
    taglineIsSpinning
}: PreHeaderTheme): JSX.Element {
    const router = useRouter();
    const isRecipeDetail = router.pathname.indexOf("recepten/") >= 1;

    return (
        <Container darkMode={isRecipeDetail}>
            {showTagline && <Tagline isSpinning={taglineIsSpinning} />}
            <TrustBox view="desktop" />

            <Usp theme={theme}>
                Op werkdagen vóór 10.00 uur besteld, &lsquo;s avonds in huis
            </Usp>
        </Container>
    );
}
