import { gql } from "@apollo/client";

export const GetOrder = gql`
    query GetOrder($orderId: String!) {
        orderData(orderId: $orderId) {
            total
            items
            order_number
            mollie_payment_token
            mollie_redirect_url
        }
    }
`;
