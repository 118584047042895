import styled, { css } from "styled-components";

import { Icon as BaseIcon } from "components";
import { LinkUnderline } from "components/shared/svg/LinkUnderline";
import { headerThemeOptions } from "components/layout/Layout";

import { HeaderAlignmentTypes, headerAlignmentTypes } from "./LinkGroup";

import { media } from "constants/media";

function selectLinkGroupType(
    type: "primary" | "secondary" | "recipe" | "default"
) {
    switch (type) {
        case "primary":
            return css`
                :first-of-type {
                    margin-top: 162px;
                }

                > a {
                    color: var(--color-orange-50);
                    cursor: pointer;
                    font-family: var(--font-secondary);
                    font-size: 32px;
                    line-height: 40px;
                }
            `;
        case "secondary":
            return css`
                > a {
                    color: var(--color-beige-50);
                    font-family: var(--font-primary);
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 24px;
                }
            `;
        case "recipe":
            return css`
                > a {
                    color: var(--color-beige-50);
                    cursor: pointer;
                    font-family: var(--font-secondary);
                    font-size: 32px;
                    line-height: 40px;
                }
            `;
        default:
            break;
    }
}

function selectHeaderItemsColor(
    theme: headerThemeOptions.light | headerThemeOptions.dark,
    isSticky: boolean
) {
    switch (theme) {
        case headerThemeOptions.light:
            return css`
                color: var(--color-brown-50);
            `;
        case headerThemeOptions.dark:
            return css`
                color: ${isSticky
                    ? " var(--color-brown-40)"
                    : " var(--color-beige-40)"};
            `;
        default:
            return css`
                color: var(--color-beige-40);
            `;
    }
}

function selectListAlignment(align: HeaderAlignmentTypes) {
    switch (align) {
        case headerAlignmentTypes.left:
            return css`
                display: flex;
                flex: 1;
                justify-content: flex-start;
                margin-right: 90px;
                text-align: left;

                > li:not(:last-child) {
                    margin-right: 24px;
                }
            `;
        case headerAlignmentTypes.right:
            return css`
                display: flex;
                flex: 1;
                justify-content: flex-end;
                margin-left: 90px;

                > li:not(:first-child) {
                    margin-left: 8px;

                    ${media.xlUp} {
                        margin-left: 24px;
                    }
                }
            `;
        default:
            return css`
                top: -90px;
            `;
    }
}

export const Container = styled.ul<{
    align?: HeaderAlignmentTypes;
}>`
    position: relative;
    z-index: 10;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ align }) => align && selectListAlignment(align)};
`;

export const Icon = styled(BaseIcon)``;

export const Underline = styled(LinkUnderline)`
    bottom: 0;
    display: block;
    opacity: 0;
    overflow: visible;
    position: absolute;
`;

export const LinkItem = styled.li<{
    type: "primary" | "secondary" | "recipe" | "default";
    theme: headerThemeOptions.light | headerThemeOptions.dark;
    isSticky: boolean;
}>`
    list-style: none;
    padding-bottom: 32px;

    > a {
        align-items: flex-start;
        display: flex;
        justify-content: center;
    }

    /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
    ${({ type }) => type && selectLinkGroupType(type)};

    ${media.lgUp} {
        height: 32px;
        padding-bottom: 0;
        position: relative;

        > a {
            align-items: center;

            ${({ theme, isSticky }) =>
                theme && selectHeaderItemsColor(theme, isSticky)};

            &:hover {
                color: var(--color-orange-50);

                ${Underline} {
                    fill: var(--color-orange-50);
                    left: -16px;
                    opacity: 1;
                }

                > ${Icon} {
                    stroke: var(--color-orange-50);
                }
            }

            &:active {
                ${Underline} {
                    fill: var(--color-brown-50);
                    opacity: 1;
                }
            }
        }
    }
`;

export const Label = styled.span<{ withIcon: boolean }>`
    font-size: 16px;
    height: 32px;
    padding-bottom: 0;
    position: relative;

    /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
    ${({ withIcon }) =>
        withIcon &&
        css`
            display: inline-block;
            margin-left: 8px;
        `};
`;
