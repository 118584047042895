import { gql } from "@apollo/client";

export const GetCountry = gql`
    query GetCountry($id: String) {
        country(id: $id) {
            full_name_locale
            full_name_english
        }
    }
`;
