import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const AddProductsToCart = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation addProductsToCart(
        $cartId: String!
        $cartItems: [CartItemInput!]!
    ) {
        addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
            cart {
                ...CartContent
            }
            user_errors {
                code
                message
            }
        }
    }
`;
