import { StoryblokRichtext, render } from "storyblok-rich-text-react-renderer";
import { useStoryblok } from "@storyblok/react";

import { ERROR, INFO, Icon } from "components/shared/icon";
import { Notification } from "components/shared/banner/Banner.styled";

const icons = {
    error: { icon: ERROR, size: 48 },
    info: { icon: INFO, size: 48 }
};

export function Banner(): JSX.Element | null {
    // Get the environment from the environment variable
    const environment = process.env.NODE_ENV;

    // Get the global/notification story from Storyblok
    const story = useStoryblok("global/notification", {
        version: environment === "production" ? "published" : "draft"
    });

    // Get the type and message from the story
    const type = story?.content?.type as "error" | "info";
    const message = story?.content?.message as StoryblokRichtext;

    if (story?.content?.enabled) {
        return (
            <Notification type={type}>
                <span>
                    <Icon {...icons[type]} />
                    <div>{render(message)}</div>
                </span>
            </Notification>
        );
    }
    return null;
}
