import Image from "next/image";
import Link from "next/link";

import {
    Amount,
    Card,
    Decoration,
    Duration,
    ImageContainer,
    Info,
    StyledIcon,
    Tag,
    Title
} from "components/shared/recipeCard/recipeCard.styled";
import { CHICKEN, Icon } from "components/shared/icon";
import { CLOCK, EYE } from "components/shared/icon/IconSet";

import type { SbRecipeCard } from "types/storyblok";

type Props = {
    blok: SbRecipeCard;
};

export default function RecipeCard({ blok }: Props): JSX.Element {
    const { image, title, duration, amount, tag, belowFold } = blok;
    const slug = title?.toLowerCase()?.replace(/ /g, "-") || "";

    return (
        <Card>
            <Link href={`/recepten/${slug}`} passHref>
                <ImageContainer>
                    {image?.filename && (
                        <Image
                            src={image.filename}
                            alt={image.alt}
                            priority={!belowFold}
                            objectFit="cover"
                            layout="fill"
                            loading={belowFold ? "lazy" : "eager"}
                        />
                    )}
                    <StyledIcon>
                        <Icon icon={EYE} size={32} />
                    </StyledIcon>
                </ImageContainer>
            </Link>
            <Link href={`/recepten/${slug}`} passHref>
                <Title>{title}</Title>
            </Link>

            <Info>
                <Decoration>
                    <Image
                        src="/images/link-underline-brush-beige.svg"
                        alt="underline-brush-beige"
                        layout="fill"
                        objectFit="cover"
                        role="presentation"
                        loading="lazy"
                    />
                </Decoration>

                {duration && (
                    <Duration>
                        <Icon icon={CLOCK} stroke="var(--color-brown-50)" />
                        {duration} min
                    </Duration>
                )}

                {amount && (
                    <Amount>
                        <Icon icon={CHICKEN} stroke="var(--color-brown-50)" />
                        {amount}{" "}
                        {parseInt(amount) === 1 ? "persoon" : "personen"}
                    </Amount>
                )}

                {tag && <Tag>{tag}</Tag>}
            </Info>
        </Card>
    );
}
