import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import NextLink from "next/link";

import { ARROW_RIGHT, Checkbox, Icon, Modal } from "components";

import {
    AcceptButton,
    BottomContainer,
    Description,
    Link,
    Option,
    Options,
    PreferencesButton
} from "./CookiesConsent.styled";

import { useBodyScrollLock } from "helpers/hooks";

function pushPreferencesToDataLayer(statistics: boolean, marketing: boolean) {
    window?.dataLayer?.push({ event: "cookies_functional" });

    if (statistics) {
        window?.dataLayer?.push({ event: "cookies_statistics" });
    }

    if (marketing) {
        window?.dataLayer?.push({ event: "cookies_marketing" });
    }
}

export function CookiesConsent(): JSX.Element | null {
    const { register, watch, handleSubmit } = useForm();
    const [isOpen, setIsOpen] = useState(true);
    const [showDetailedCookies, setShowDetailedCookies] = useState(false);
    const [savedPreferences, setSavedPreferences] = useState(true);

    useEffect(() => {
        // Checks if the cookies have been already set locally.
        const funcitionalCookieExists = Cookies.get("cookiesFunctional");
        const statisticsCookieExists = Cookies.get("cookiesStatistics");
        const marketingCookieExists = Cookies.get("cookiesMarketing");

        if (
            funcitionalCookieExists === undefined ||
            statisticsCookieExists === undefined ||
            marketingCookieExists === undefined
        ) {
            setSavedPreferences(false);
        }
    }, []);

    function saveCookiePreferences() {
        const isFunctionalAllowed = true;
        const isStatisticsAllowed = showDetailedCookies
            ? !!watch("cookiesStatistics")
            : true;
        const isMarketingAllowed = showDetailedCookies
            ? !!watch("cookiesMarketing")
            : true;

        Cookies.set("cookiesFunctional", isFunctionalAllowed.toString(), {
            expires: 365
        });

        Cookies.set("cookiesStatistics", isStatisticsAllowed.toString(), {
            expires: 365
        });

        Cookies.set("cookiesMarketing", isMarketingAllowed.toString(), {
            expires: 365
        });

        pushPreferencesToDataLayer(isStatisticsAllowed, isMarketingAllowed);
        setSavedPreferences(true);
    }

    const { route } = useRouter();
    const isCookieModalHidden = savedPreferences || route === "/privacy-policy";

    useBodyScrollLock(!isCookieModalHidden);

    if (isCookieModalHidden) {
        return null;
    }

    return (
        <>
            <Modal
                showModal={isOpen}
                onClose={() => setIsOpen(!isOpen)}
                title="Cluckin’ Cookies"
                isOpen={isOpen}
                contentLabel="content label"
                maxWidth={450}
                shouldCloseOnOverlayClick={false}
            >
                <Description>
                    Om voor een optimale gebruikerservaring te zorgen gebruiken
                    wij cookies. Hiermee kunnen wij advertenties personaliseren
                    en websiteverkeer analyseren.{" "}
                    <NextLink href="/privacy-policy" passHref prefetch={false}>
                        <Link href="/privacy-policy">Meer informatie</Link>
                    </NextLink>
                </Description>
                <Options isVisible={showDetailedCookies}>
                    <Option>
                        <Checkbox
                            {...register("cookiesFunctional")}
                            activeValue="true"
                            currentValue="true"
                            disabled
                            label="Functioneel"
                        />
                    </Option>
                    <Option>
                        <Checkbox
                            {...register("cookiesStatistics")}
                            activeValue="true"
                            currentValue={watch("cookiesStatistics")}
                            label="Statistieken"
                        />
                    </Option>
                    <Option>
                        <Checkbox
                            {...register("cookiesMarketing")}
                            activeValue="true"
                            currentValue={watch("cookiesMarketing")}
                            label="Marketing"
                        />
                    </Option>
                </Options>
                <BottomContainer>
                    <PreferencesButton
                        size="simple"
                        buttonType="link"
                        variant="blue"
                        reversedOrder
                        onClick={() =>
                            setShowDetailedCookies(!showDetailedCookies)
                        }
                    >
                        Voorkeuren
                    </PreferencesButton>
                    <AcceptButton
                        onClick={handleSubmit(saveCookiePreferences)}
                        isShowingDetails={showDetailedCookies}
                        type="submit"
                    >
                        <span>
                            {showDetailedCookies
                                ? "Opslaan"
                                : "Alles accepteren"}
                        </span>
                        <Icon
                            icon={ARROW_RIGHT}
                            fill="var(--color-beige-50)"
                            size={24}
                        />
                    </AcceptButton>
                </BottomContainer>
            </Modal>
        </>
    );
}
