import styled from "styled-components";

export const Container = styled.div`
    margin: auto;
    max-width: 1200px;
    min-height: 100vh;
    padding: 0 16px;
`;

export const FaqCategory = styled.span`
    border: 1px solid currentColor;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 32px;
    margin-right: 15px;

    padding: 0 4px;
`;
