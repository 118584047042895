import { EVENTS } from "lib/gtag";

export type CartEvent = "add" | "remove";

interface ProductObject {
    brand?: string;
    category?: string;
    id?: string | number;
    name?: string;
    price?: number;
    quantity?: number;
    variant?: string;
}

const PAGES_WITH_ECOMMERCE_EVENT = ["/afrekenen", "/kip-bestellen"];

const SELECTED = "selected";

// Some pages need to fire their own "pageview" events due to their dependency
// on the `ecommerce` object. This function helps with deciding which pages
// need to be omitted from the page switching callback logic.
export function shouldFirePageViewEvent(path: string): boolean {
    return !(
        path === "/" ||
        PAGES_WITH_ECOMMERCE_EVENT.some(excludePath =>
            path.includes(excludePath)
        )
    );
}

export function createGTMCartItem(
    item: CartItem
): Array<Record<string, unknown>> | undefined {
    return item?.bundle_options?.map(bundleItem => {
        const itemValues = { ...bundleItem?.values?.at?.call({}, 0) };
        delete itemValues["__typename" as keyof typeof itemValues];

        return {
            label: bundleItem.label,
            uid: bundleItem.uid,
            values: itemValues
        };
    });
}

export const cartAddOrRemoveEvent = (
    eventType: CartEvent,
    productInput: ProductObject | Array<ProductObject>
): void => {
    let existingEcommerce: {
        add?: {
            products: Array<Record<string, unknown>>;
        };
        remove?: {
            products: Array<Record<string, unknown>>;
        };
    } | null = {};

    // Need to extract the updating logic from this callback as it seems to execute 3 times in the client
    window?.dataLayer?.push(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        existingEcommerce = this.get("ecommerce"); // Typing `this` in this context gives a lot of trouble, so I disabled errors
        // For documentation about the above functionality, check this link:
        // https://developers.google.com/tag-platform/tag-manager/web/datalayer#custom_data_layer_methods
    });

    const event =
        eventType === "add" ? EVENTS.ADD_TO_CART : EVENTS.REMOVE_FROM_CART;

    const isInputAnArray = Array.isArray(productInput);

    const pushObject = {
        ecommerce: {
            [SELECTED]: undefined,
            [eventType === "add" ? "remove" : "add"]: undefined,
            [eventType]: {
                products: isInputAnArray ? [...productInput] : [productInput]
            }
        },
        event,
        page: window.location.pathname
    };

    if (existingEcommerce) {
        pushObject.ecommerce = {
            ...existingEcommerce,
            ...pushObject.ecommerce
        };
    }

    window?.dataLayer?.push({ _clear: true, ...pushObject });
};
