import { gql } from "@apollo/client";

export const MollieProcessTransaction = gql`
    mutation MollieProcessTransaction($paymentToken: String!) {
        mollieProcessTransaction(input: { payment_token: $paymentToken }) {
            paymentStatus
            cart {
                id
            }
        }
    }
`;
