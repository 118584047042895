import styled, { css } from "styled-components";

import { ButtonElement } from "components";

import { FEEDBACK_MESSAGE_TYPES } from "constants/enum";
import { media } from "constants/media";

function selectMessageSize(size: "small" | "default" | "fullWidth") {
    switch (size) {
        case "small":
            return css`
                font-size: var(--type-span-font-size);
                line-height: var(--type-span-line-height);
            `;
        case "fullWidth":
            return css`
                font-size: var(--type-span-font-size);
                grid-column: 1 / 3;
                line-height: var(--type-span-line-height);
            `;
        default:
            return css`
                font-size: var(--type-paragraph-font-size);
                line-height: var(--type-paragraph-line-height);
            `;
    }
}

function selectMessageType(
    type:
        | FEEDBACK_MESSAGE_TYPES.ERROR
        | FEEDBACK_MESSAGE_TYPES.SUCCESS
        | FEEDBACK_MESSAGE_TYPES.WARNING
) {
    switch (type) {
        case FEEDBACK_MESSAGE_TYPES.ERROR:
            return css`
                background-color: var(--color-red-50);
            `;
        case FEEDBACK_MESSAGE_TYPES.SUCCESS:
            return css`
                background-color: var(--color-green-40);
            `;
        case FEEDBACK_MESSAGE_TYPES.WARNING:
            return css`
                background-color: var(--color-orange-50);
            `;
        default:
            return css`
                background-color: var(--color-grey);
            `;
    }
}

export const Container = styled.span<{
    $order: number;
    size?: "small" | "default" | "fullWidth";
    type?:
        | FEEDBACK_MESSAGE_TYPES.ERROR
        | FEEDBACK_MESSAGE_TYPES.SUCCESS
        | FEEDBACK_MESSAGE_TYPES.WARNING;
    withCloseButton?: boolean;
}>`
    align-items: center;
    color: var(--color-beige-40);
    display: flex;
    gap: 8px;
    margin: 16px 0;
    padding: 16px;

    white-space: pre-line;
    width: 100%;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */

    ${({ $order }) =>
        $order &&
        css`
            order: ${$order};
        `}

    ${({ withCloseButton }) =>
        withCloseButton &&
        css`
            position: relative;
        `};
    ${({ type }) => type && selectMessageType(type)};
    ${({ size }) => size && selectMessageSize(size)};
    /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const IconContainer = styled.div<{ strokeColor?: string }>`
    height: 24px;
    margin-top: 4px;
    width: 24px;

    &:first-child {
        align-self: flex-start;
    }

    ${media.lgUp} {
        margin-top: 4px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ strokeColor }) =>
        strokeColor &&
        css`
            svg > path {
                stroke: ${strokeColor};
            }
        `};
    /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const CloseButton = styled(ButtonElement)`
    position: absolute;
    right: 8px;
    top: 16px;
`;
