import { useState } from "react";
import dynamic from "next/dynamic";

import SliderControls, {
    SliderControlTheme
} from "components/shared/sliderControls/SliderControls";

import {
    Container,
    ControlsContainer,
    List,
    ListItem,
    PackageItem
} from "./PreConfiguredPackages.styled";

import { CUSTOM_PACKAGE_SKU } from "constants/util";
import { useMediaQueryContext } from "helpers/hooks";

const Carousel = dynamic(() => import("nuka-carousel"));

export function PreConfiguredPackages({
    hasDarkTheme = false,
    packages,
    slideIndicatorSpacingColor = "var(--color-green-50)",
    desktopVariant = "default"
}: {
    packages?: Array<MagentoProduct> | null;
    slideIndicatorSpacingColor?: string;
    desktopVariant?: "default" | "simpleGrid";
    hasDarkTheme?: boolean;
}): JSX.Element | null {
    const { isMobileView } = useMediaQueryContext();
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const items = packages
        ?.filter(
            packageItem =>
                packageItem?.sku !== CUSTOM_PACKAGE_SKU &&
                packageItem?.uid !== "MTQ="
        )
        .map((packageItem, index) => {
            if (index <= 2) {
                return (
                    <PackageItem
                        hasDarkTheme={hasDarkTheme}
                        key={`Pakket-${packageItem?.name}-${index}`}
                        item={packageItem}
                    />
                );
            }
        });

    return isMobileView ? (
        <>
            <Container>
                <Carousel
                    slidesToShow={isMobileView ? 1.3 : 3}
                    swiping
                    withoutControls
                    wrapAround
                    slideIndex={currentSlide}
                    afterSlide={nextSlide => {
                        setCurrentSlide(nextSlide);
                    }}
                >
                    {items}
                </Carousel>
            </Container>
            <ControlsContainer>
                <SliderControls
                    isAllowedToLoop
                    theme={SliderControlTheme.PRIMARY}
                    currentSlide={currentSlide}
                    totalSlides={3}
                    onChange={nextSlide => {
                        setCurrentSlide(nextSlide);
                    }}
                    slideIndicatorSpacingColor={slideIndicatorSpacingColor}
                />
            </ControlsContainer>
        </>
    ) : (
        <List>
            {items?.map((item, index) => (
                <ListItem
                    key={`Pakket-list-item-${index}`}
                    desktopVariant={desktopVariant}
                >
                    {item}
                </ListItem>
            ))}
        </List>
    );
}
