import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import Image from "next/image";

import { CHEVRON_DOWN } from "components/shared/icon/IconSet";
import {
    DropDownWrapper,
    DropdownButton,
    DropdownWrapper,
    ErrorMessage,
    SelectedIcon,
    SelectedLabel,
    StyledDropdown,
    StyledLabel,
    StyledOption
} from "components/shared/dropdown/Dropdown.styled";
import { Icon } from "components/shared/icon/Icon";

import { useOnClickOutside } from "helpers/hooks";

interface TOption {
    label?: string | JSX.Element;
    value?: string | number;
    image?: string;
}
export interface DropdownProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** label shown above the dropdown */
    label?: string;
    /** Options for the dropdown */
    options?: Array<TOption> | undefined;
    /** Error message shown */
    errorMesssage?: string;
    /** Dropdown is disabled */
    disabled?: true;
    /** Placeholder for when the user hasn't made a selection yet */
    placeholder?: string;
    /** Name to register input to for react-hooks-form */
    name: string;
    /** A custom width for the wrapper */
    wrapperWidth?: string;
    /** react-hooks-form setvalue function */
    required?: string | boolean | undefined;
    /** Override the selected value */
    overrideValueLabel?: string | JSX.Element | null;
    /** Color of the icon */
    iconColor?: string;
}

/**
 * Dropdown component to be used with react-hooks-form
 *
 * @param {DropdownProps} {
 *     className,
 *     placeholder = "",
 *     wrapperWidth = "290px",
 *     name,
 *     label,
 *     options = [],
 *     errorMesssage,
 *     disabled,
 *     required
 * }
 * @return {*}  {JSX.Element}
 */
const Dropdown = ({
    className,
    placeholder = "",
    wrapperWidth = "290px",
    name,
    label,
    options = [],
    errorMesssage,
    disabled,
    required,
    iconColor = "var(--color-brown-50)",
    overrideValueLabel = undefined
}: DropdownProps): JSX.Element => {
    /* Ref used to track clicks outside of element to close dropdown */
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { register, setValue, watch } = useFormContext();
    const currentValue = watch(name);

    useOnClickOutside(dropdownRef.current, () => setIsOpen(false));

    useEffect(() => {
        register(name, { required });
    }, [register, name, required]);

    const handleChange = (selectedOption: TOption) => {
        /* Set component state */
        setValue(name, selectedOption, { shouldDirty: true });
        /* Set react-hooks-form state */
        setIsOpen(false);
    };

    return (
        <DropDownWrapper
            // Can't seem to fix the setting of the ref without an overload typescript error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={dropdownRef} /* Ref used to handle clicks outside of element */
            wrapperWidth={wrapperWidth}
            dropdownIsOpen={isOpen}
        >
            {label && <StyledLabel>{label}</StyledLabel>}
            <DropdownButton
                type="button"
                className={className}
                onClick={() => !disabled && setIsOpen(!isOpen)}
                hasError={!!errorMesssage}
                isDisabled={disabled}
                hasValue={currentValue?.value !== "no-selection"}
                dropdownIsOpen={isOpen}
                aria-label={currentValue?.label}
            >
                <SelectedLabel
                    hasError={!!errorMesssage}
                    isDefault={currentValue?.value === "no-selection"}
                >
                    {currentValue?.label && placeholder ? (
                        <span style={{ color: "var(--color-beige-60)" }}>
                            {placeholder}
                        </span>
                    ) : overrideValueLabel ? (
                        overrideValueLabel
                    ) : (
                        currentValue?.label
                    )}
                </SelectedLabel>
                <SelectedIcon isFlipped={isOpen}>
                    <Icon icon={CHEVRON_DOWN} size={24} fill={iconColor} />
                </SelectedIcon>
            </DropdownButton>
            <DropdownWrapper isOpen={isOpen}>
                <StyledDropdown>
                    {options?.map(option => (
                        <StyledOption
                            type="button"
                            key={option.value}
                            onClick={() => handleChange(option)}
                            isSelected={option.value === currentValue?.value}
                        >
                            <>
                                {option?.image && (
                                    <Image
                                        src={option.image}
                                        width={20}
                                        height={15}
                                        unoptimized={true}
                                    />
                                )}
                                {option.label}
                            </>
                        </StyledOption>
                    ))}
                </StyledDropdown>
            </DropdownWrapper>
            {errorMesssage && <ErrorMessage>{errorMesssage}</ErrorMessage>}
        </DropDownWrapper>
    );
};

export default Dropdown;
