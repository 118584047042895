import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const UpdateCartQuantity = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation updateCartQuantity($input: UpdateCartItemsInput) {
        updateCartItems(input: $input) {
            cart {
                ...CartContent
            }
        }
    }
`;
