import { ForwardedRef, forwardRef } from "react";

import {
    Fill,
    Label,
    RadioInput,
    StyledRadio
} from "components/shared/radio/Radio.styled";

export interface RadioProps {
    /** Setting this to true will disable the input, blocking any further interactions */
    disabled?: boolean;
    /** The radio button is always rendered with a label, here you can set the text for the label */
    label?: string;
    /** Use this property to group multiple RadioButton components together. All RadioButtons that share the same name belong to a radio button group, with a single value */
    name: string;
    /** When this RadioButton is selected, the value passed through this key will be used when grabbing all the data from the form */
    value: string;
    theme?: "red" | "green";
    onFocus?: () => void;
}

export const Radio = forwardRef(function RadioButton(
    {
        disabled = false,
        label,
        name,
        value,
        theme = "red",
        onFocus = () => null,
        ...otherProps
    }: RadioProps,
    ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
    return (
        <Label disabled={disabled}>
            <RadioInput
                data-testid={`${name}:${value}`}
                disabled={disabled}
                name={name}
                ref={ref}
                type="radio"
                onFocus={() => onFocus()}
                value={value}
                {...otherProps}
            />
            <StyledRadio theme={theme}>
                <Fill theme={theme} />
            </StyledRadio>
            {label}
        </Label>
    );
});
