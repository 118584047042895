import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import { LinkUnderline } from "../svg/LinkUnderline";

export const Underline = styled(LinkUnderline)<{
    $isSimple?: boolean;
    showUnderline?: boolean;
    $showUnderlineOnMobile?: boolean;
}>`
    bottom: ${({ $isSimple }) => ($isSimple ? "0" : "4px")};
    display: ${({ showUnderline }) => showUnderline && "none"};
    height: 2px;
    left: ${({ $isSimple }) => ($isSimple ? "0" : "12px")};
    position: absolute;
    visibility: ${({ $showUnderlineOnMobile }) =>
        $showUnderlineOnMobile ? "visible" : "hidden"};
    width: 100%;

    @media (pointer: fine) {
        opacity: 0;
        transition: all var(--default-transition-duration) linear;
        visibility: visible;
        width: 0;
    }
`;

export function GetLinkStyling({
    variant
}: {
    variant: string;
    $isSimple?: boolean;
    showUnderline?: boolean;
}): FlattenSimpleInterpolation {
    switch (variant) {
        case "blue":
            return css`
                color: var(--color-blue-50);
                transition: all var(--default-transition-duration) linear;

                svg {
                    transition: all var(--default-transition-duration) linear;
                }

                :hover,
                :active {
                    color: var(--color-blue-50);

                    > ${Underline} {
                        left: 0;
                        opacity: 1;
                        transition: all var(--default-transition-duration)
                            linear;
                        width: calc(100% - 24px);
                    }
                }
            `;
        case "orange":
            return css`
                color: var(--color-orange-50);
                transition: all var(--default-transition-duration) linear;

                /* stylelint-disable-next-line */
                svg {
                    transition: all var(--default-transition-duration) linear;
                }

                :hover,
                :active {
                    color: var(--color-orange-50);

                    > ${Underline} {
                        left: -16px;
                        opacity: 1;
                        transition: all var(--default-transition-duration)
                            linear;
                        width: 100%;
                    }
                }
            `;
        case "brown":
            return css`
                color: var(--color-brown-50);
                transition: all var(--default-transition-duration) linear;

                /* stylelint-disable-next-line */
                svg {
                    transition: all var(--default-transition-duration) linear;
                }

                :hover,
                :active {
                    color: var(--color-brown-50);

                    > ${Underline} {
                        left: 0;
                        opacity: 1;
                        transition: all var(--default-transition-duration)
                            linear;
                        width: calc(100% - 24px);
                    }
                }
            `;
        case "red":
            return css`
                color: var(--color-red-50);
                transition: all var(--default-transition-duration) linear;

                /* stylelint-disable-next-line */
                svg {
                    transition: all var(--default-transition-duration) linear;
                }

                :hover,
                :active {
                    color: var(--color-red-50);

                    > ${Underline} {
                        left: 0;
                        opacity: 1;
                        transition: all var(--default-transition-duration)
                            linear;
                        width: calc(100% - 24px);
                    }
                }
            `;
        case "beige":
        default:
            return css`
                color: var(--color-beige-50);
                transition: all var(--default-transition-duration) linear;

                /* stylelint-disable-next-line */
                svg {
                    transition: all var(--default-transition-duration) linear;
                }

                :hover,
                :active {
                    color: var(--color-orange-50);

                    > ${Underline} {
                        left: 0;
                        opacity: 1;
                        transition: all var(--default-transition-duration)
                            linear;
                        width: calc(100% - 24px);
                    }
                }
            `;
    }
}

export const disabledLinkStyles = css`
    color: var(--color-beige-60);

    :hover {
        color: var(--color-orange-60);

        > ${Underline} {
            visibility: visible;
        }
    }
`;

export const LinkStyled = styled.a<{
    variant?: string;
    disabled?: boolean;
    underline?: boolean;
    reversedOrder?: boolean;
    size?: "large";
}>`
    align-items: center;
    display: inline-flex;
    position: relative;

    /* stylelint-disable order/properties-alphabetical-order */
    ${({ variant }) => variant && GetLinkStyling({ variant })};

    ${({ disabled }) => disabled && disabledLinkStyles};

    ${({ reversedOrder }) =>
        reversedOrder &&
        css`
            flex-direction: row-reverse;
        `};

    ${({ size }) =>
        size === "large" &&
        css`
            font-size: 18px;
            line-height: 32px;
        `};
    /* stylelint-enable order/properties-alphabetical-order */

    text-decoration: none;
`;
