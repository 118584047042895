import Image from "next/image";
import ReactPlayer from "react-player/lazy";
import parse, { DOMNode, Element, Node, domToReact } from "html-react-parser";

import { CHECK, Icon, Link } from "components";
import {
    Content,
    ImageWrapper,
    ListElementWrapper,
    VideoWrapper
} from "components/richText/RichText.styled";

interface RichTextProps {
    /** Data pulled in from the richt text respons */
    // Due to getting data from different queries it is impossible to type everything correctly without errors, that is the reason of the any type here
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    htmlString: string;
}

export function RichText({ htmlString }: RichTextProps): JSX.Element | null {
    if (!htmlString) return null;

    const options = {
        replace: (domNode: DOMNode) => {
            const domNodeElement = domNode as Element;
            if (!domNodeElement.attribs) return;

            if (domNodeElement?.name === "a") {
                const tagContent = domNodeElement.children[0] as Node & {
                    data?: string;
                };

                if (!tagContent.data) {
                    return;
                }

                return (
                    <Link
                        href={domNodeElement.attribs.href}
                        target={domNodeElement.attribs.target}
                        rel={domNodeElement.attribs.rel}
                    >
                        {tagContent.data}
                    </Link>
                );
            }
            if (domNodeElement?.name === "video") {
                const source = domNodeElement.children.find(
                    node => (node as Element).name === "source"
                ) as Element;

                return (
                    <VideoWrapper>
                        <ReactPlayer
                            width="100%"
                            height="auto"
                            url={source?.attribs?.src ?? ""}
                            controls
                        />
                    </VideoWrapper>
                );
            }

            if (domNodeElement?.name === "img") {
                return (
                    <ImageWrapper>
                        <Image
                            src={
                                domNodeElement?.attribs?.src ??
                                "/assets/placeholders/fallback-img-800x450.jpg"
                            }
                            alt={domNodeElement?.attribs?.title ?? ""}
                            height={domNodeElement?.attribs?.height ?? 450}
                            width={domNodeElement?.attribs?.width ?? 800}
                            objectFit="cover"
                            objectPosition="center"
                        />
                    </ImageWrapper>
                );
            }

            if (domNodeElement?.name === "li") {
                return (
                    <ListElementWrapper>
                        <Icon icon={CHECK} stroke="var(--color-blue-50)" />
                        {domToReact(domNodeElement.children)}
                    </ListElementWrapper>
                );
            }

            if (domNodeElement?.name) {
                return domToReact(domNodeElement.children, options);
            }
        }
    };

    return <Content>{parse(htmlString, options)}</Content>;
}
