import styled from "styled-components";

import { media } from "constants/media";

export const Content = styled.div`
    color: var(--color-indigo-50);

    strong,
    p {
        display: block;
        margin-bottom: 8px;
    }

    code {
        background-color: var(--color-light-grey);
        border-radius: 4px;
        color: var(--color-secondaryDark);
        display: block;
        font-size: 13px;
        line-height: 24px;
        margin: 24px 0;
        padding: 32px;
        white-space: pre-wrap;

        ${media.mdUp} {
            font-size: 15px;
            line-height: 32px;
            margin: 24px auto;
            max-width: 800px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style-type: none;
    }
`;

export const VideoWrapper = styled.div`
    margin: 0 auto;
    max-width: 800px;
`;

export const ImageWrapper = styled.div`
    margin: 24px auto;
    position: relative;
    text-align: center;
`;

export const ListElementWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 500;
    gap: 8px;

    ${media.lgUp} {
        font-size: 18px;
        line-height: 32px;
    }

    > li,
    > span {
        font-size: inherit;
        line-height: inherit;
    }

    > svg {
        height: 24px;
        min-height: 24px;
        min-width: 24px;
        width: 24px;
    }
`;
