import { EVENTS } from "lib/gtag";

interface EventProduct {
    brand?: string;
    category?: string;
    id?: string;
    name?: string;
    price?: number;
    quantity?: number;
    variant?: string;
}

const brand = "Mr. Fillet";
const category = "Losse producten";

export const updateCheckout = (
    items: Array<BundledCartItem | CartItem>,
    allProducts: Array<MagentoProduct>
): void => {
    window.dataLayer = window?.dataLayer || [];

    let existingEcommerce = {};

    window?.dataLayer?.push(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        existingEcommerce = this.get("ecommerce");
    });

    let individualItems: Array<EventProduct> = [];

    try {
        individualItems = items.reduce(
            (acc: Array<EventProduct>, nextBundle) => {
                const { bundle_options: bundleItems, quantity: totalQuantity } =
                    nextBundle;

                const transformedItems: Array<EventProduct> =
                    bundleItems?.map(item => {
                        if (!item.values) {
                            return {};
                        }

                        const { label: name, quantity: itemQuantity } =
                            item.values[0] ?? {};

                        const matchingProduct = allProducts.find(
                            product => product.name === name
                        );

                        const id = matchingProduct?.sku ?? "No id found";
                        const weight = matchingProduct?.weight ?? 1.5;
                        const variant = `${weight}kg`;
                        const quantity =
                            (itemQuantity ?? 1) * (totalQuantity ?? 1);
                        const price = matchingProduct?.price_per_kilogram ?? 0;

                        return {
                            brand,
                            category,
                            id,
                            name,
                            price,
                            quantity,
                            variant
                        };
                    }) ?? [];

                return [...acc, ...transformedItems];
            },
            []
        );

        const checkedItems: Record<string, number> = {};

        individualItems.forEach(item => {
            if (!item.id) {
                return;
            }

            if (Object.keys(checkedItems).includes(item.id ?? "")) {
                item.quantity = checkedItems[item.id];
                return;
            }

            item.quantity = individualItems
                .filter(el => el.id === item.id)
                .reduce((acc: number, next) => {
                    return acc + (next.quantity ?? 0);
                }, 0);

            checkedItems[item.id] = item.quantity;
        });

        // Aggregate similar items
        individualItems = [
            ...new Set(individualItems.map(i => JSON.stringify(i)))
        ].map(i => JSON.parse(i));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
            "An error occurred in the `checkout` dataLayer event while preparing data. See the full error here: ",
            error
        );
    }

    if (!existingEcommerce) {
        window?.dataLayer?.push({
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 2
                    },
                    products: individualItems
                }
            },
            event: EVENTS.CHECKOUT
        });

        return;
    }

    window?.dataLayer?.push({
        ecommerce: {
            ...existingEcommerce,
            checkout: {
                actionField: {
                    step: 2
                },
                products: individualItems
            }
        },
        event: EVENTS.CHECKOUT
    });
};
