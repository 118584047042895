import styled, { css } from "styled-components";

export interface IconProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Can be any hex value, name, or set css variable as a string e.g. "var(--color-violet-50)" | "red" | "#FFF".
     * If left undefined will default primary color.
     * Used in case the fill and the stroke properties have the same value */
    color?: string;
    /** Can be any hex value, name, or set css variable as a string e.g. "var(--color-violet-50)" | "red" | "#FFF".
     * If left undefined will default to the default secondary color. */
    fill?: string;
    /** Any icon (`<path /> or <g />`) imported from "IconSet.tsx" */
    icon: React.ReactNode;
    /** Can be any number for pixel values, or a string e.g. "100%" | 10 */
    size?: number | string;
    /** Can be any hex value, name, or set css variable as a string e.g. "var(--color-violet-50)" | "red" | "#FFF".
     * If left undefined will default to the default color defined in the path tag itself. */
    stroke?: string;
    /** Custom viewbox option */
    viewbox?: string;
    width?: number | string;
    height?: number | string;
    preserveAspectRatio?: string;
}

const StyledIcon = styled.svg`
    display: inline-block;
    fill: ${props => props?.color};

    ${({ stroke }) =>
        stroke &&
        css`
            stroke: ${stroke};
        `}
`;

export function Icon({
    className,
    fill,
    icon,
    size = 24,
    stroke = "",
    viewbox = "0 0 24 24",
    width,
    height,
    preserveAspectRatio
}: IconProps): JSX.Element {
    return (
        <StyledIcon
            className={className}
            width={width || size}
            height={height || size}
            viewBox={viewbox}
            fill={fill || "none"}
            stroke={stroke}
            preserveAspectRatio={preserveAspectRatio}
        >
            {icon}
        </StyledIcon>
    );
}
