import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import { useQuery } from "@apollo/client";

import { useCartContext } from "./CartContext";

import { GetProducts } from "helpers/api/magento";
import { useSessionStorage } from "helpers/hooks";
export interface PackageItem {
    id?: string;
    sku?: string;
    uid?: string;
    name?: string;
    type?: string;
    price?: number;
    quantity?: number;
    category?: string;
}

type CustomPackage = {
    url_key: string;
    items: Array<{
        options: Array<{
            uid: string;
            product: { sku: string; name: string };
        }>;
    }>;
};

export type PackageItems = Array<PackageItem | null> | [];

interface PackageCart {
    items: PackageItems;
    addPackageToCart: () => void;
    addProduct: (product: PackageItem) => void;
    addProducts: (products: Array<PackageItem>) => void;
    removeProductFromPackage: (index: number, uid?: string) => void;
    canAddProduct: boolean;
    maxItems?: number;
    total?: number;
    totalItems: number;
    customPackage?: MagentoProduct | undefined;
    packageConfiguredCorrectly?: boolean;
}

const PackageCartContext = createContext<PackageCart | null>(null);

export function usePackageCartContext(): PackageCart {
    const context = useContext(PackageCartContext);

    if (!context) {
        throw new Error(
            "PackageCartContext should be used within PackageCartProivder"
        );
    }

    return context;
}

export function PackageCartProvider({
    children,
    maxItems = 4
}: {
    children: JSX.Element | Array<JSX.Element>;
    maxItems?: number;
}): JSX.Element {
    const { addProductsToCart } = useCartContext();

    // This is the index of the product that is currently being added to the package
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    // This is the array that holds the products of the package in browser session
    const [products, setProducts] = useSessionStorage<PackageItems | []>(
        "customPackage",
        [null, null, null, null]
    );

    // Total items in the package
    const totalItems =
        products?.filter(product => !!product && product?.uid)?.length ?? 0;

    // This is the index of the next empty product in the package
    const nextEmptyIndex = products?.findIndex(product => !product);

    // Data of the product we use to create custom packages
    const [customPackage, setCustomPackage] = useState<
        CustomPackage | undefined
    >();

    // This query gets the prodcut we use to create custom packages.
    const { data: customPackageData } = useQuery(GetProducts, {
        skip: !!customPackage,
        variables: {
            filter: {
                sku: {
                    eq: process.env.NEXT_PUBLIC_PACKAGE_SKU
                }
            }
        }
    });

    // Resolves package option uid by product sku
    const getOptionUIDBySku = useCallback(
        (sku: string | undefined, itemIndex: number): string | undefined => {
            const option = customPackage?.items?.[itemIndex]?.options?.find(
                option => option.product.sku === sku
            );

            return option?.uid;
        },
        [customPackage]
    );

    // Add product to package cart
    const addProduct = useCallback(
        (product: PackageItem) => {
            // Get last index removed
            const lastRemovedIndex = nextEmptyIndex;

            // use last index removed if exists, otherwise use current index
            const indexTbeAdded =
                !!lastRemovedIndex && lastRemovedIndex > -1
                    ? lastRemovedIndex
                    : currentIndex;

            // Get the uid of the option we are adding
            const uid = getOptionUIDBySku(product.sku, indexTbeAdded);

            if (uid && indexTbeAdded > -1) {
                // If no index was removed, we need to increment the current index
                if (indexTbeAdded < maxItems) {
                    setProducts(prevItems =>
                        prevItems.map((item, index) => {
                            if (index === indexTbeAdded) {
                                return { ...product, uid };
                            }
                            return item;
                        })
                    );

                    setCurrentIndex(prev =>
                        prev + 1 >= maxItems ? 0 : prev + 1
                    );
                }
            }
        },
        [currentIndex, getOptionUIDBySku, maxItems, setProducts, nextEmptyIndex]
    );

    // Add multiple products to package cart
    const addProducts = useCallback(
        (products: Array<PackageItem>) => {
            const parsedProducts = products.map((product, index) => {
                const uid = getOptionUIDBySku(product?.sku, index);
                return { ...product, uid };
            });

            setProducts(prevItems => [...prevItems, ...parsedProducts]);
        },
        [getOptionUIDBySku, setProducts]
    );

    // Remove product from package cart
    const removeProductFromPackage = useCallback(
        (currentIndex: number) => {
            setProducts(prevProducts =>
                prevProducts.map((product, index) => {
                    if (index === currentIndex) {
                        return null;
                    }
                    return product;
                })
            );
        },
        [setProducts]
    );

    // Reset package cart state
    function resetItems(): void {
        setProducts([null, null, null, null]);
    }

    // Add package to magento cart
    async function addPackageToCart() {
        const selectedOptions =
            products?.filter(item => !!item)?.map(item => item?.uid) ?? [];

        if (selectedOptions.length === maxItems) {
            await addProductsToCart([
                {
                    quantity: 1,
                    selected_options: selectedOptions,
                    sku: process.env.NEXT_PUBLIC_PACKAGE_SKU
                }
            ]).then(() => {
                resetItems();
                setCurrentIndex(0);
            });
        } else {
            // eslint-disable-next-line no-console
            console.error("Package is not filled correctly");
        }
    }

    // Set custom package if it is not set and we have the data
    useEffect(() => {
        if (!customPackage && customPackageData?.products?.items?.[0]) {
            setCustomPackage(customPackageData.products.items[0]);
        }
    }, [customPackage, customPackageData]);

    // Total price of the package
    const total = [...products].reduce(
        (a: number, b: PackageItem | null) => (b ? a + (b?.price ?? 0) : a),
        0
    );

    return (
        <PackageCartContext.Provider
            value={{
                addPackageToCart,
                addProduct,
                addProducts,
                canAddProduct: totalItems < maxItems,
                customPackage,
                items: products,
                maxItems: maxItems,
                removeProductFromPackage,
                total,
                totalItems
            }}
        >
            {children}
        </PackageCartContext.Provider>
    );
}
