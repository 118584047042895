import { EVENTS } from "../index";

interface Item {
    brand?: string;
    category?: string;
    id?: string;
    name?: string;
    price?: number;
    quantity?: number;
    variant?: string;
}

const PROPERTY = "selected";

export const selectOrDeselectItem = (
    eventType: "select" | "deselect",
    item: Item
): void => {
    let existingEcommerce: {
        selected?: {
            products?: Array<Record<string, unknown>>;
        };
    } | null = {};

    window.dataLayer?.push(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        existingEcommerce = this.get("ecommerce");
    });

    const event =
        eventType === "select"
            ? EVENTS.SELECT_ITEM
            : EVENTS.REMOVE_ITEM_SELECTION;

    if (eventType === "select") {
        if (existingEcommerce && existingEcommerce["selected"]?.products) {
            window?.dataLayer?.push({
                ecommerce: {
                    [PROPERTY]: {
                        products: [
                            ...(existingEcommerce["selected"]?.products ?? []),
                            item
                        ]
                    }
                },
                event,
                page: window.location.pathname
            });
        } else {
            window?.dataLayer?.push({
                ecommerce: {
                    [PROPERTY]: {
                        products: [item]
                    }
                },
                event,
                page: window.location.pathname
            });
        }
    }

    if (eventType === "deselect") {
        if (existingEcommerce && existingEcommerce[PROPERTY]?.products) {
            const { id } = item;

            const itemWithThisId = existingEcommerce[PROPERTY]?.products?.find(
                element => element.id === id
            );

            if (!itemWithThisId) {
                return;
            }

            const indexOfItemToDelete =
                existingEcommerce[PROPERTY]?.products?.indexOf(
                    itemWithThisId
                ) ?? -1;

            const arrCopy = [...(existingEcommerce[PROPERTY]?.products || [])];

            arrCopy.splice(indexOfItemToDelete, 1);

            window?.dataLayer?.push({
                _clear: true,
                ecommerce: {
                    ...existingEcommerce,
                    [PROPERTY]: {
                        products: arrCopy
                    }
                },
                event,
                page: window.location.pathname
            });
        }
    }
};
