import styled from "styled-components";

export interface HeadingProps {
    /** Use either the bold font or normal weighted font */
    bold?: boolean;
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Pass any React.Node to render inside this component */
    children: React.ReactNode;
    /** Sets the HTML tag that will be used. A value of 1 will result in an `<h1 />` tag */
    headingLevel?: keyof JSX.IntrinsicElements;
}

const StyledHeading = styled.h1`
    color: #000;
`;

export function Heading({
    bold = false,
    className,
    children,
    headingLevel
}: HeadingProps): JSX.Element {
    // styled-component's  as prop does not work as expected in Typescript
    // this solution is based on this SO answer: https://stackoverflow.com/questions/65740233/styled-components-typescript-as-is-not-assignable-to-type-intrinsicattribut
    // const tag = `h${headingLevel}` as keyof JSX.IntrinsicElements;
    return (
        // passing props to a styled-component constructed with the as prop results in typescript errors
        // for this reason, the JSX native style property is used to pass on dynamic styles
        // this is an antipattern and should only be done in this usecase
        <StyledHeading
            as={headingLevel}
            className={className}
            style={{ fontWeight: bold ? "bold" : "normal" }}
        >
            {children}
        </StyledHeading>
    );
}
