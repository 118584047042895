import { ReactElement } from "react";

import { CHEVRON_UP, Icon } from "components";
import {
    MoreInformation,
    RightElement,
    StyledArrow,
    Title,
    TitleWrapper,
    Wrapper
} from "components/shared/accordion/Accordion.styled";

export enum ACCORDION_THEME {
    BEIGE40 = "beige-40",
    BEIGE50 = "beige-50"
}

interface Props {
    id?: string;
    title?: string | ReactElement | JSX.Element;
    isOpen: boolean;
    theme?: ACCORDION_THEME;
    onClick?: () => void;
    rightElement?: string | ReactElement | JSX.Element;
    information?:
        | string
        | ReactElement
        | JSX.Element
        | Array<ReactElement | JSX.Element>;
}

export function Accordion({
    isOpen,
    information,
    title,
    theme = ACCORDION_THEME.BEIGE50,
    rightElement,
    onClick,
    id
}: Props): JSX.Element {
    return (
        <Wrapper theme={theme} isOpen={isOpen} id={id} onClick={onClick}>
            <TitleWrapper>
                <Title hasRightElement={!!rightElement}>
                    {!!rightElement && (
                        <RightElement>{rightElement}</RightElement>
                    )}
                    {title}
                </Title>
                <StyledArrow isOpen={isOpen}>
                    <Icon fill="currentColor" icon={CHEVRON_UP} size={24} />
                </StyledArrow>
            </TitleWrapper>
            <MoreInformation isOpen={isOpen} theme={theme}>
                {information}
            </MoreInformation>
        </Wrapper>
    );
}
