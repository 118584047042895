import { createContext, useContext, useState } from "react";
import dynamic from "next/dynamic";

const SuccesModal = dynamic(
    () => import("components/shared/succesModal/SuccesModal")
);

type SuccessModalContext = {
    show: boolean;
    setShow: (value: boolean) => void;
};

const SuccessModalContext = createContext<SuccessModalContext | null>({
    setShow: () => null,
    show: false
});

export function useSuccessModal(): SuccessModalContext {
    const context = useContext(SuccessModalContext);

    if (!context) {
        throw new Error(
            "SuccessModalContext should be used within a SuccessModalProvidor"
        );
    }

    return context;
}

export function SuccessModalProvider({
    children
}: {
    children: JSX.Element | Array<JSX.Element>;
}): JSX.Element {
    const [show, setShow] = useState(false);

    function handleSetShow(value: boolean) {
        setShow(value);
    }
    return (
        <SuccessModalContext.Provider value={{ setShow: handleSetShow, show }}>
            {children}
            {show && (
                <SuccesModal
                    showModal={show}
                    onClose={() => handleSetShow(!show)}
                />
            )}
        </SuccessModalContext.Provider>
    );
}
