import { useState } from "react";
import Image from "next/image";
import ReactHtmlParser from "react-html-parser";

import { SHOPPING_BASKET_ADD } from "components";
import ClipSvg from "components/shared/clipSvg/ClipSvg";

import {
    Description,
    ImageContainer,
    Images,
    Item,
    PackageName,
    PurchaseInfo,
    StyledButton,
    TypeContainer
} from "./PackageItem.styled";

import { cartAddOrRemoveEvent } from "lib/gtag";
import { formatPrice } from "helpers/format";
import { getProductTypeImage } from "helpers/util/product";
import { useCartContext } from "helpers/context/CartContext";
import { useMediaQueryContext } from "helpers/hooks";

type Props = {
    item: MagentoProduct;
    className?: string;
    hasDarkTheme?: boolean;
};

export default function PackageItem({
    item,
    className = "",
    hasDarkTheme
}: Props): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const { isMobileView } = useMediaQueryContext();

    const { addProductsToCart } = useCartContext();
    const priceValue = item?.special_price
        ? item.special_price
        : item?.price_range?.maximum_price?.final_price?.value ?? 0;
    const price = formatPrice(priceValue);

    const handleClick = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 400);
    };

    const images = item?.items?.map(item => {
        return item?.options?.map(option => ({
            image_url: option?.product?.image?.url ?? "",
            label: option?.product?.name ?? "",
            type: option?.product?.categories?.[0]?.url_key ?? ""
        }))[0];
    });

    const selectedOptions =
        item?.items && item?.items?.length > 1
            ? item?.items?.map(
                  item =>
                      item?.options?.find(options => options.is_default)?.uid
              )
            : item?.items?.[0]?.options?.map(option => option.uid);

    function addProductToDataLayer() {
        if (item && window) {
            const productData = {
                brand: "Mr. Fillet",
                category: "Product",
                id: item.sku,
                name: item.name,
                price: item.price_range?.maximum_price?.final_price?.value,
                quantity: 1,
                variant: ""
            };
            cartAddOrRemoveEvent("add", productData);
        }
    }

    return (
        <Item className={className}>
            <Images>
                {images
                    ?.slice(0, 4)
                    .filter(item => item?.image_url)
                    .map((productImage, productIndex: number) => (
                        <ImageContainer
                            key={`image-${productImage?.label}-${productIndex}`}
                        >
                            <Image
                                className="clipped-image"
                                alt={productImage?.label ?? ""}
                                height={isMobileView ? 200 : 370}
                                src={productImage?.image_url ?? ""}
                                width={isMobileView ? 200 : 368}
                                objectFit="cover"
                            />

                            <TypeContainer>
                                {getProductTypeImage(productImage?.type)}
                            </TypeContainer>
                            <ClipSvg />
                        </ImageContainer>
                    ))}
            </Images>

            {item?.name && (
                <PackageName hasDarkTheme={hasDarkTheme}>
                    {item.name}
                </PackageName>
            )}
            {item?.short_description?.html && (
                <Description>
                    {ReactHtmlParser(item.short_description.html)}
                </Description>
            )}
            <PurchaseInfo>
                {price && <span>{price}</span>}
                <StyledButton
                    disabled={isLoading}
                    buttonType="primary"
                    size="large"
                    icon={{
                        icon: SHOPPING_BASKET_ADD,
                        size: 32,
                        viewbox: "0 0 35 30"
                    }}
                    onClick={() => {
                        if (!isLoading) {
                            handleClick();
                            addProductsToCart([
                                {
                                    quantity: 1,
                                    selected_options: selectedOptions,
                                    sku: item.sku
                                }
                            ]);
                            addProductToDataLayer();
                        }
                    }}
                />
            </PurchaseInfo>
        </Item>
    );
}
