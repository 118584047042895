import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const MergeCarts = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation MergeCarts($sourceCartId: String!, $destinationCartId: String!) {
        mergeCarts(
            source_cart_id: $sourceCartId
            destination_cart_id: $destinationCartId
        ) {
            ...CartContent
        }
    }
`;
