import styled from "styled-components";

import {
    Tagline as BaseTagline,
    TaglineProps
} from "components/shared/decorations/tagline/Tagline";

import { media } from "constants/media";

export const Tagline = styled(BaseTagline)<TaglineProps>`
    display: none;
    height: 350px;
    left: 0;
    margin: auto;
    max-width: 1200px;
    position: absolute;
    right: 0;
    top: -60px;
    width: 100%;
    z-index: 0;

    > span {
        left: -120px;
        max-width: 350px;
    }

    ${media.mdUp} {
        display: block;
    }
`;
