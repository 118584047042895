import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { CHICKEN, Icon, MENU, SHOPPING_BASKET } from "components";
import { headerThemeOptions } from "components/layout/Layout";

import {
    Container,
    MenuButton,
    NavigationDesktop,
    StyledHeader
} from "./Header.styled";
import { Logo } from "./logo/Logo";
import { MenuItems, Menu as MenuMobile } from "./menu/Menu";
import LinkGroup, { headerAlignmentTypes } from "./menu/linkGroup/LinkGroup";
import ShoppingBasketLink from "./shoppingBasketLink/ShoppingBasketLink";

import { useMediaQueryContext } from "helpers/hooks";

interface HeaderTheme {
    darkMode?: boolean;
    theme:
        | headerThemeOptions.light
        | headerThemeOptions.dark
        | headerThemeOptions.recipe;
}

interface HeaderProps {
    isSticky?: boolean;
    primaryLinks: Array<MenuItems>;
    secondaryLinks: Array<MenuItems>;
    theme:
        | headerThemeOptions.light
        | headerThemeOptions.dark
        | headerThemeOptions.recipe;
    darkMode: boolean;
}

export function HeaderMobile({
    primaryLinks,
    secondaryLinks,
    isSticky = false,
    theme,
    darkMode
}: HeaderProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(() => false);

    return (
        <>
            <MenuButton
                darkMode={darkMode}
                isSticky={isSticky}
                type="button"
                onClick={() => setIsOpen(true)}
                aria-label="Open menu"
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        setIsOpen(true);
                    }
                }}
                theme={theme}
            >
                <Icon size={24} icon={MENU} />
            </MenuButton>
            <Logo isSticky={isSticky} view="mobile" />
            <ShoppingBasketLink darkMode={darkMode} />
            <MenuMobile
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                primaryLinks={primaryLinks}
                secondaryLinks={secondaryLinks}
                isSticky={isSticky}
            />
        </>
    );
}

export function Header({ theme }: HeaderTheme): JSX.Element {
    const { data: session } = useSession();
    const authenticated = !!session?.accessToken;

    const { isDesktopView } = useMediaQueryContext();
    const [isSticky, setIsSticky] = useState(false);
    const primaryLinksDesktop = [
        { label: { text: "Kip bestellen" }, url: "/kip-bestellen" },
        { label: { text: "Recepten" }, url: "/recepten" },
        { label: { text: "Hoe het werkt" }, url: "/faq" }
    ];
    const primaryLinksMobile = [
        { label: { text: "Kip bestellen" }, url: "/kip-bestellen" },
        { label: { text: "Recepten" }, url: "/recepten" },
        { label: { text: "Hoe het werkt" }, url: "/faq" },
        {
            isExternal: true,
            isMobileOnly: true,
            label: { text: "Werken bij" },
            url: "https://www.werkenbijmrfillet.nl/"
        }
    ];
    const secondaryLinksDesktop = [
        {
            isExternal: true,
            isMobileOnly: false,
            label: { text: "Werken bij" },
            url: "https://www.werkenbijmrfillet.nl/"
        },
        { label: { text: "Winkels" }, url: "/winkels" },
        {
            label: {
                icon: CHICKEN,
                iconColors: {
                    fill: "transparent",
                    stroke:
                        isDesktopView && theme === headerThemeOptions.light
                            ? "var(--color-brown-50)"
                            : "currentColor"
                },
                text: authenticated ? "Mijn Account" : "Inloggen"
            },
            url: authenticated ? "/mijn-account" : "/inloggen"
        }
    ];
    const secondaryLinksMobile = [
        { label: { text: "Winkels" }, url: "/winkels" },
        {
            label: {
                icon: CHICKEN,
                iconColors: {
                    fill: "transparent",
                    stroke:
                        isDesktopView && theme === headerThemeOptions.light
                            ? "var(--color-brown-50)"
                            : "currentColor"
                },
                text: authenticated ? "Mijn Account" : "Inloggen"
            },
            url: authenticated ? "/mijn-account" : "/inloggen"
        }
    ];
    const { ref, inView } = useInView({
        initialInView: true,
        threshold: 1
    });

    useEffect(() => {
        setIsSticky(!inView);
    }, [inView]);

    const router = useRouter();
    const isRecipeDetail = router.pathname.indexOf("recepten/") >= 1;

    return (
        <StyledHeader ref={ref} darkMode={isRecipeDetail}>
            <Container isSticky={isSticky} darkMode={isRecipeDetail}>
                <NavigationDesktop>
                    <LinkGroup
                        align={headerAlignmentTypes.left}
                        items={primaryLinksDesktop}
                        theme={
                            isRecipeDetail ? headerThemeOptions.recipe : theme
                        }
                        isSticky={isSticky}
                    />
                    <Logo isSticky={isSticky} view="desktop" />
                    <LinkGroup
                        align={headerAlignmentTypes.right}
                        isSticky={isSticky}
                        items={[
                            ...secondaryLinksDesktop,
                            {
                                ariaLabel: "Shopping basket",
                                isCartLink: true,
                                label: { icon: SHOPPING_BASKET, text: "" },
                                url: "/winkelwagen"
                            }
                        ]}
                        theme={
                            isRecipeDetail ? headerThemeOptions.recipe : theme
                        }
                        showStoreSwitcher={isDesktopView}
                    />
                </NavigationDesktop>
                <HeaderMobile
                    darkMode={isRecipeDetail}
                    isSticky={isSticky}
                    primaryLinks={primaryLinksMobile}
                    secondaryLinks={secondaryLinksMobile}
                    theme={theme}
                />
            </Container>
        </StyledHeader>
    );
}
