import { ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";

import { GTM_ID, pageView, shouldFirePageViewEvent } from "lib/gtag";

const handleRouteChange = (url: string) => {
    if (shouldFirePageViewEvent(url)) {
        pageView(url);
    }
};
interface GoogleTagManagerProps {
    children: ReactNode;
}

export const GoogleTagManager = ({
    children
}: GoogleTagManagerProps): JSX.Element => {
    const router = useRouter();
    const [datalayerSet, setDatalayerSet] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        window.dataLayer = window?.dataLayer || [];
        window?.dataLayer?.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime()
        });
        setDatalayerSet(true);
    }, []);

    return (
        <>
            {datalayerSet && GTM_ID && (
                <Head>
                    <script
                        src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
                        async
                    />
                </Head>
            )}
            {children}
        </>
    );
};
