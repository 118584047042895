import styled from "styled-components";

export const Notification = styled.div<{ type: "error" | "info" }>`
    background-color: ${({ type }) =>
        type === "error" ? "var(--color-primary)" : "var(--color-orange-40)"};
    bottom: 0;
    color: var(--color-white);
    height: fit-content;
    padding: 12px 24px;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 19;

    span {
        align-items: center;
        display: flex;
        gap: 16px;
        height: fit-content;
        justify-content: center;
        margin: 0 auto;
        max-width: 1200px;
    }

    svg {
        min-width: 48px;
    }
`;
