import { EVENTS } from "lib/gtag/";

export const pageView = (
    url: string,
    ecommerceObject?: Record<string, unknown>
): void => {
    window.dataLayer = window?.dataLayer || [];
    // Since this event is often used in effects that listen to router changes,
    // routes that include the tagassistant query are filtered out since
    // they can cause events to trigger multiple times.
    if (url.includes("gtm_debug")) {
        return;
    }

    const eventObject = {
        ecommerce: ecommerceObject,
        event: EVENTS.PAGE_VIEW,
        page: url
    };

    window?.dataLayer?.push(eventObject);
};
