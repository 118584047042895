import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { useRouter } from "next/router";

import { BELGIUM, NETHERLANDS } from "components/shared/icon/IconSet";
import { HeaderThemeOptions } from "components/header/menu/linkGroup/LinkGroup";
import { Icon } from "components/shared/icon";
import {
    OptionLabelWrapper,
    StyledDropdown
} from "components/storeSwitcher/StoreSwitcher.styled";

const neterlandsIcon = (
    <Icon icon={NETHERLANDS} viewbox="0 0 24 18" height={18} />
);
const belgiumIcon = <Icon icon={BELGIUM} viewbox="0 0 24 18" height={18} />;
const stores = [
    {
        label: (
            <OptionLabelWrapper>
                {belgiumIcon}
                België
            </OptionLabelWrapper>
        ),
        value: "be"
    },
    {
        label: (
            <OptionLabelWrapper>
                {neterlandsIcon}
                Nederland
            </OptionLabelWrapper>
        ),
        value: "nl"
    }
];

type StoreSwitcherProps = {
    theme?: HeaderThemeOptions;
    isSticky: boolean;
};

export default function StoreSwitcher({
    theme,
    isSticky
}: StoreSwitcherProps): JSX.Element {
    const router = useRouter();
    const { locale: activeLocale } = router;

    const methods = useForm({
        defaultValues: {
            store: stores.find(country => country.value === activeLocale)
        },
        mode: "onChange"
    });

    const { handleSubmit, control } = methods;
    const store = useWatch({ control, name: "store" });

    useEffect(() => {
        if (store?.value !== activeLocale) {
            const { pathname, query, asPath } = router;
            router.push({ pathname, query }, asPath, { locale: store?.value });
        }
    }, [activeLocale, handleSubmit, router, store?.value]);

    return (
        <FormProvider {...methods}>
            <form>
                <StyledDropdown
                    theme={theme}
                    isSticky={isSticky}
                    wrapperWidth="auto"
                    iconColor="currentColor"
                    name="store"
                    overrideValueLabel={
                        activeLocale === "be" ? (
                            <OptionLabelWrapper>
                                {belgiumIcon}
                            </OptionLabelWrapper>
                        ) : (
                            <OptionLabelWrapper>
                                {neterlandsIcon}
                            </OptionLabelWrapper>
                        )
                    }
                    options={stores}
                />
            </form>
        </FormProvider>
    );
}
