import { gql } from "@apollo/client";

export const GetCustomer = gql`
    query GetCustomer {
        customer {
            email
            firstname
            lastname
            taxvat
            addresses {
                id
                city
                country_code
                street
                postcode
                default_billing
                default_shipping
                telephone
            }
            orders {
                items {
                    id
                    order_date
                    total {
                        grand_total {
                            currency
                            value
                        }
                    }

                    items {
                        ... on BundleOrderItem {
                            id

                            product_name
                            product_sku
                            product_type
                            product_sale_price {
                                currency
                                value
                            }
                            bundle_options {
                                label
                                uid
                                values {
                                    id
                                    product_name
                                    product_sku
                                    quantity
                                    uid
                                    price {
                                        value
                                        currency
                                    }
                                }
                            }
                        }
                    }
                }
                page_info {
                    current_page
                }
                items {
                    id
                    number
                    items {
                        id
                    }
                }
            }
        }
    }
`;
