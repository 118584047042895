import styled, { css } from "styled-components";

import { Button } from "components";

import { media } from "constants/media";

export const Item = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
`;

export const Images = styled.div`
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 8px;

    ${media.mdUp} {
        margin-bottom: 16px;
    }
`;

export const ImageContainer = styled.div`
    margin-bottom: 8px;
    position: relative;

    ${media.mdUp} {
        margin-bottom: 16px;
    }

    ${media.xlUp} {
        width: 178px;
    }
`;

export const TypeContainer = styled.div`
    height: 33px;
    position: absolute;
    right: -5px;
    top: 0;
    width: 33px;

    ${media.lgUp} {
        height: 66px;
        right: -10px;
        width: 66px;
    }
`;

export const PackageName = styled.span<{ hasDarkTheme?: boolean }>`
    color: ${({ hasDarkTheme }) =>
        hasDarkTheme ? "var(--color-beige-50)" : "var(--color-brown-50)"};
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;

    ${media.mdUp} {
        font-size: 32px;
        line-height: 48px;
    }
`;

export const Description = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const PurchaseInfo = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 8px;

    > span {
        margin-right: 24px;
    }

    ${media.mdUp} {
        margin-top: 16px;

        > span {
            margin-right: 32px;
        }
    }
`;

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
    border-radius: 50%;
    min-height: unset;
    padding: 0;

    > div {
        padding: 8px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ disabled }) =>
        disabled &&
        css`
            background: var(--color-red-60);
            opacity: 0.8;
        `} /* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */

    ${media.mdUp} {
        padding: 0;

        > div {
            padding: 8px;
        }
    }
`;
