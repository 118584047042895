import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable
} from "@storyblok/react";
import { useRouter } from "next/router";

import { Container } from "./Faq.styled";

import { FaqProvider } from "helpers/context/FaqContext";

export type SbFaqItem = SbBlokData & { category: string };

type FaqItemsBlok = SbBlokData & { items: Array<SbFaqItem> };

function Faq({ blok }: { blok: FaqItemsBlok }): JSX.Element {
    const router = useRouter();
    const categoryQuery = router.query?.categorie;
    const items = blok.items.filter((item: SbFaqItem) => {
        if (categoryQuery) {
            return categoryQuery === item?.category.toLowerCase();
        } else {
            return true;
        }
    });

    return (
        <Container {...storyblokEditable(blok)}>
            <FaqProvider>
                {items.map((nestedBlok: SbFaqItem) => (
                    <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                    />
                ))}
            </FaqProvider>
        </Container>
    );
}

export default Faq;
