import { gql } from "@apollo/client";

export const GetMolliePaymentMethods = gql`
    query GetMolliePaymentMethods($input: MolliePaymentMethodsInput) {
        molliePaymentMethods(input: $input) {
            methods {
                code
                image
                name
            }
        }
    }
`;
