import { ApolloProvider } from "@apollo/client";
import { CookiesProvider } from "react-cookie";
import { SessionProvider } from "next-auth/react";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import { useEffect } from "react";
// import Script from "next/script";

import { Banner } from "components/shared/banner/Banner";
import { BaseHead, GoogleTagManager } from "components";
import { CookiesConsent } from "components";

import { CartProvider } from "helpers/context/CartContext";
import { CustomerProvider } from "helpers/context";
import { GlobalStyle } from "config";
import { MediaQueryProvider } from "helpers/hooks";
import { PackageCartProvider } from "helpers/context/PackageCartContext";
import { SuccessModalProvider } from "helpers/context/SuccessModalContext";
import { pageView, shouldFirePageViewEvent } from "lib/gtag";
import { useApollo } from "lib/apolloClient";
import components from "constants/components";

import type { AppProps } from "next/app";

const accessToken = process.env.NEXT_PUBLIC_STORYBLOK_TOKEN;

storyblokInit({
    accessToken: accessToken,
    components,
    use: [apiPlugin]
});

export default function MyApp({
    Component,
    pageProps: { session, ...pageProps }
}: AppProps): JSX.Element {
    const apolloClient = useApollo(pageProps.initialApolloState);

    // Fires a `pageview` GTM event on page load for pages that
    // don't have their own `pageview` event effect.
    useEffect(() => {
        const { pathname } = window.location;
        if (window?.dataLayer && shouldFirePageViewEvent(pathname)) {
            pageView(window.location.pathname);
        }
    }, []);

    return (
        <>
            <GlobalStyle />
            <BaseHead />
            <SessionProvider session={session}>
                <CookiesProvider>
                    <ApolloProvider client={apolloClient}>
                        <CustomerProvider>
                            <MediaQueryProvider>
                                <GoogleTagManager>
                                    <SuccessModalProvider>
                                        <CartProvider>
                                            <PackageCartProvider>
                                                <Banner />
                                                <Component {...pageProps} />
                                            </PackageCartProvider>
                                        </CartProvider>
                                    </SuccessModalProvider>
                                    <CookiesConsent />
                                </GoogleTagManager>
                            </MediaQueryProvider>
                        </CustomerProvider>
                    </ApolloProvider>
                </CookiesProvider>
            </SessionProvider>
        </>
    );
}
