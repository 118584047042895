export enum PRODUCT_TYPES {
    SIMPLE_PRODUCT = "SimpleProduct",
    BUNDLED_PRODUCT = "BundleProduct"
}

export enum USER_TYPES {
    PRIVATE = "particulier",
    BUSINESS = "zakelijk"
}

export enum FEEDBACK_MESSAGE_TYPES {
    ERROR = "error",
    SUCCESS = "success",
    WARNING = "warning"
}

export enum CUT_TYPES {
    FILET = "kip-filet",
    DRUMSTICK = "kip-drumstick",
    WINGS = "kip-vleugels",
    TIGHS = "kippendij",
    CHICKENROLLADE = "kipdijrollade",
    CHOP = "karbonade",
    CHICKENTENDERLOOIN = "kiphaasjes",
    SMALLBLOCKS = "kipblokjes",
    THIGHSTRIPS = "dijenreepjes",
    WHOLECHICKEN = "hele-kip"
}
