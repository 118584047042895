import { EVENTS } from "lib/gtag";
import { USER_TYPES } from "constants/enum";

interface PaymentData {
    clientType?: string;
    option?: string;
    paymentMethod?: string;
    step?: number;
}

function handleCheckoutUpdate(
    input: USER_TYPES | PaymentData | string | undefined,
    specificField?: "idealBank" | "clientType" | "discountCode"
    // extend the above type alias for future property additions, it'll automatically get picked up and handled by this function
): void {
    if (!input) {
        return;
    }

    const isInputObject = typeof input === "object";

    let existingData: {
        checkout_option?: {
            actionField?: Record<string, string | number | undefined>;
        };
    } | null = {};

    window.dataLayer?.push(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        existingData = this.get("ecommerce");
    });

    if (!existingData) {
        window.dataLayer?.push({
            ecommerce: {
                checkout_option: {
                    actionField: isInputObject
                        ? { ...input }
                        : { [specificField ?? "data"]: input }
                }
            },
            event: EVENTS.CHECKOUT_OPTION
        });

        return;
    }

    if (
        specificField &&
        existingData.checkout_option?.actionField &&
        existingData.checkout_option?.actionField[specificField] === input
    ) {
        return;
    }

    window.dataLayer?.push({
        ecommerce: {
            ...existingData,
            checkout_option: {
                actionField: isInputObject
                    ? {
                          ...existingData.checkout_option?.actionField,
                          ...input
                      }
                    : {
                          ...existingData.checkout_option?.actionField,
                          [specificField ?? "data"]: input
                      }
            }
        },
        event: EVENTS.CHECKOUT_OPTION
    });
}

export const updateClientType = (clientType: USER_TYPES): void => {
    handleCheckoutUpdate(clientType, "clientType");
};

export const updatePayment = (paymentData: PaymentData): void => {
    handleCheckoutUpdate(paymentData);
};

export const updateIdealOption = (idealBank?: string): void => {
    handleCheckoutUpdate(idealBank, "idealBank");
};

export const updateCouponCode = (code?: string): void => {
    handleCheckoutUpdate(code, "discountCode");
};
