type OptionProps = {
    canBeZero?: boolean;
    useDash?: boolean;
    locale?: string;
    currency?: string;
};

export const formatPrice = (
    value?: string | number,
    options?: OptionProps
): string => {
    const canBeZero = options && options.canBeZero ? options.canBeZero : false;
    const useDash = options && options.useDash ? options.useDash : false;
    const locale = options && options.locale ? options.locale : "nl-NL";
    let string;

    const formatter = new Intl.NumberFormat(locale, {
        currency: options?.currency ?? "EUR",
        style: "currency"
    });

    if (!value && canBeZero) {
        return formatter.format(0);
    }

    if ((!value && !canBeZero) || value === undefined) {
        return "Gratis";
    }

    if (typeof value === "string") {
        if (parseInt(value, 10) === 0 && !canBeZero) {
            return "Gratis";
        }
    }

    string = formatter.format(parseFloat(value as string));

    if (useDash) {
        string = string.replace(/,00/, ",-");
    }

    return string;
};
