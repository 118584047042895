import styled, { css } from "styled-components";

import { headerThemeOptions } from "components/layout/Layout";

import { media } from "constants/media";

function selectTheme(
    theme: headerThemeOptions.light | headerThemeOptions.dark
) {
    switch (theme) {
        case headerThemeOptions.light:
            return css`
                color: var(--color-brown-40);
            `;
        case headerThemeOptions.dark:
            return css`
                color: var(--color-beige-60);
            `;
        default:
            break;
    }
}

export const Container = styled.div<{ darkMode: boolean }>`
    align-items: center;
    display: none;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;
    padding: 8px 24px 0;
    position: relative;
    z-index: 10;

    ${media.lgUp} {
        display: flex;
    }

    ${media.xlUp} {
        padding: 8px 0 0;
    }

    /* stylelint-disable-next-line */
    ${({ darkMode }) =>
        darkMode &&
        css`
            background: var(--color-brown-50);

            ${Usp} {
                color: var(--color-beige-40);
            }

            ${media.mdUp} {
                position: relative;
                z-index: 0;

                ::before,
                ::after {
                    background-color: var(--color-brown-50);
                    content: "";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100vw;
                    z-index: -1;
                }

                ::before {
                    left: -100%;
                }

                ::after {
                    right: -100%;
                }
            }
        `}
`;

export const Usp = styled.span<{
    theme: headerThemeOptions.light | headerThemeOptions.dark;
}>`
    font-size: 16px;
    max-width: 242px;
    text-align: right;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ theme }) => theme && selectTheme(theme)}

    ${media.lgUp} {
        font-size: 14px;
        max-width: 100%;
    }

    ${media.xlUp} {
        font-size: 16px;
        max-width: unset;
    }
`;
