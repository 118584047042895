import styled, { css } from "styled-components";

import { Link } from "components/shared/link/Link";

import { media } from "constants/media";

export const LogoContainer = styled.div<{
    isSticky?: boolean;
    view: "mobile" | "desktop";
}>`
    align-items: center;
    background-color: var(--color-red-50);
    display: flex;
    justify-content: center;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.15s ease-in-out;
    z-index: 10;

    &:hover {
        svg {
            margin: 0;
        }
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ view }) =>
        view === "desktop" &&
        css`
            display: none;
        `}

    ${({ view }) =>
        view === "mobile" &&
        css`
            ${media.lgUp} {
                display: none;
            }
        `}

    ${media.lgUp} {
        ${({ view }) =>
            view === "desktop" &&
            css`
                display: block;
            `}
    }

    ${({ isSticky }) =>
        isSticky
            ? css`
                  height: 56px;
                  transform: translate3d(0, 0, 0);
                  width: 76px;

                  ${media.mdUp} {
                      height: 80px;
                  }

                  ${media.lgUp} {
                      top: -24px;
                      width: 122px;
                  }
              `
            : css`
                  padding: 32px 16px 16px;
                  width: 106px;

                  ${media.lgUp} {
                      height: 149px;
                      padding-top: 64px;
                      top: -100px;
                      width: 160px;
                  }

                  ${media.xlUp} {
                      top: -72px;
                  }
              `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

export const MobileLink = styled(Link)`
    ${media.lgUp} {
        display: none;
    }
`;

export const DesktopLink = styled(Link)`
    display: none;

    ${media.lgUp} {
        display: block;
    }
`;
