import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState
} from "react";

interface FaqContextInterface {
    isOpen: string | null | undefined;
    setIsOpen: Dispatch<SetStateAction<null | string | undefined>>;
}

interface FaqContextProviderProps {
    children: JSX.Element | Array<JSX.Element>;
}

const FaqContext = createContext<FaqContextInterface>({
    isOpen: null,
    setIsOpen: () => null
});

export function FaqProvider({
    children
}: FaqContextProviderProps): JSX.Element {
    const [isOpen, setIsOpen] = useState<string | null | undefined>(null);

    const value = useMemo(() => ({ isOpen, setIsOpen }), [isOpen, setIsOpen]);

    return <FaqContext.Provider value={value}>{children}</FaqContext.Provider>;
}

export function useFaq(): FaqContextInterface {
    const context = useContext(FaqContext);

    if (!context) {
        throw new Error("useFaq must be called within a FaqProvider");
    }

    return context;
}
