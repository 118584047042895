import styled, { css } from "styled-components";

import { TaglineProps } from "./Tagline";

export const Container = styled.div<TaglineProps>`
    pointer-events: none;

    > span {
        ${({ isSpinning }) =>
            isSpinning &&
            css`
                @media (prefers-reduced-motion: no-preference) {
                    animation: spin infinite 60s linear;
                }
            `}
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
`;
