import styled, { css } from "styled-components";

import { ACCORDION_THEME } from "./Accordion";

import { media } from "constants/media";

function GetAccordionTheme(theme: ACCORDION_THEME, isOpen = false) {
    switch (theme) {
        case ACCORDION_THEME.BEIGE40:
            return css`
                background-color: var(--color-${theme});
                border: 1px solid var(--color-beige-50);
                color: var(--color-brown-50);
                margin-bottom: 16px;
                padding: 16px;

                /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
                ${isOpen &&
                css`
                    background-color: var(--color-brown-50);
                    box-shadow: 8px 8px 0 var(--color-brown-60);
                    color: var(--color-beige-40);
                    padding: 16px;
                `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
            `;
        default:
        case ACCORDION_THEME.BEIGE50:
            return css`
                background-color: var(--color-beige-55);
                border: 0;
                margin-bottom: 8px;
                padding: 8px;
            `;
    }
}

export const Wrapper = styled.div<{
    isOpen: boolean;
    theme: ACCORDION_THEME;
}>`
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${({ theme, isOpen }) => theme && GetAccordionTheme(theme, isOpen)};

    position: relative;
    width: 100%;
`;

export const TitleWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;

    span:first-child {
        font-size: 14px;

        ${media.mdUp} {
            font-size: 16px;
        }
    }

    strong {
        font-size: 16px;

        ${media.mdUp} {
            font-size: 18px;
        }
    }
`;

export const RightElement = styled.span``;

export const Title = styled.div<{ hasRightElement: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({ hasRightElement }) =>
        hasRightElement ? "flex-start" : "space-between"};
    margin-right: 10px;
    width: 100%;
`;

export const StyledArrow = styled.div<{
    isOpen: boolean;
}>`
    min-height: 11px;
    min-width: 8px;
    transform: rotate(-180deg);
    transition: transform var(--quick-transition-duration) linear;

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(0deg);
            transition: transform 0.15s linear;
        `}/* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;

export const MoreInformation = styled.div<{
    isOpen: boolean;
    theme: ACCORDION_THEME;
}>`
    background-color: var(--color-${({ theme }) => theme});
    font-size: 16px;
    height: 0;
    overflow: hidden;
    position: relative;
    transform: scaleY(0);
    transform-origin: top;
    transition: 100ms transform linear;
    width: 100%;

    ${media.mdUp} {
        font-size: 18px;
    }

    p {
        margin: 16px 0;

        ${media.lgUp} {
            margin: 0 0 32px;
        }
    }

    /* stylelint-disable order/properties-alphabetical-order */ /* stylelint-disable order/order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            height: auto;
            padding: 8px;
            transform: scaleY(1);
        `}

    ${({ isOpen, theme }) =>
        isOpen &&
        theme === ACCORDION_THEME.BEIGE40 &&
        css`
            background: transparent;
        `} /* stylelint-enable order/properties-alphabetical-order */ /* stylelint-enable order/order */
`;
