import styled from "styled-components";

import { Link } from "components/shared/link/Link";

import { media } from "constants/media";

export const Container = styled(Link)`
    gap: 8px;

    > span {
        color: var(--color-orange-50);
        font-size: 12px;
        font-weight: var(font-weight-bold);
        line-height: 24px;

        ${media.mdUp} {
            line-height: 32px;
        }
    }

    &:hover svg {
        margin: 0;
    }

    ${media.mdUp} {
        display: none;
    }
`;
