import { Dispatch, ReactNode, SetStateAction } from "react";
import Image from "next/image";

import {
    BasketWrapper,
    CloseButton,
    Container,
    Decoration,
    Header
} from "components/header/menu/Menu.styled";
import { CLOSE_WITH_TEXT, Icon, SHOPPING_BASKET } from "components";
import LinkGroup, {
    headerAlignmentTypes
} from "components/header/menu/linkGroup/LinkGroup";

import { useBodyScrollLock, useMediaQueryContext } from "helpers/hooks";

export interface MenuLinkLabel {
    text: string;
    icon?: ReactNode;
    iconColors?: {
        stroke?: string;
        fill?: string;
    };
}

export interface MenuItems {
    label: MenuLinkLabel;
    url: string;
    isButton?: boolean;
    ariaLabel?: string;
    isExternal?: boolean;
}

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    primaryLinks: Array<MenuItems>;
    secondaryLinks: Array<MenuItems>;
    isSticky: boolean;
}

export function Menu({
    isOpen = false,
    setIsOpen = () => false,
    primaryLinks,
    secondaryLinks,
    isSticky
}: Props): JSX.Element {
    const winkelsItem = secondaryLinks.filter(link => link.url === "/winkels");
    useBodyScrollLock(isOpen);
    const { isMobileView } = useMediaQueryContext();
    const homeItem = { label: { text: "Home" }, url: "/" };

    return (
        <Container isOpen={isOpen}>
            <Header>
                <CloseButton
                    type="button"
                    onClick={() => setIsOpen(false)}
                    aria-label="Close menu"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setIsOpen(false);
                        }
                    }}
                >
                    <Icon icon={CLOSE_WITH_TEXT} size={24} />
                </CloseButton>
                <BasketWrapper>
                    <LinkGroup
                        align={headerAlignmentTypes.right}
                        isSticky={isSticky}
                        items={[
                            ...secondaryLinks,
                            {
                                ariaLabel: "Shopping basket",
                                isCartLink: true,
                                label: { icon: SHOPPING_BASKET, text: "" },
                                url: "/winkelwagen"
                            }
                        ]}
                    />
                </BasketWrapper>
            </Header>
            <Decoration>
                <Image
                    src="/images/now-were-tokkin.svg"
                    width={376}
                    height={376}
                    alt="now we're tokkin"
                    role="presentation"
                    layout="responsive"
                />
            </Decoration>
            <LinkGroup
                items={[homeItem, ...winkelsItem, ...primaryLinks]}
                type="primary"
                isSticky={isSticky}
            />
            <LinkGroup
                items={secondaryLinks.filter(
                    link => link.url !== winkelsItem[0]?.url
                )}
                type="secondary"
                isSticky={isSticky}
                showStoreSwitcher={isMobileView}
            />
        </Container>
    );
}
