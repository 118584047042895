import Image from "next/image";
import MainLogo from "public/assets/lottie/main-logo.json";
import dynamic from "next/dynamic";

import { DesktopLink, LogoContainer, MobileLink } from "./Logo.styled";

import { useMediaQueryContext } from "helpers/hooks";

const LottieWrapper = dynamic(
    () => import("components/shared/lottieWrapper/LottieWrapper")
);

interface LogoProps {
    isSticky?: boolean;
    className?: string;
    view: "desktop" | "mobile";
}

export function Logo({ className, isSticky, view }: LogoProps): JSX.Element {
    const { isMobileView, isTabletView, isDesktopView } =
        useMediaQueryContext();
    let logoWidth = isMobileView ? 114 : 228;
    let logoHeight = isMobileView ? 84 : 166;

    if (isSticky && isTabletView) {
        logoWidth = 94;
        logoHeight = 84;
    } else if (isSticky && isDesktopView) {
        logoWidth = 156;
        logoHeight = 108;
    }

    return (
        <LogoContainer isSticky={isSticky} className={className} view={view}>
            <DesktopLink href="/" noUnderline>
                <LottieWrapper
                    style={{ margin: isSticky ? "-15px" : "-50px" }}
                    width={`${logoWidth.toString()}px`}
                    height={`${logoHeight.toString()}px`}
                    triggerWheneverInView
                    playOnMouseEnter
                    isRepeating
                    config={{
                        animationData: MainLogo,
                        autoplay: true,
                        loop: false
                    }}
                />
            </DesktopLink>
            <MobileLink href="/" noUnderline ariaLabel="homelink">
                <Image
                    alt="Logo"
                    src="/images/logo-mobile.svg"
                    width={74}
                    loading="eager"
                    priority
                    height={37}
                    objectFit="cover"
                />
            </MobileLink>
        </LogoContainer>
    );
}
