import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    from
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { getSession } from "next-auth/react";
import { onError } from "@apollo/client/link/error";
import { useMemo } from "react";
import ApolloLinkTimeout from "apollo-link-timeout";

import { debugError, debugLog } from "helpers/util/log";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
    // Aborts requests that take longer than 30000ms.
    const timeoutLink = new ApolloLinkTimeout(30000);
    const debugLink = new ApolloLink((operation, forward) => {
        process.env.STATE_DEBUG_MODE &&
            debugLog(`[APOLLO] - ${operation.operationName}: `);
        return forward(operation);
    });

    const errorLink = onError(
        ({ graphQLErrors, networkError, forward, operation }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, path }) => {
                    process.env.STATE_DEBUG_MODE &&
                        debugError(`[GRAPHQL ERROR] ${path}`, message);

                    if (message === "The cart isn't active.") {
                        forward(operation);
                    }
                });
            } else if (networkError) {
                process.env.STATE_DEBUG_MODE &&
                    debugError(
                        `[NETWORK ERROR] ${networkError.name}`,
                        networkError.message
                    );
            }
        }
    );

    const httpLink = new RetryLink().split(
        operation => operation.getContext().name === "storyblok",
        new HttpLink({
            credentials: "include",
            headers: {
                token: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
                version:
                    process.env.NODE_ENV === "production"
                        ? "published"
                        : "draft"
            },
            uri: process.env.NEXT_PUBLIC_STORYBLOK_API
        }),
        new HttpLink({
            headers: {
                "Content-Type": "application/json"
            },
            uri: process.env.NEXT_PUBLIC_MAGENTO_API
        })
    );

    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext(async ({ headers = {} }) => {
            const session = await getSession();
            return {
                headers: {
                    Authorization: session?.accessToken
                        ? `Bearer ${session.accessToken}`
                        : "",
                    ...headers
                }
            };
        });

        return forward(operation);
    });

    return new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Customer: {
                    keyFields: ["email"]
                }
            }
        }),
        connectToDevTools: process.env.NODE_ENV === "development",
        link: from([
            authMiddleware,
            debugLink,
            errorLink,
            timeoutLink,
            httpLink
        ]),
        ssrMode: typeof window === "undefined" // Disables forceFetch on the server (so queries are only run once)
    });
}

export function useApollo(
    pageProps: NormalizedCacheObject
): ApolloClient<NormalizedCacheObject> {
    return useMemo(() => initializeApollo(pageProps), [pageProps]);
}

export function initializeApollo(
    initialState = {}
): ApolloClient<NormalizedCacheObject> {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        _apolloClient.cache.restore({
            ...existingCache,
            ...initialState
        });
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}
