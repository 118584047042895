import { SvgProps } from "types/input";

export function FreezebagsIcon({
    width = 64,
    height = 64
}: SvgProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 64 64"
            width={width}
            height={height}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#E5CCBB"
                    d="M0 32C0 12.204 12.665 0 32 0s32 12.204 32 32-12.675 32-32 32S0 51.778 0 32Z"
                />
                <path fill="#fff" d="M57.38 28.037H6V37.6h51.38v-9.563Z" />
                <path
                    fill="#338E84"
                    d="m46.495 11.176-36.33 36.52 6.727 6.762 36.331-36.52-6.728-6.762Z"
                />
                <path
                    stroke="#3E2313"
                    strokeMiterlimit="10"
                    d="M26.928 7v51.637h9.514V7h-9.514Z"
                />
                <path
                    fill="#3E2313"
                    d="m10.155 17.931 36.33 36.52 6.728-6.763-36.33-36.52-6.728 6.763Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
