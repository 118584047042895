import { gql } from "@apollo/client";

export const ResetPassword = gql`
    mutation ResetPassword(
        $email: String!
        $token: String!
        $newPassword: String!
    ) {
        resetPassword(
            email: $email
            resetPasswordToken: $token
            newPassword: $newPassword
        )
    }
`;
