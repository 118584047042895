import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const RemoveCouponFromCart = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation remvoe($input: RemoveCouponFromCartInput!) {
        removeCouponFromCart(input: $input) {
            cart {
                ...CartContent
            }
        }
    }
`;
