import { SvgProps } from "types/input";

export function InstagramIcon({
    width = "64",
    height = "64"
}: SvgProps): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>instagram icoon</title>
            <g clipPath="url(#clip0_29_3270)">
                <path
                    d="M0 32C0 12.2037 12.6653 0 32 0C51.3347 0 64 12.2037 64 32C64 51.7963 51.3253 64 32 64C12.6746 64 0 51.7778 0 32Z"
                    fill="#E5CCBB"
                />
                <rect x="13" y="13" width="37" height="19" fill="#338E84" />
                <rect
                    x="13.5"
                    y="32.5"
                    width="36"
                    height="17"
                    stroke="#3E2313"
                />
                <circle cx="31.5" cy="31.5" r="12.5" fill="white" />
                <circle cx="44" cy="19" r="3" fill="#3E2313" />
            </g>
            <defs>
                <clipPath id="clip0_29_3270">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
