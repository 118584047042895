import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const SetShippingAddressesOnCart = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation setShippingAddressesOnCart(
        $cartId: String!
        $shippingAddress: [ShippingAddressInput]!
    ) {
        setShippingAddressesOnCart(
            input: { cart_id: $cartId, shipping_addresses: $shippingAddress }
        ) {
            cart {
                ...CartContent
            }
        }
    }
`;
