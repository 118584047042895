import { Children, ForwardedRef, ReactNode, forwardRef } from "react";

import {
    ButtonComponent,
    ButtonContent,
    StyledIcon,
    StyledIconWrapper
} from "components/shared/buttonElement/ButtonElement.styled";
import { IconProps } from "components/shared/icon/Icon";
import { Underline } from "components/shared/link/Link.styled";

export type ButtonVariant =
    | "primary"
    | "secondary"
    | "link"
    | "ghost"
    | "tertiary"
    | "transparent"
    | undefined;

export type ButtonProps = {
    size?: "small" | "large" | "round" | "link" | "simple";
    buttonType?: ButtonVariant;
    variant?: "blue" | "orange" | "brown" | "red";
    type?: "button" | "submit";
    disabled?: boolean;
    onClick?: () => void;
    children?: ReactNode;
    reversedOrder?: boolean;
    className?: string;
    underline?: boolean;
    icon?: IconProps;
    $showUnderlineOnMobile?: boolean;
};

// eslint-disable-next-line react/display-name
export const ButtonElement = forwardRef(
    (
        {
            className,
            children,
            disabled = false,
            onClick,
            buttonType = "primary",
            type = "button",
            reversedOrder = false,
            size = "large",
            variant = "blue",
            underline = true,
            icon,
            $showUnderlineOnMobile = false
        }: ButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ): JSX.Element => {
        return (
            <ButtonComponent
                ref={ref}
                onClick={onClick}
                type={type}
                disabled={disabled}
            >
                <ButtonContent
                    buttonType={buttonType}
                    isDisabled={disabled}
                    reversedOrder={reversedOrder}
                    size={size}
                    variant={variant}
                    className={className}
                >
                    {!!icon?.icon && (
                        <StyledIconWrapper
                            hasChildren={!!Children.count(children)}
                            reversedOrder={reversedOrder}
                        >
                            <StyledIcon {...icon} />
                        </StyledIconWrapper>
                    )}
                    {children}
                    {(underline || $showUnderlineOnMobile) && (
                        <Underline
                            $isSimple={size === "simple"}
                            fill="currentColor"
                            width={265}
                            preserveAspectRatio="none"
                            $showUnderlineOnMobile={$showUnderlineOnMobile}
                        />
                    )}
                </ButtonContent>
            </ButtonComponent>
        );
    }
);
