import { gql } from "@apollo/client";

export const MollieCustomerOrder = gql`
    query MollieCustomerOrder($hash: String!) {
        mollieCustomerOrder(hash: $hash) {
            id

            status
            carrier
            number
            order_date
            payment_methods {
                type
                name
                additional_data {
                    name
                    value
                }
            }
            invoices {
                items {
                    id
                    discounts {
                        amount {
                            currency
                            value
                        }
                        label
                    }
                    quantity_invoiced
                    product_name
                    product_sku
                    product_sale_price {
                        currency
                        value
                    }
                }
            }
            total {
                grand_total {
                    value
                    currency
                }
                total_tax {
                    currency
                    value
                }
                total_shipping {
                    currency
                    value
                }
                discounts {
                    amount {
                        currency
                        value
                    }
                    label
                }
            }
        }
    }
`;
