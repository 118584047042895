import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const GetGuestCart = gql`
    ${CART_CONTENT_FRAGMENT}

    query GetGuestCart($cartId: String!) {
        cart(cart_id: $cartId) {
            ...CartContent
        }
    }
`;
