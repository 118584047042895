import { gql } from "@apollo/client";

export const CreateCustomer = gql`
    mutation createCustomerV2($input: CustomerCreateInput!) {
        createCustomerV2(input: $input) {
            customer {
                email
                firstname
                lastname
                taxvat
            }
        }
    }
`;
