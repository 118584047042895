import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const GetCustomerCart = gql`
    ${CART_CONTENT_FRAGMENT}

    query GetCustomerCart {
        customerCart {
            id
            ...CartContent
        }
    }
`;
