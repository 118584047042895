import { gql } from "@apollo/client";

export const CreateCustomerAddress = gql`
    mutation CreateCustomerAddress($input: CustomerAddressInput!) {
        createCustomerAddress(input: $input) {
            id
            city
            street
            postcode
            default_billing
            default_shipping
        }
    }
`;
