import dynamic from "next/dynamic";

import { CLOSE_WITH_TEXT, Icon } from "components";
import {
    IconWrapper,
    StyledContent,
    StyledContentWrapper,
    StyledHeader
} from "components/shared/modal/Modal.styled";

import { useBodyScrollLock } from "helpers/hooks/useBodyScrollLock";
import { useMediaQueryContext } from "helpers/hooks";
const ModalComponent = dynamic(() => import("react-modal"));

interface ModalProps {
    showModal: boolean;
    onClose: () => void;
    title?: string;
    isOpen: boolean;
    contentLabel: string;
    children?: React.ReactNode;
    maxWidth?: number;
    minWidth?: number;
    withCloseButton?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    mobilePadding?: string;
    desktopPadding?: string;
    contentClassName?: string;
    headerAlignement?: "left" | "center" | "right";
    contentAlignement?: "left" | "center" | "right";
    rowGap?: number;
}

export function Modal({
    children,
    contentLabel,
    isOpen,
    maxWidth = 784,
    minWidth = 272,
    onClose,
    showModal,
    title,
    withCloseButton = false,
    shouldCloseOnOverlayClick = true,
    mobilePadding,
    contentClassName,
    desktopPadding,
    headerAlignement = "left",
    contentAlignement = "left",
    rowGap = 16
}: ModalProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    useBodyScrollLock(showModal);

    return (
        <ModalComponent
            isOpen={isOpen}
            contentLabel={contentLabel}
            onRequestClose={() => onClose()}
            ariaHideApp={false}
            className="react-modal"
            overlayClassName="react-modal-overlay"
            closeTimeoutMS={400}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <StyledContentWrapper maxWidth={maxWidth} minWidth={minWidth}>
                {withCloseButton && (
                    <IconWrapper
                        onClick={() => onClose()}
                        isMobileView={isMobileView}
                    >
                        <Icon
                            icon={CLOSE_WITH_TEXT}
                            color="var(--color-secondary)"
                            size={isMobileView ? 16 : 24}
                        />
                    </IconWrapper>
                )}

                <StyledContent
                    mobilePadding={mobilePadding}
                    desktopPadding={desktopPadding}
                    contentAlignement={contentAlignement}
                    rowGap={rowGap}
                    className={contentClassName}
                >
                    {title && (
                        <StyledHeader headerAlignement={headerAlignement}>
                            {title}
                        </StyledHeader>
                    )}
                    {children}
                </StyledContent>
            </StyledContentWrapper>
        </ModalComponent>
    );
}

export default Modal;
