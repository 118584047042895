import styled from "styled-components";

import { media } from "constants/media";

export const StyledTooltip = styled.div`
    display: inline-block;
    position: relative;
    width: fit-content;
`;

export const ToolTipContent = styled.div`
    background-color: var(--color-brown-50);
    color: var(--color-white);
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-medium);
    left: 50%;
    line-height: 24px;
    padding: 16px;
    position: absolute;
    top: -8px;
    transform: translate(-50%, -100%);
    width: 288px;

    ${media.mdUp} {
        /* display gets overwritten on button hover */
        display: none;
        font-size: 16px;
        line-height: 32px;
        width: 376px;
    }

    &::before {
        background-color: var(--color-brown-50);
        bottom: -7px;
        content: "";
        height: 8px;
        left: calc(50% - 3px);
        position: absolute;
        transform: rotate(45deg) translateX(-50%);
        width: 8px;
    }
`;

export const TooltipButton = styled.div<{
    align: "center" | "flex-start" | "flex-end";
}>`
    align-items: ${({ align }) => align};
    background: transparent;
    border: 0;
    color: var(--color-blue-50);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    line-height: 24px;
    outline: 0;
    padding: 0;
    pointer-events: auto;
    transition: color 250ms ease;

    &:hover {
        color: var(--color-blue-60);

        ${media.mdUp} {
            + ${ToolTipContent} {
                display: block;
            }
        }
    }

    ${media.mdUp} {
        font-size: 16px;
        line-height: 32px;
    }
`;
