/* eslint-disable react/no-danger */
import { css } from "styled-components";
import NextHead from "next/head";

export function BaseHead(): JSX.Element {
    const googleSiteVerificationToken =
        "XRv2AHk5pOWR3pSp_6ofsMeamE_OPFGXYjdV4QQjKxU";
    const themeColor = "#FFFFFF";
    const applicationName = "Mr. Fillet | Verse kip | Gratis bezorgd";

    return (
        <NextHead>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
            />
            <meta
                name="google-site-verification"
                content={googleSiteVerificationToken}
            />
            <link
                rel="dns-prefetch preconnect"
                as="script"
                href="https://www.googletagmanager.com/"
                crossOrigin="anonymous"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" sizes="16x16 32x32 64x64" href="/favicon.ico" />
            <link
                rel="icon"
                type="image/png"
                sizes="196x196"
                href="/favicon-192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="160x160"
                href="/favicon-160.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicon-96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="64x64"
                href="/favicon-64.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16.png"
            />
            <link rel="apple-touch-icon" href="/favicon-57.png" />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/favicon-114.png"
            />
            <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72.png" />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/favicon-144.png"
            />
            <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60.png" />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/favicon-120.png"
            />
            <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76.png" />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/favicon-152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicon-180.png"
            />
            <link rel="manifest" href="/manifest.json" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
            />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="msapplication-TileImage" content="/favicon-144.png" />
            <meta name="msapplication-tap-highlight" content="no" />
            <meta name="theme-color" content={themeColor} />
            <meta name="application-name" content={applicationName} />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="apple-mobile-web-app-title" content={applicationName} />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="msapplication-config" content="/browserconfig.xml" />
            <meta name="msapplication-TileColor" content={themeColor} />
            <link rel="manifest" href="/manifest.json" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <meta name="twitter:card" content="summary_large_image" />
            <script
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                async
            ></script>
            <style
                /* prettier-ignore */
                dangerouslySetInnerHTML={{__html: css`
                        @font-face {
                            font-display: swap;
                            font-family: "Space Grotesk";
                            font-style: normal;
                            font-weight: normal;
                            src: local("SpaceGrotesk-Regular"), url("/fonts/SpaceGrotesk-Regular.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Space Grotesk";
                            font-style: bold;
                            font-weight: 700;
                            src: local("SpaceGrotesk-Bold"), url("/fonts/SpaceGrotesk-Bold.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Space Grotesk";
                            font-style: medium;
                            font-weight: 500;
                            src: local("SpaceGrotesk-Medium"), url("/fonts/SpaceGrotesk-Medium.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Bely Display";
                            font-style: normal;
                            font-weight: 400;
                            src: local("BelyDisplay"), url("/fonts/BelyDisplay.woff2") format("woff2");
                        }
                        @font-face {
                            font-display: swap;
                            font-family: "Righteous";
                            font-style: normal;
                            font-weight: 400;
                            src: local("Righteous"), url("/fonts/Righteous.woff2") format("woff2");
                        }
                        /* css function of styled-components returns a FlattenSimpleInterpolation type,
                        which does not completely overlap with the type string expected by dangerouslySetInnerHTML */
                    ` as unknown as string
                }}
            />
        </NextHead>
    );
}
