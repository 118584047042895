import styled, { css } from "styled-components";

import {
    GetLinkStyling,
    disabledLinkStyles
} from "components/shared/link/Link.styled";
import { Icon } from "components/shared/icon/Icon";

import { ButtonVariant } from "./ButtonElement";

import { media } from "constants/media";

function determineSizeStyling(type: string) {
    switch (type) {
        case "small":
            return css`
                font-size: 16px;
                line-height: 22px;
                min-height: 32px;
                padding: 8px 16px;
            `;
        case "round":
            return css`
                border-radius: 50%;
                font-size: 14px;
                line-height: 22px;
                padding: 10px;
            `;
        case "large":
            return css`
                font-size: 20px;
                line-height: 32px;
                padding: 16px 24px;
            `;
        case "simple":
            return css`
                font-size: 16px;
                line-height: 24px;
                padding: 0;

                ${media.lgUp} {
                    font-size: 18px;
                    line-height: 32px;
                }
            `;
        case "link": // CHECK PLACES WHERE THIS IS USED TO SEE IF IT"S THE SAME AS DESIGN SINCE I HAD TO CHANGE
            return css`
                font-size: 16px;
                font-weight: normal;
                line-height: 32px;
                padding: 8px 16px;

                ${media.lgUp} {
                    font-size: 20px;
                    line-height: 32px;
                    padding: 16px 24px;
                }
            `;
        default:
            return css`
                font-size: 18px;
                line-height: 32px;
                min-height: 45px;
                padding: 16px 0;
            `;
    }
}

function determineTypeStyling(type: ButtonVariant) {
    switch (type) {
        case "secondary":
            return css`
                background-color: var(--color-brown-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);

                :active {
                    background-color: var(--color-brown-40);
                    outline: 8px solid rgba(62, 35, 19, 0.4);
                }

                :hover {
                    background-color: var(--color-brown-40);
                }
            `;
        case "tertiary":
            return css`
                background-color: var(--color-brown-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);

                :active {
                    background-color: var(--color-brown-40);
                    outline: 8px solid rgba(62, 35, 19, 0.4);
                }

                :hover {
                    background-color: var(--color-brown-40);
                }
            `;
        case "ghost":
            return css`
                background-color: transparent;
                border: 2px solid var(--color-brown-50);
                color: var(--color-brown-50);
                font-family: var(--font-secondary);

                &&& {
                    &:active {
                        background-color: var(--color-beige-50);
                        border-color: var(--color-brown-40);
                        color: var(--color-brown-40);
                    }

                    &:hover {
                        border-color: transparent;
                        box-shadow: 0 0 0 4px var(--color-brown-40);
                    }
                }
            `;
        case "transparent":
            return css`
                background-color: transparent;
                padding: 0;
            `;
        case "primary":
            return css`
                background-color: var(--color-red-50);
                color: var(--color-beige-40);
                font-family: var(--font-secondary);
                font-weight: var(--font-weight-normal);

                :active {
                    background-color: var(--color-red-40);
                    outline: 8px solid var(--color-red-40-transparent);
                }

                :hover {
                    background-color: var(--color-red-40);
                }
            `;
        default:
            return;
    }
}

function selectDisabledStyling(type: string) {
    switch (type) {
        case "ghost":
            return css`
                background-color: transparent;
                border-color: var(--color-beige-60);
                color: var(--color-beige-60);
                cursor: default;
                outline: transparent;
                pointer-events: none;

                :active,
                :focus,
                :hover {
                    background-color: transparent;
                }
            `;
        default:
            return css`
                background-color: var(--color-beige-45);
                color: var(--color-beige-60);
                cursor: default;
                pointer-events: none;

                :active,
                :focus,
                :hover {
                    background-color: transparent;
                }
            `;
    }
}

export const ButtonComponent = styled.button<{ type: "submit" | "button" }>`
    background: none;
    border: 0;
    display: flex;
    outline: none;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 1;

    :disabled {
        cursor: not-allowed;
    }

    ${media.mdUp} {
        width: auto;
    }
`;

export const ButtonContent = styled.div<{
    isDisabled: boolean;
    reversedOrder: boolean;
    buttonType: ButtonVariant;
    size: string;
    variant: string;
}>`
    align-items: center;
    border: 0;
    border-radius: 70px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    position: relative;
    transition: all var(--quick-transition-duration) ease-in-out;
    z-index: 1;

    /* stylelint-disable */
    ${({ variant, buttonType, size }) =>
        buttonType === "link" &&
        variant &&
        GetLinkStyling({
            $isSimple: size === "simple",
            showUnderline: true,
            variant
        })};

    ${({ size }) => determineSizeStyling(size)};
    ${({ buttonType }) =>
        buttonType !== "link" && determineTypeStyling(buttonType)};
    ${({ reversedOrder }) =>
        reversedOrder &&
        css`
            flex-direction: row-reverse;
        `};
    ${({ isDisabled, buttonType }) =>
        isDisabled && buttonType === "link" && disabledLinkStyles};

    ${({ isDisabled, buttonType }) =>
        isDisabled &&
        buttonType &&
        buttonType !== "link" &&
        selectDisabledStyling(buttonType)}; /* stylelint-enable */
`;

export const StyledIconWrapper = styled.div<{
    hasChildren?: boolean;
    reversedOrder?: boolean;
}>`
    align-items: center;
    display: flex;
    height: 100%;

    ${({ hasChildren, reversedOrder }) =>
        hasChildren &&
        css`
            ${reversedOrder ? "padding-left: 4px" : "padding-right: 4px"};

            ${media.mdUp} {
                ${reversedOrder ? "padding-left: 15px" : "padding-right: 15px"};
            }
        `};
`;

export const StyledIcon = styled(Icon)`
    height: 100%;
`;
