import {
    ArrowsWrapper,
    PreviousIcon,
    SlideIndicator,
    SlideIndicatorWrapper,
    StyledSliderControls
} from "components/shared/sliderControls/SliderControls.styled";
import { Button } from "components/shared/button";
import { CHEVRON, Icon } from "components/shared/icon";

import { ButtonVariant } from "../buttonElement/ButtonElement";

export enum SliderControlTheme {
    PRIMARY = "primary",
    BLACK_AND_WHITE = "black-and-white",
    WHITE_BUTTONS = "white-buttons"
}

export interface SliderControlsProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Current index for slider component */
    currentSlide: number;
    /** Total number of slides in the carousel */
    totalSlides: number;
    /** onChange callback function for when one of the controls gets pressed */
    onChange: (newIndex: number) => void;
    /** Allowing the user to click next and previous when at the start or end of the slider */
    isAllowedToLoop?: boolean;
    /** Number of slides to scroll on next / previous */
    slidesToScroll?: number;
    /** Theme for the colors of the controls */
    theme?: SliderControlTheme;
    /** Color that the spacing between items should assume (usually the background color of the container in which the controller is positioned).
     *  Used for the layout that the first and last items have in the design. */
    slideIndicatorSpacingColor?: string;
}

function SliderControls({
    className,
    currentSlide,
    totalSlides,
    onChange,
    isAllowedToLoop = false,
    slidesToScroll = 1,
    theme = SliderControlTheme.PRIMARY,
    slideIndicatorSpacingColor = "var(--color-beige-40)"
}: SliderControlsProps): JSX.Element {
    const onPreviousClick = () => {
        if (currentSlide > 0) {
            onChange(currentSlide - slidesToScroll);
        }

        if (currentSlide === 0 && isAllowedToLoop) {
            onChange(totalSlides - 1);
        }
    };

    const onNextClick = () => {
        if (currentSlide < totalSlides - 1) {
            onChange(currentSlide + slidesToScroll);
        }

        if (currentSlide === totalSlides - 1 && isAllowedToLoop) {
            onChange(0);
        }
    };

    let buttonType: ButtonVariant;
    let buttonIconColor: string;
    let slideIndicatorColor: string;
    let slideIndicatorColorSelected: string;

    const indecators = new Array(totalSlides).fill("");

    switch (theme) {
        case SliderControlTheme.PRIMARY:
        default: {
            buttonType = "link";
            buttonIconColor = "currentColor";
            slideIndicatorColorSelected = "var(--color-primary)";
            slideIndicatorColor = "var(--color-beige-50)";
        }
    }

    return (
        <StyledSliderControls className={className}>
            <ArrowsWrapper>
                <Button
                    size="link"
                    variant="brown"
                    buttonType={buttonType}
                    onClick={() => onPreviousClick()}
                    disabled={!isAllowedToLoop && currentSlide === 0}
                    underline={false}
                >
                    <PreviousIcon
                        icon={CHEVRON}
                        size={22}
                        fill={buttonIconColor}
                    />
                </Button>
            </ArrowsWrapper>
            <SlideIndicatorWrapper>
                {indecators.map((_, index) => (
                    <SlideIndicator
                        key={`slide__${index}`}
                        color={
                            index === currentSlide
                                ? slideIndicatorColorSelected
                                : slideIndicatorColor
                        }
                        widthPercentage={100 / totalSlides}
                        isSelected={index === currentSlide}
                        spacingColor={slideIndicatorSpacingColor}
                    />
                ))}
            </SlideIndicatorWrapper>
            <ArrowsWrapper>
                <Button
                    size="link"
                    variant="brown"
                    buttonType={buttonType}
                    onClick={() => onNextClick()}
                    disabled={
                        !isAllowedToLoop &&
                        currentSlide + slidesToScroll === totalSlides
                    }
                >
                    <Icon icon={CHEVRON} size={22} fill={buttonIconColor} />
                </Button>
            </ArrowsWrapper>
        </StyledSliderControls>
    );
}

export default SliderControls;
