import styled from "styled-components";

import { media } from "constants/media";

export const Label = styled.label<{ disabled: boolean }>`
    /* stylelint-disable order/properties-alphabetical-order*/
    -webkit-tap-highlight-color: transparent;
    align-items: flex-start;
    /* stylelint-enable order/properties-alphabetical-order*/
    color: ${props => (props.disabled ? "var(--color-grey)" : "inherit")};
    cursor: pointer;
    display: inline-flex;
    font-size: var(--type-paragraph-font-size);
    line-height: var(--type-paragraph-line-height);
    margin: 4px 4px 4px 0;
`;

export const StyledRadio = styled.span<{ theme: "red" | "green" }>`
    align-items: center;
    border: 2px solid
        ${({ theme }) =>
            theme === "red" ? "var(--color-primary)" : "var(--color-green-50)"};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: 8px;
    min-width: 24px;
    /* trick for aligning multi-line labels */
    transform: translateY(-0.05em);
    transition: var(--default-transition-duration) border ease-in-out;
    width: 24px;

    ${media.mdUp} {
        margin-top: 4px;
    }
`;

export const Fill = styled.div<{ theme: "red" | "green" }>`
    background: ${({ theme }) =>
        theme === "red" ? "var(--color-primary)" : "var(--color-green-50)"};
    border-radius: 50%;
    height: 14px;
    opacity: 0;
    transform: scale(0);
    transition: var(--default-transition-duration) all ease-in-out;
    visibility: hidden;
    width: 14px;
`;

export const RadioInput = styled.input<{ theme: "red" | "green" }>`
    display: none;
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + ${StyledRadio} {
        border: 2px solid
            ${({ theme }) =>
                theme === "red"
                    ? "var(--color-primary)"
                    : "var(--color-green-50)"};

        ${Fill} {
            opacity: 1;
            transform: scale(1);
            visibility: visible;
        }
    }

    &:hover + ${StyledRadio} {
        border: 2px solid
            ${({ theme }) =>
                theme === "red"
                    ? "var(--color-primary)"
                    : "var(--color-green-50)"};
    }

    &:focus + ${StyledRadio} {
        border: 2px solid
            ${({ theme }) =>
                theme === "red"
                    ? "var(--color-primary)"
                    : "var(--color-green-50)"};
        box-shadow: 0 0 5px
            ${({ theme }) =>
                theme === "red"
                    ? "var(--color-primary)"
                    : "var(--color-green-50)"};
    }

    &:disabled + ${StyledRadio} {
        background-color: transparent;
        border: 2px solid
            ${({ theme }) =>
                theme === "red"
                    ? "var(--color-primary)"
                    : "var(--color-green-50)"};
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
