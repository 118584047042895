import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import React from "react";

type Props = {
    blok: SbBlokData & { body: Array<SbBlokData>; blocks: Array<SbBlokData> };
};

export default function Page({ blok }: Props): JSX.Element {
    return (
        <>
            {blok?.body?.map(blok => (
                <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
            {blok?.blocks?.map(blok => (
                <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
        </>
    );
}
