import styled, { css } from "styled-components";

import { Icon } from "components/shared/icon/Icon";

export const StyledBreadcrumbsWrapper = styled.div`
    overflow: hidden;
    padding: 16px 0;
`;

export const StyledBreadcrumbs = styled.div`
    display: flex;
    margin: 0 auto;
`;

export const BreadcrumbItem = styled.div`
    align-items: center;
    display: flex;
    text-decoration: none;
`;

export const BreadcrumbItemLink = styled.a`
    align-items: center;
    display: flex;
    text-decoration: none;
`;

export const BreadcrumbSeperatorIcon = styled(Icon)`
    color: var(--color-secondary);
    margin: 0 8px;
`;

export const BreadcrumbLabel = styled.div<{
    isDisabled: boolean;
}>`
    color: var(--color-secondary);
    font-weight: normal;
    line-height: 20px;
    max-width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Calling first-letter to only capitalize the first word, not every word. */
    &::first-letter {
        text-transform: uppercase;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isDisabled }) =>
        !isDisabled &&
        css`
            color: var(--color-grey);
            transition: var(--default-transition-duration) color ease-in-out;

            &:hover {
                color: var(--color-light-grey);
            }
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;
