import styled from "styled-components";

import { media } from "constants/media";

const hiddenScrollBar = `
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;

export const StyledContentWrapper = styled.div<{
    maxWidth: number;
    minWidth: number;
}>`
    display: flex;
    flex-wrap: wrap;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    min-width: ${({ minWidth }) => `${minWidth}px`};
    width: 100%;

    ${media.mdUp} {
        flex-wrap: nowrap;
    }
`;

export const StyledContent = styled.div<{
    mobilePadding?: string;
    desktopPadding?: string;
    contentAlignement?: "left" | "center" | "right";
    rowGap?: number;
}>`
    align-items: center;
    background: var(--color-beige-40);
    color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-height: calc(100vh - 2 * 16px);
    overflow-y: scroll;
    padding: ${({ mobilePadding }) => mobilePadding || "16px"};
    row-gap: ${({ rowGap }) => rowGap}px;
    text-align: ${({ contentAlignement }) => contentAlignement};

    strong {
        text-align: left;
        width: 100%;
    }

    &.newsletter-modal {
        padding-top: 213px;
        position: relative;
    }

    ${media.mdUp} {
        flex: 1 1 50%;
        font-size: 18px;
        line-height: 32px;
        padding: ${({ desktopPadding }) => desktopPadding || "32px"};

        /* stylelint-disable-next-line */
        ${hiddenScrollBar}

        &.newsletter-modal {
            overflow-y: hidden;
            padding: 32px 225px 32px 32px;
            position: relative;
        }
    }

    ${media.lgUp} {
        &.newsletter-modal {
            padding-right: 315px;
        }
    }

    ${media.xlUp} {
        max-height: fit-content;
        overflow-y: visible;
    }
`;

export const StyledHeader = styled.h4<{
    headerAlignement?: "left" | "center" | "right";
}>`
    font-family: var(--font-tertiary);
    font-size: 32px;
    font-weight: normal;
    line-height: 40px;
    text-align: ${({ headerAlignement }) => headerAlignement};
    width: 100%;
`;

export const IconWrapper = styled.div<{ isMobileView: boolean }>`
    color: var(--color-white);
    position: absolute;
    right: 16px;
    top: 16px;

    ${media.mdUp} {
        right: 24px;
        top: 24px;
    }
`;
