import styled, { css } from "styled-components";

import { Button } from "components";

import { media } from "constants/media";

export const Description = styled.p`
    margin-bottom: 16px;

    ${media.mdUp} {
        margin-bottom: 24px;
    }
`;

export const Options = styled.ul<{ isVisible: boolean }>`
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--default-transition-duration) ease-in-out;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isVisible }) =>
        isVisible &&
        css`
            max-height: 200px;
            transition: max-height var(--default-transition-duration)
                ease-in-out;
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

export const Option = styled.li`
    margin-bottom: 16px;

    ${media.mdUp} {
        margin-bottom: 24px;
    }
`;

export const Link = styled.a`
    color: var(--color-brown-50);
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    ${media.mdUp} {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: 32px;
    }
`;

export const BottomContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-between;

    ${media.mdUp} {
        flex-direction: row;
        gap: 35px;
    }
`;

export const MoreInfoButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;

    ${media.mdUp} {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: 32px;
    }
`;

export const PreferencesButton = styled(Button)`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;

    ${media.mdUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const AcceptButton = styled.button<{ isShowingDetails: boolean }>`
    align-items: center;
    background-color: var(--color-brown-50);
    border: 0;
    border-radius: 70px;
    color: var(--color-beige-50);
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    font-family: var(--font-secondary);
    justify-content: center;
    padding: 16px;
    width: 240px;

    > svg {
        margin: 4px 0 0 9px;
    }

    > span {
        font-size: 20px;
        font-weight: normal;
        line-height: 32px;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isShowingDetails }) =>
        isShowingDetails &&
        css`
            flex: 0 1 0;
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;
