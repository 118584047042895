import styled, { css } from "styled-components";

import { Icon } from "components/shared/icon";

import { media } from "constants/media";

export const StyledSliderControls = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    justify-content: space-between;
`;

export const SlideIndicatorWrapper = styled.div`
    align-items: center;
    border-radius: 15px;
    display: flex;
    height: 4px;
    margin: 0 10px;
    position: relative;
    width: 100%;

    ${media.mdUp} {
        margin: 0 24px;
    }
`;

export const SlideIndicator = styled.div<{
    widthPercentage: number;
    color: string;
    isSelected?: boolean;
    spacingColor?: string;
}>`
    background-color: ${props => props.color};
    display: inline-block;
    height: 8px;
    width: ${props => props.widthPercentage}%;

    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isSelected, spacingColor }) =>
        isSelected &&
        css`
            margin: 4px;

            &:first-child {
                box-shadow: inset 20px 0px 0px 0px var(--color-beige-50),
                    inset 25px 0px 0px 0px ${spacingColor};
            }

            &:last-child {
                box-shadow: inset -20px 0px 0px 0px var(--color-beige-50),
                    inset -23px 0px 0px 0px ${spacingColor};
            }
        `};
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const ArrowsWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
`;

export const PreviousIcon = styled(Icon)`
    transform: rotate(180deg);
`;
