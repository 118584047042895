import { createGlobalStyle } from "styled-components";

import { media } from "constants/media";

export const GlobalStyle = createGlobalStyle`
    html {


        /* ===== Theme colors ====== */

        --color-primaryLight: #d02828;
        --color-primary: #c61717;
        --color-primaryDark: #b21515;

        --color-secondaryLight: #482714;
        --color-secondary: #3e2313;
        --color-secondaryDark: #381f0f;

        /* --color-errorLight:
        --color-error:
        --color-errorDark:

        --color-warningLight:
        --color-warning:
        --color-warningDark:

        --color-successLight:
        --color-success:
        --color-successDark:

        --color-dimmedLight:
        --color-dimmed:
        --color-dimmedDark: */

        --color-white: #fff;
        --color-black: #000;

        /* Support colors */

        --color-red-40: #dc2020;
        --color-red-40-transparent: rgba(220, 32, 32, 0.4);
        --color-red-45: #d02828;
        --color-red-50: #c61717;
        --color-red-55: #b21515;
        --color-red-60: #9e1212;

        --color-green-40: #2c603e;
        --color-green-45: #285036;
        --color-green-50: #23422e;
        --color-green-55: #1c3926;
        --color-green-60: #14311e;

        --color-orange-40: #f5893b;
        --color-orange-45: #e17426;
        --color-orange-50: #d56719;
        --color-orange-55: #ce6011;
        --color-orange-60: #c5570a;

        --color-blue-40: #59b4aa;
        --color-blue-45: #369e93;
        --color-blue-50: #338e84;
        --color-blue-55: #2e8077;
        --color-blue-60: #29726a;

        --color-brown-40: #562e16;
        --color-brown-45: #482714;
        --color-brown-50: #3e2313;
        --color-brown-55: #381f0f;
        --color-brown-60: #30190a;

        --color-beige-40: #f1dcce;
        --color-beige-45: #ecd4c4;
        --color-beige-50: #e5ccbb;
        --color-beige-55: #dcc2b0;
        --color-beige-60: #d2b5a1;

        --color-red-light: #f5e4e4;
        --color-green-light: #b2ffc6;

        --color-grey: #b2b2bf;
        --color-light-grey: #f8f8ff;
        --color-border-grey: #b3b3b3;

        --main-navigation-height: 104px;

        /* Footer */

        --footer-max-width: 1216px;

        /* Button */

        --type-button-font-size: 16px;
        --type-button-line-height: 24px;
        --type-button-font-size--small: 16px;
        --type-button-line-height--small: 32px;
        --type-button-font-size--large: 20px;
        --type-button-line-height--large: 32px;

        --button-primary-box-shadow: 0 8px 12px rgba(128, 0, 255, 0.17);
        --button-secondary-box-shadow: 0 8px 12px rgba(44, 226, 126, 0.19);

        /* Dropdown */

        --dropdown-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07),
            0 27.7413px 29.2013px rgba(34, 12, 74, 0.05),
            0 13.4679px 14.1767px rgba(34, 12, 74, 0.04),
            0 6.6022px 6.94968px rgba(34, 12, 74, 0.03),
            0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        --pill-box-shadow: 0 2px 31px rgba(34, 12, 74, 0.07),
            0 10.5083px 15.6991px rgba(34, 12, 74, 0.06151,)
            0 5.2525px 7.58201px rgba(34, 12, 74, 0.0528532),
            0 2.47219px 3.48928px rgba(34, 12, 74, 0.0430861),
            0 0.886661px 1.24172px rgba(34, 12, 74, 0.0298631);

        /* TextButton */

        --type-anchor-font-size: 13px;
        --type-anchor-line-height: 24px;

        --button-color-disabled: #b2b2bf;

        /* Static page menu */
        --color-menu-item-hover: rgba(128, 0, 255, 0.05);

        /* Milestone */
        --color-milestone-dot: rgba(44, 226, 126, 0.1);

        /* Transition duration variable */
        --quick-transition-duration: 150ms;
        --default-transition-duration: 250ms;

        /* Typography */

        --font-primary: "Space Grotesk", sans-serif;
        --font-secondary: "Bely Display", sans-serif;
        --font-tertiary: "Righteous", sans-serif;

        --type-heading-1-font-size: 38px;
        --type-heading-1-line-height: 40px;
        --type-heading-2-font-size: 34px;
        --type-heading-2-line-height: 48px;
        --type-heading-3-font-size: 32px;
        --type-heading-3-line-height: 40px;
        --type-heading-4-font-size: 26px;
        --type-heading-4-line-height: 40px;
        --type-heading-5-font-size: 24px;
        --type-heading-5-line-height: 32px;
        --type-heading-6-font-size: 20px;
        --type-heading-6-line-height: 32px;

        --type-paragraph-font-size: 16px;
        --type-paragraph-line-height: 24px;

        --type-span-font-size: 14px;
        --type-span-line-height: 24px;

        --type-label-font-size: 14px;
        --type-label-line-height: 24px;

        --type-sub-text-font-size-sub-text: 14px;
        --type-sub-text-line-height: 24px;

        --font-weight-normal: 400;
        --font-weight-medium: 500;
        --font-weight-bold: 700;


        /* Range input */

        --range-input-label-font: ClanPro-Wide, serif;
        --range-input-value-counter-font: Alterra Display, serif;

        /* Whitepaper */

        --whitepaper-image-drop-shadow: drop-shadow(0 22px 55px rgba(0, 0, 0, 0.27)) drop-shadow(0 6.63236px 16.5809px rgba(0, 0, 0, 0.175929)) drop-shadow(0 2.75474px 6.88685px rgba(0, 0, 0, 0.135)) drop-shadow(0 0.996336px 2.49084px rgba(0, 0, 0, 0.094071));
        --whitepaper-download-block-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07), 0 27.7413px 29.2013px rgba(34, 12, 74, 0.05), 0 13.4679px 14.1767px rgba(34, 12, 74, 0.04), 0 6.6022px 6.94968px rgba(34, 12, 74, 0.03), 0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        /* Modal Overlay */
        --overlay-color: rgba(62, 35, 19, 0.5);

        /* Smooth scroll behavior for scrolling to ID'ed elements on static pages */

        scroll-behavior: smooth;

        ${media.mdUp} {
            --type-heading-1-font-size: 80px;
            --type-heading-1-line-height: 88px;
            --type-heading-2-font-size: 56px;
            --type-heading-2-line-height: 64px;
            --type-heading-3-font-size: 48px;
            --type-heading-3-line-height: 64px;
            --type-heading-4-font-size: 40px;
            --type-heading-4-line-height: 56px;
            --type-heading-5-font-size: 32px;
            --type-heading-5-line-height: 48px;
            --type-heading-6-font-size: 24px;
            --type-heading-6-line-height: 40px;

            --type-paragraph-font-size: 18px;
            --type-paragraph-line-height: 32px;

            --type-span-font-size: 16px;
            --type-span-line-height: 32px;

            --type-label-font-size: 16px;
            --type-label-line-height: 32px;

            --type-sub-text-font-size-sub-text: 16px;
            --type-sub-text-line-height: 32px;

            --type-anchor-font-size-: 15px;
            --type-anchor-line-height-: 32px;

            --type-button-font-size: 18px;
            --type-button-line-height: 32px;
        }
    }

    * {
        box-sizing: border-box;
    }

    body {
        background-color: var(--color-beige-40);
    }

    /* Headers */

    h1 {
        font-size: var(--type-heading-1-font-size);
        line-height: var(--type-heading-1-line-height);
    }

    h2 {
        font-size: var(--type-heading-2-font-size);
        line-height: var(--type-heading-2-line-height);
    }

    h3 {
        font-size: var(--type-heading-3-font-size);
        line-height: var(--type-heading-3-line-height);
    }

    h4 {
        font-size: var(--type-heading-4-font-size);
        line-height: var(--type-heading-4-line-height);
    }

    h5 {
        font-size: var(--type-heading-5-font-size);
        line-height: var(--type-heading-5-line-height);
    }

    h6 {
        font-size: var(--type-heading-6-font-size);
        line-height: var(--type-heading-6-line-height);

    }

    /* General components */

    p {
        font-size: var(--type-paragraph-font-size);
        line-height: var(--type-paragraph-line-height);
    }

    span {
        font-size: var(--type-span-font-size);
        line-height: var(--type-span-line-height);
    }

    a {
        cursor: pointer;
        text-decoration: none;
    }

    button {
        font-size: var(--type-button-font-size);
        line-height: var(--type-button-line-height);
    }

    label {
        font-size: var(--type-label-font-size);
        line-height: var(--type-label-line-height);
    }

    /*  General styling */

    html,
    body,
    button,
    input,
    select,
    textarea {
        font-family: var(--font-primary), sans-serif;
    }

    input,
    textarea {
        font-size: var(--type-paragraph-font-size);
        line-height: var(--type-paragraph-line-height);
    }

    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin: 0;
        padding: 0;
    }

    /* stylelint-disable selector-max-id */
    #__next {
        overflow: hidden;
    }

    /* stylelint-enable selector-max-id */

    .react-modal {
        background: transparent;
        border: 3px solid var(--color-brown-50);
        left: 50%;
        max-height: 100%;
        opacity: 0;
        outline: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.4s opacity ease-in-out;
        z-index: 14;

        svg g {
            fill: var(--color-secondary);
        }

        &-overlay {
            background: var(--overlay-color);
            bottom: 0;
            left: 0;
            overflow: auto;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
        }

    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }

    .clipped-image {
        clip-path: url("#myClip");
        overflow: hidden;
    }

`;
