import { Richtext, SbBlokData, storyblokEditable } from "@storyblok/js";
import { render } from "storyblok-rich-text-react-renderer";

import { ACCORDION_THEME } from "components/shared/accordion/Accordion";
import { Accordion } from "components/shared/accordion";

import { FaqCategory } from "../Faq.styled";

import { useFaq } from "helpers/context/FaqContext";

type FaqItemBlok = SbBlokData & {
    title: string;
    category: string;
    description: Richtext;
};

function FaqItem({ blok }: { blok: FaqItemBlok }): JSX.Element {
    const { isOpen, setIsOpen } = useFaq();
    const id = `#${blok.title.toLowerCase().replace(/\s+/g, "-")}`;

    return (
        <div {...storyblokEditable(blok)}>
            <Accordion
                onClick={() => {
                    if (isOpen === id) {
                        setIsOpen(null);
                    } else {
                        setIsOpen(id);
                    }
                }}
                isOpen={isOpen === id}
                key={JSON.stringify(blok)}
                rightElement={<FaqCategory>{blok.category}</FaqCategory>}
                title={
                    <span>
                        <strong>{blok.title}</strong>
                    </span>
                }
                information={render(blok.description)}
                theme={ACCORDION_THEME.BEIGE40}
                id={id}
            />
        </div>
    );
}

export default FaqItem;
