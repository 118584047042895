import NextLink from "next/link";

import { Icon, IconProps } from "components/shared/icon/Icon";
import { LinkStyled, Underline } from "components/shared/link/Link.styled";

type LinkProps = {
    ariaLabel?: string | "";
    children: JSX.Element | string;
    className?: string;
    disabled?: boolean;
    href: string;
    icon?: IconProps;
    noUnderline?: boolean;
    thinUnderline?: boolean;
    passHref?: boolean;
    prefetch?: boolean;
    rel?: string;
    reversedOrder?: boolean;
    size?: "large";
    target?: string;
    variant?: "blue" | "orange" | "brown" | "beige" | "red";
};

export function Link({
    ariaLabel,
    children,
    className = "",
    disabled,
    href,
    icon,
    noUnderline = false,
    thinUnderline = false,
    passHref = true,
    prefetch = false,
    reversedOrder = false,
    size,
    variant = "blue"
}: LinkProps): JSX.Element {
    return (
        <NextLink href={href} passHref={passHref} prefetch={prefetch}>
            <LinkStyled
                aria-label={ariaLabel ?? `${variant}-link`}
                className={className}
                disabled={disabled}
                href={href}
                reversedOrder={reversedOrder}
                size={size}
                variant={variant}
            >
                {icon && <Icon {...icon} />}
                {children}
                {!noUnderline && (
                    <Underline
                        preserveAspectRatio="none"
                        fill="currentColor"
                        $isSimple
                        isThin={thinUnderline}
                    />
                )}
            </LinkStyled>
        </NextLink>
    );
}
