export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export enum EVENTS {
    PAGE_VIEW = "pageView",
    PRODUCT_CLICK = "productClick",
    ADD_TO_CART = "addToCart",
    REMOVE_FROM_CART = "removeFromCart",
    CHECKOUT = "checkout",
    CHECKOUT_OPTION = "checkoutOption",
    SELECT_ITEM = "selectItem",
    REMOVE_ITEM_SELECTION = "removeItemSelection"
}

export * from "./events";
