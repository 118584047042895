import { gql } from "@apollo/client";

export const PlaceCompleteCustomerOrder = gql`
    mutation PlaceCompleteCustomerOrder(
        $cartId: String!
        $paymentMethodInput: PaymentMethodInput!
        $shippingMethods: [ShippingMethodInput]!
        $billingAddressId: Int!
        $shippingAddressId: Int!
    ) {
        setShippingAddressesOnCart(
            input: {
                cart_id: $cartId
                shipping_addresses: [
                    { customer_address_id: $shippingAddressId }
                ]
            }
        ) {
            cart {
                id
            }
        }
        setBillingAddressOnCart(
            input: {
                cart_id: $cartId
                billing_address: { customer_address_id: $billingAddressId }
            }
        ) {
            cart {
                id
            }
        }
        setPaymentMethodOnCart(
            input: { cart_id: $cartId, payment_method: $paymentMethodInput }
        ) {
            cart {
                id
            }
        }
        setShippingMethodsOnCart(
            input: { cart_id: $cartId, shipping_methods: $shippingMethods }
        ) {
            cart {
                id
            }
        }
        placeOrder(input: { cart_id: $cartId }) {
            order {
                order_number
                mollie_payment_token
                mollie_redirect_url
            }
        }
    }
`;
