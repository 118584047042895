function ClipSvg(): JSX.Element {
    return (
        <svg width="0" height="0">
            <defs>
                <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                    <path
                        d="M 0.5 1 C 0.800781 1 1 0.808594 1 0.5 C 1 0.191406 0.800781 0 0.5 0 C 0.199219 0 0 0.191406 0 0.5 C 0 0.808594 0.199219 1 0.5 1 Z M 0.5 1 "
                        fill="#000"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ClipSvg;
