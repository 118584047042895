import dynamic from "next/dynamic";

import Faq from "components/faq/Faq";
import FaqItem from "components/faq/faqItem/FaqItem";
import HomePageHero from "components/storyblok/homePageHero/HomePageHero";
import Page from "components/storyblok/page/Page";
import RecipeCard from "components/shared/recipeCard/recipeCard";
import TrustPilotCarousel from "components/trustPilotWidgets/carousel/TrustPilotCarousel";

const SocialWall = dynamic(
    () => import("components/storyblok/socialWall/SocialWall"),
    { ssr: false }
);
const StoresList = dynamic(
    () => import("components/shared/storesList/storesList"),
    { ssr: false }
);
const Usps = dynamic(() => import("components/storyblok/usps/Usps"), {
    ssr: false
});

export default {
    faqItem: FaqItem,
    faqItems: Faq,
    homePageHero: HomePageHero,
    page: Page,
    recipeCard: RecipeCard,
    socialWall: SocialWall,
    storesList: StoresList,
    trustPilotCarousel: TrustPilotCarousel,
    usps: Usps
};
