import styled, { css } from "styled-components";

import { media } from "constants/media";

export const DropDownWrapper = styled.div<{
    ref: HTMLDivElement;
    wrapperWidth?: string;
    dropdownIsOpen: boolean;
}>`
    display: inline-block;
    ${({ dropdownIsOpen }) =>
        dropdownIsOpen &&
        css`
            z-index: 2;
        `};

    position: relative;
    width: ${({ wrapperWidth }) => wrapperWidth};
`;

export const StyledLabel = styled.label`
    color: var(--color-brown-50);
    display: block;
    font-size: var(--type-span-font-size);
    font-weight: 500;
    height: 28px;
    line-height: var(--type-span-line-height);
`;

export const DropdownButton = styled.button<{
    hasError: boolean;
    isDisabled?: boolean;
    hasValue: boolean;
    dropdownIsOpen: boolean;
}>`
    align-items: center;
    background-color: var(--color-white);
    border: 2px solid var(--color-beige-40);
    box-sizing: border-box;
    color: var(--color-beige-60);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 64px;
    padding: 0 24px;
    transition: var(--default-transition-duration) border-color ease-in-out;
    width: 100%;

    ${media.mdUp} {
        height: 64px;
    }

    > span {
        line-break: normal;
        white-space: normal;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ dropdownIsOpen }) =>
        dropdownIsOpen &&
        css`
            border-color: var(--color-red-50);
        `};

    ${({ hasValue }) =>
        hasValue &&
        css`
            background-color: var(--color-white);
            color: var(--color-brown-50);
        `};

    ${({ hasError }) =>
        hasError &&
        css`
            background-color: var(--color-red-light);
            border-color: var(--color-red-50);
        `};

    ${({ isDisabled }) =>
        isDisabled
            ? css`
                  background-color: var(--color-white);
                  border-color: var(--color-beige-40);
                  color: var(--color-beige-60);
                  cursor: not-allowed;
              `
            : css`
                  &:hover {
                      border-color: var(--color-brown-50);
                  }
              `}; /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const SelectedLabel = styled.span<{
    isDefault: boolean;
    hasError: boolean;
}>`
    color: ${props =>
        props.isDefault ? "var(--color-beige-60)" : "var(--color-brown-50)"};
    font-size: 16px;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ hasError }) =>
        hasError &&
        css`
            color: var(--color-primary);
        `}; /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const SelectedIcon = styled.div<{ isFlipped: boolean }>`
    align-items: center;
    display: flex;
    margin-left: 8px;
    transform: ${props => (props.isFlipped ? "rotate(180deg)" : "none")};
    transform-origin: center;
    transition: 150ms transform linear;
`;

export const DropdownWrapper = styled.div<{ isOpen: boolean }>`
    background-color: var(--color-white);
    border: 2px solid transparent;
    left: 0;
    max-height: 256px;
    padding: 0;
    position: absolute;
    top: 62px; /* label + button height = 76px */
    transform: scaleY(0);
    transform-origin: top;
    transition: 100ms transform linear;
    width: 100%;
    z-index: 1;

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            border-color: var(--color-brown-50);
            box-shadow: var(--dropdown-box-shadow);
            top: 82px; /* label + button height + border = 96px */
            transform: scaleY(1);
        `}; /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const StyledDropdown = styled.div`
    max-height: 240px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: var(--color-light-grey);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color-brown-50);
        border-radius: 4px;
    }
`;

export const StyledOption = styled.button<{ isSelected: boolean }>`
    align-items: baseline;
    background-color: transparent;
    border: 0;
    color: ${props =>
        props.isSelected ? "var(--color-orange-50)" : "var(--color-brown-50)"};
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    gap: 8px;
    justify-content: flex-start;
    line-break: auto;
    line-height: 24px;
    outline: none;
    overflow: hidden;
    padding: 8px 24px;
    text-align: left;
    text-overflow: ellipsis;
    transition-duration: var(--default-transition-duration);
    transition-property: color, background-color;
    transition-timing-function: ease-in-out;
    user-select: none;
    white-space: normal;
    width: 100%;

    &:hover {
        color: var(--color-orange-50);
    }

    ${media.mdUp} {
        height: 48px;
        line-break: initial;
        white-space: nowrap;
    }
`;

export const ErrorMessage = styled.div`
    color: var(--color-red-50);
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    height: 24px;
    line-height: 24px;
    margin-top: 4px;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
