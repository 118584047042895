import styled from "styled-components";

export const StyledToggleCheckbox = styled.div`
    /* stylelint-disable order/properties-alphabetical-order */
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    /* stylelint-enable order/properties-alphabetical-order */
    color: inherit;
    display: flex;
    height: 34px;
`;

export const ButtonLabel = styled.label<{ disabled?: boolean }>`
    color: inherit;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    outline: none;
`;

export const Label = styled.div<{ disabled?: boolean }>`
    align-items: center;
    color: ${props => (props.disabled ? "var(--color-grey)" : "inherit")};
    display: flex;
    font-size: var(--type-paragraph-font-size);
    line-height: var(--type-paragraph-line-height);
`;

export const Switch = styled.div<{ isActive: boolean }>`
    background-color: ${props =>
        props.isActive ? "var(--color-primary)" : "var(--color-grey)"};
    border-radius: 100%;
    height: 26px;
    margin-left: ${props => (props.isActive ? "26px" : 0)};
    position: absolute;
    top: -1px;
    transition: margin var(--default-transition-duration) ease,
        background-color var(--default-transition-duration) ease;
    width: 26px;
`;

export const SwitchWrapper = styled.div`
    background-color: var(--color-light-grey);
    border-radius: 25px;
    height: 24px;
    position: relative;
    width: 52px;
`;

export const CheckboxInput = styled.input`
    height: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    width: 0;
`;
