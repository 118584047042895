import { createContext, useContext, useState } from "react";

export enum ACTION {
    DELETE = "delete",
    EDIT = "edit"
}
interface CartItemContext {
    selectedItem: BundledCartItem | null | undefined;
    setSelectedItem: (selectedItem: BundledCartItem | null | undefined) => void;
}

const CartItemContext = createContext<CartItemContext | undefined>(undefined);

export function useCartItemContext(): CartItemContext {
    const context = useContext(CartItemContext);

    if (!context) {
        throw new Error(
            "CartItemContext should be used withinCartItemProivder"
        );
    }

    return context;
}

export function CartItemProvider({
    children
}: {
    children: JSX.Element | Array<JSX.Element>;
}): JSX.Element {
    const [selectedItem, setSelectedItem] = useState<
        BundledCartItem | null | undefined
    >(null);

    return (
        <CartItemContext.Provider
            value={{
                selectedItem,
                setSelectedItem
            }}
        >
            {children}
        </CartItemContext.Provider>
    );
}
