import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import styled, { css } from "styled-components";

import { media } from "constants/media";

type TrustBoxProps = { view?: "mobile" | "desktop" };

const Container = styled.div<TrustBoxProps>`
    position: relative;
    text-align: left;
    width: 100%;
    /* stylelint-disable order/properties-alphabetical-order */

    ${({ view }) =>
        view === "desktop" &&
        css`
            display: none;
        `}
    ${({ view }) =>
        view === "mobile" &&
        css`
            ${media.lgUp} {
                display: none;
            }
        `}

    ${media.lgUp} {
        margin-left: -105px;
        width: 400px;

        ${({ view }) =>
            view === "desktop" &&
            css`
                display: block;
            `}
    }

    ${media.xlUp} {
        margin-left: -21px;
        width: 462px;
    }

    /* stylelint-enable order/properties-alphabetical-order */
`;

function TrustBox({ view }: TrustBoxProps): JSX.Element {
    const router = useRouter();
    const urlPath = router.asPath;

    const lightOrDark =
        urlPath.includes("kip-bestellen") || urlPath.includes("recepten/")
            ? "dark"
            : "light";

    // We need to add this useEffect so we force the component to re-render.
    const ref = useRef(null);
    useEffect(() => {
        window?.Trustpilot?.loadFromElement(ref.current, true);
    }, []);

    return (
        <>
            <Script
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                strategy="afterInteractive"
            />
            <Container view={view}>
                <div
                    ref={ref}
                    className="trustpilot-widget"
                    data-locale="nl-NL"
                    data-template-id="5419b6ffb0d04a076446a9af"
                    data-businessunit-id="64809bfcdc5cb52419b44813"
                    data-style-height="20px"
                    data-style-width="100%"
                    data-theme={lightOrDark}
                />
            </Container>
        </>
    );
}

export default TrustBox;
