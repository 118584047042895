import { SvgProps } from "types/input";

export function DeliveryTruckIcon({
    width = 64,
    height = 64
}: SvgProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 64 64"
            width={width}
            height={height}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#E5CCBB"
                    d="M0 32C0 12.204 12.665 0 32 0s32 12.204 32 32-12.675 32-32 32S0 51.778 0 32Z"
                />
                <mask
                    id="b"
                    width="64"
                    height="64"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: "alpha" }}
                >
                    <path
                        fill="#EAE5E5"
                        d="M0 32C0 12.204 12.665 0 32 0s32 12.204 32 32-12.675 32-32 32S0 51.778 0 32Z"
                    />
                </mask>
                <g mask="url(#b)">
                    <path
                        fill="#fff"
                        d="M39.554 15.272H0v32.16h39.554v-32.16Z"
                    />
                    <path
                        fill="#3E2313"
                        d="M56.99 38.08H39.555v9.351h17.437V38.08Z"
                    />
                    <path
                        fill="#E5CCBB"
                        d="M39.573 54.75a5.532 5.532 0 1 0 0-11.063 5.532 5.532 0 0 0 0 11.063ZM11.905 54.75a5.532 5.532 0 1 0 0-11.063 5.532 5.532 0 0 0 0 11.063Z"
                    />
                    <path
                        fill="#3E2313"
                        d="m40.528 22.95 14.184 14.184H40.528V22.95Zm-.946-2.288v17.427H57L39.582 20.662Z"
                    />
                    <path
                        fill="#338E84"
                        d="M32.415 22.487H8.302V36.84h24.113V22.487Z"
                    />
                    <path
                        fill="#3E2313"
                        d="M39.516 53.436a3.877 3.877 0 1 0 0-7.754 3.877 3.877 0 0 0 0 7.754ZM11.83 53.218a3.877 3.877 0 1 0 0-7.754 3.877 3.877 0 0 0 0 7.754Z"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h64v64H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
