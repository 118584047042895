import { SvgProps } from "types/input";

export function ArrowRight({
    width = 24,
    height = 24,
    ...rest
}: SvgProps): JSX.Element {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            {...rest}
        >
            <path d="M1 12h22" strokeWidth="1.5" />
            <path d="m15 9 8 3-8 3" strokeWidth="1.5" strokeLinejoin="bevel" />
        </svg>
    );
}
