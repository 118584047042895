import dynamic from "next/dynamic";

import { Header } from "components/header";
import { PreHeader } from "components/preHeader/PreHeader";

const Footer = dynamic(() => import("components/footer/Footer"));
const PackageCart = dynamic(() => import("components/packageCart/PackageCart"));

export enum headerThemeOptions {
    light = "light",
    dark = "dark",
    recipe = "recipe"
}

type LayoutProps = {
    children?: JSX.Element | Array<JSX.Element>;
    headerTheme?:
        | headerThemeOptions.light
        | headerThemeOptions.dark
        | headerThemeOptions.recipe;
    showTagline?: boolean;
    taglineIsSpinning?: boolean;
};

function Layout({
    children,
    headerTheme = headerThemeOptions.light,
    showTagline = true,
    taglineIsSpinning = true
}: LayoutProps): JSX.Element | null {
    return (
        <>
            <PreHeader
                showTagline={showTagline}
                taglineIsSpinning={taglineIsSpinning}
                theme={headerTheme}
            />
            <Header theme={headerTheme} />
            {children}
            <Footer />
            <PackageCart />
        </>
    );
}

export default Layout;
