import { CHECK, ERROR, Icon, WARN } from "components/shared/icon";
import { CLOSE } from "components";

import {
    CloseButton,
    Container,
    IconContainer
} from "./FeedbackMessage.styled";

import { FEEDBACK_MESSAGE_TYPES } from "constants/enum";

function selectMessageIcon(
    messageType?:
        | FEEDBACK_MESSAGE_TYPES.ERROR
        | FEEDBACK_MESSAGE_TYPES.SUCCESS
        | FEEDBACK_MESSAGE_TYPES.WARNING
) {
    switch (messageType) {
        case FEEDBACK_MESSAGE_TYPES.ERROR:
            return ERROR;
        case FEEDBACK_MESSAGE_TYPES.SUCCESS:
            return CHECK;
        case FEEDBACK_MESSAGE_TYPES.WARNING:
            return WARN;
        default:
            return ERROR;
    }
}

export function FeedbackMessage({
    children,
    className,
    size,
    type,
    strokeColor = "var(--color-beige-40)",
    withCloseButton = false,
    onClose = () => false,
    order = 0
}: {
    children:
        | string
        | Array<string>
        | React.ReactElement
        | Array<React.ReactElement>;
    className?: string;
    size?: "small" | "default" | "fullWidth";
    strokeColor?: string;
    order?: number;
    type?:
        | FEEDBACK_MESSAGE_TYPES.ERROR
        | FEEDBACK_MESSAGE_TYPES.SUCCESS
        | FEEDBACK_MESSAGE_TYPES.WARNING;
    withCloseButton?: boolean;
    onClose?: () => void;
}): JSX.Element {
    return (
        <Container
            className={className}
            size={size}
            type={type}
            withCloseButton={withCloseButton}
            $order={order}
        >
            <IconContainer strokeColor={strokeColor}>
                <Icon icon={selectMessageIcon(type)} size={24} />
            </IconContainer>
            {children}
            {withCloseButton && (
                <CloseButton
                    type="button"
                    buttonType="transparent"
                    onClick={onClose}
                >
                    <Icon stroke="var(--color-beige-40)" icon={CLOSE} />
                </CloseButton>
            )}
        </Container>
    );
}

export default FeedbackMessage;
