import { useEffect } from "react";

export function useBodyScrollLock(shouldBodyScrollBeLocked: boolean): void {
    useEffect(() => {
        return () => {
            document.body.style.overflow = "hidden auto";
        };
    }, []); // Empty array ensures effect is only run on mount and unmount

    useEffect(() => {
        if (shouldBodyScrollBeLocked === true) {
            document.body.style.overflow = "hidden hidden";
        } else if (shouldBodyScrollBeLocked === false) {
            document.body.style.overflow = "hidden auto";
            document.body.classList.remove("ReactModal__Body--open");
        }
    }, [shouldBodyScrollBeLocked]);
}
