import { Dispatch, SetStateAction, useEffect, useState } from "react";

function getSessionStorageOrDefault<T>(
    key: string,
    defaultValue: T | undefined
) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

export function useSessionStorage<T>(
    key: string,
    defaultValue: T
): [value: T, setValue: Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState<T>(defaultValue);

    useEffect(() => {
        if (value) {
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            setValue(getSessionStorageOrDefault<T>(key, defaultValue));
        }
    }, [key, value, defaultValue]);

    return [value, setValue];
}
