/* eslint-disable no-console */
export function debugLog(message: string, response?: unknown): void {
    console.group(`%c███ ${message}`, "color: rgb(50, 168, 82);");
    response && console.log("Response: ", { response });
    console.groupEnd();
}

export function debugError(message: string, response?: unknown): void {
    console.group(`%c███ ${message}`, "color: rgb(176, 37, 39);");
    console.error(response);
    console.groupEnd();
}
