import styled, { css } from "styled-components";

import BasePackageItem from "components/shared/packageItem/PackageItem";

import { media } from "constants/media";

export const Container = styled.div`
    margin: 42px auto 0;
    padding: 0 0 0 24px;
`;

export const PackageItem = styled(BasePackageItem)<{ hasDarkTheme?: boolean }>`
    color: ${({ hasDarkTheme }) =>
        hasDarkTheme ? "var(--color-beige-50)" : "var(--color-brown-50)"};
    margin-right: 28px;

    ${media.mdUp} {
        margin-bottom: ${({ hasDarkTheme }) => (hasDarkTheme ? "64px" : 0)};
        margin-right: 0;
    }
`;

export const List = styled.ul`
    list-style: none;
    margin: 61px auto 0;
    max-width: 1200px;
    padding: 0;
    text-align: center;

    ${media.mdUp} {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(3, 1fr);
        width: 90%;
    }

    ${media.xlUp} {
        gap: 48px;
        width: 100%;
    }
`;

export const ListItem = styled.li<{
    desktopVariant: "default" | "simpleGrid";
}>`
    ${({ desktopVariant }) =>
        desktopVariant === "default" &&
        css`
            ${media.mdUp} {
                &:not(:last-child):nth-of-type(even) {
                    margin-top: 112px;
                }
            }
        `};
`;

export const ControlsContainer = styled.div`
    margin: 50px auto 0;
    width: 85%;
`;
