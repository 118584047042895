import { SvgProps } from "types/input";

export function FacebookIcon({
    width = "64",
    height = "64"
}: SvgProps): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>instagram icoon</title>
            <g clipPath="url(#clip0_29_3271)">
                <path
                    d="M0 32C0 12.2037 12.6653 0 32 0C51.3347 0 64 12.2037 64 32C64 51.7963 51.3253 64 32 64C12.6746 64 0 51.7778 0 32Z"
                    fill="#E5CCBB"
                />
                <rect x="13" y="13" width="37" height="19" fill="#338E84" />
                <rect x="13" y="32" width="37" height="18" fill="#3E2313" />
                <path
                    d="M40 29.2222L39.1111 34.7778H34.6667V48.8889H28V34.7778H23V29.2222H28V24.7778C28 19.7778 31.2222 17 35.7778 17C37.2222 17 38.7778 17.2222 40.2222 17.4444V22.5556H37.6667C35.2222 22.5556 34.6667 23.7778 34.6667 25.3333V29.2222H40Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_29_3271">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
