import styled from "styled-components";

import { media } from "constants/media";

export const StyledCheckbox = styled.div`
    /* stylelint-disable order/properties-alphabetical-order */
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    /* stylelint-enable order/properties-alphabetical-order */
    color: inherit;
    display: flex;
    height: 34px;
`;

export const ButtonLabel = styled.label<{ disabled?: boolean }>`
    align-items: center;
    color: inherit;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    outline: none;
`;

export const Label = styled.div<{
    disabled?: boolean;
    withThinLabel?: boolean;
}>`
    align-items: center;
    color: ${props => (props.disabled ? "var(--color-beige-50)" : "inherit")};
    display: flex;
    font-size: 16px;
    font-weight: ${({ withThinLabel }) => (withThinLabel ? 400 : 500)};
    line-height: 24px;

    ${media.mdUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const Button = styled.div<{ isActive: boolean; disabled?: boolean }>`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    left: 2px;
    opacity: ${props => (props.isActive ? 1 : 0)};
    position: absolute;
    top: 2px;
    transform: ${props => (props.isActive ? "scale(1)" : "scale(0)")};
    transition: opacity var(--default-transition-duration) ease,
        background-color var(--default-transition-duration) ease,
        transform var(--default-transition-duration) ease;
    width: 16px;

    svg {
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;

export const CheckedButton = styled(Button)`
    background-color: ${props =>
        props.disabled ? "var(--color-beige-60)" : "var(--color-green-50)"};
`;

export const SwitchWrapper = styled.div<{ disabled?: boolean }>`
    background-color: transparent;
    border: 2px solid
        ${props =>
            props.disabled ? "var(--color-beige-60)" : "var(--color-green-50)"};
    height: 24px;
    position: relative;
    width: 24px;
`;

export const CheckboxInput = styled.input`
    height: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    width: 0;
`;
