import { gql } from "@apollo/client";

import { CART_CONTENT_FRAGMENT } from "../fragments/cartContent";

export const ApplyCouponToCart = gql`
    ${CART_CONTENT_FRAGMENT}

    mutation applyCouponToCart($input: ApplyCouponToCartInput!) {
        applyCouponToCart(input: $input) {
            cart {
                ...CartContent
            }
        }
    }
`;
