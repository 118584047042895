import { Children, ReactNode, forwardRef } from "react";
import Link from "next/link";

import { IconProps } from "components/shared/icon/Icon";
import {
    LinkContent,
    StyledButton,
    StyledIcon,
    StyledIconWrapper
} from "components/shared/button/Button.styled";
import { Underline } from "components/shared/link/Link.styled";

import { ButtonVariant } from "../buttonElement/ButtonElement";

export type ButtonProps = {
    size?: "small" | "large" | "round" | "link" | "simple";
    buttonType?: ButtonVariant;
    variant?: "blue" | "orange" | "brown";
    disabled?: boolean;
    onClick?: () => void;
    href?: string;
    children?: ReactNode;
    reversedOrder?: boolean;
    className?: string;
    underline?: boolean;
    icon?: IconProps;
    $showUnderlineOnMobile?: boolean;
};

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
    ({
        className,
        children,
        href,
        disabled = false,
        onClick,
        buttonType = "primary",
        reversedOrder = false,
        size = "large",
        variant = "blue",
        underline,
        icon,
        $showUnderlineOnMobile
    }: ButtonProps): JSX.Element => {
        if (href) {
            return (
                <Link href={href}>
                    <LinkContent
                        className={className}
                        onClick={onClick}
                        buttonType={buttonType}
                        disabled={disabled}
                        reversedOrder={reversedOrder}
                        size={size}
                        variant={variant}
                    >
                        {!!icon?.icon && (
                            <StyledIconWrapper
                                hasChildren={!!Children.count(children)}
                                reversedOrder={reversedOrder}
                            >
                                <StyledIcon {...icon} />
                            </StyledIconWrapper>
                        )}
                        {children}
                        {(underline || $showUnderlineOnMobile) && (
                            <Underline
                                $isSimple={size === "simple"}
                                fill="currentColor"
                                width={265}
                                preserveAspectRatio="none"
                                $showUnderlineOnMobile={$showUnderlineOnMobile}
                            />
                        )}
                    </LinkContent>
                </Link>
            );
        } else {
            return (
                <StyledButton
                    className={className}
                    onClick={onClick}
                    aria-label={buttonType + size}
                >
                    <LinkContent
                        buttonType={buttonType}
                        disabled={disabled}
                        reversedOrder={reversedOrder}
                        size={size}
                        variant={variant}
                    >
                        {!!icon?.icon && (
                            <StyledIconWrapper
                                hasChildren={!!Children.count(children)}
                                reversedOrder={reversedOrder}
                            >
                                <StyledIcon {...icon} />
                            </StyledIconWrapper>
                        )}
                        {children}
                        {(underline || $showUnderlineOnMobile) && (
                            <Underline
                                $isSimple={size === "simple"}
                                fill="currentColor"
                                width={265}
                                preserveAspectRatio="none"
                                $showUnderlineOnMobile={$showUnderlineOnMobile}
                            />
                        )}
                    </LinkContent>
                </StyledButton>
            );
        }
    }
);
