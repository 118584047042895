import {
    Container,
    Icon,
    Label,
    LinkItem
} from "components/header/menu/linkGroup/LinkGroup.styled";
import { Link } from "components/shared/link/Link";
import { MenuLinkLabel } from "components/header/menu/Menu";
import { headerThemeOptions } from "components/layout/Layout";
import ShoppingBasketLink from "components/header/shoppingBasketLink/ShoppingBasketLink";
import StoreSwitcher from "components/storeSwitcher/StoreSwitcher";

import { useMediaQueryContext } from "helpers/hooks";

export enum headerAlignmentTypes {
    left = "left",
    right = "right",
    center = "center",
    top = "top"
}

export interface LinkGroupItem {
    label: MenuLinkLabel;
    url: string;
    isCartLink?: boolean;
    ariaLabel?: string;
    isExternal?: boolean;
}

export type HeaderTypes = "primary" | "secondary" | "recipe" | "default";

export type HeaderThemeOptions =
    | headerThemeOptions.light
    | headerThemeOptions.dark
    | headerThemeOptions.recipe;

export type HeaderAlignmentTypes =
    | headerAlignmentTypes.left
    | headerAlignmentTypes.right
    | headerAlignmentTypes.center
    | headerAlignmentTypes.top;
interface LinkGroupProps {
    align?: HeaderAlignmentTypes;
    alignLeft?: boolean;
    alignRight?: boolean;
    items?: Array<LinkGroupItem>;
    type?: HeaderTypes;
    theme?: HeaderThemeOptions;
    isSticky: boolean;
    children?: React.ReactNode;
    showStoreSwitcher?: boolean;
}

export default function LinkGroup({
    items,
    type = "default",
    theme,
    align = headerAlignmentTypes.center,
    isSticky,
    showStoreSwitcher = false
}: LinkGroupProps): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();

    return (
        <Container align={align}>
            {items?.map((item, index) => {
                return (
                    <LinkItem
                        key={`Group-link-${item.label}-${index}`}
                        type={type}
                        theme={theme}
                        isSticky={isSticky}
                    >
                        {item?.isCartLink ? (
                            <ShoppingBasketLink />
                        ) : item.isExternal ? (
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.label.icon ? (
                                    <>
                                        <Icon
                                            size={24}
                                            icon={item.label.icon}
                                            stroke={
                                                item.label.iconColors?.stroke
                                            }
                                            fill={
                                                item.label.iconColors?.fill ||
                                                "transparent"
                                            }
                                        />
                                        {item.label.text && (
                                            <Label withIcon>
                                                {item.label.text}
                                            </Label>
                                        )}
                                    </>
                                ) : (
                                    item?.label?.text
                                )}
                            </a>
                        ) : (
                            <Link
                                href={item.url}
                                ariaLabel={item.ariaLabel}
                                passHref
                                prefetch={false}
                                noUnderline={!isDesktopView}
                                thinUnderline
                            >
                                {item.label.icon ? (
                                    <>
                                        <Icon
                                            size={24}
                                            icon={item.label.icon}
                                            stroke={
                                                item.label.iconColors?.stroke
                                            }
                                            fill={
                                                item.label.iconColors?.fill ||
                                                "transparent"
                                            }
                                        />
                                        {item.label.text && (
                                            <Label withIcon>
                                                {item.label.text}
                                            </Label>
                                        )}
                                    </>
                                ) : (
                                    item?.label?.text
                                )}
                            </Link>
                        )}
                    </LinkItem>
                );
            })}
            {showStoreSwitcher && (
                <LinkItem
                    key="Group-link-Child-last"
                    type={type}
                    theme={theme}
                    isSticky={isSticky}
                >
                    <StoreSwitcher theme={theme} isSticky={isSticky} />
                </LinkItem>
            )}
        </Container>
    );
}
