import { gql } from "@apollo/client";

export const GetProducts = gql`
    query GetProducts(
        $searchTerm: String
        $filter: ProductAttributeFilterInput
    ) {
        products(
            search: $searchTerm
            filter: $filter
            sort: { position: ASC }
        ) {
            items {
                uid
                id
                url_key
                ... on BundleProduct {
                    weight
                    media_gallery {
                        label
                        url
                    }
                    image {
                        url
                    }
                    short_description {
                        html
                    }
                    items {
                        uid
                        options {
                            uid
                            is_default
                            quantity
                            product {
                                name
                                sku
                                special_price
                                price_per_kilogram
                                image {
                                    url
                                }
                                categories {
                                    uid
                                    url_key
                                }
                                price_range {
                                    maximum_price {
                                        final_price {
                                            value
                                            currency
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SimpleProduct {
                    weight
                    price_per_kilogram
                    categories {
                        uid
                        url_key
                    }
                }
                price_range {
                    maximum_price {
                        final_price {
                            currency
                            value
                        }
                    }
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                    }
                }
                image {
                    url
                    label
                }
                name
                sku
            }
        }
    }
`;
