import {
    ApolloError,
    DefaultContext,
    InternalRefetchQueriesInclude,
    useQuery
} from "@apollo/client";
import { createContext, useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { GetCustomer } from "helpers/api/magento";

type CustomerContext = {
    customer?: Customer | null;
    loading: boolean;
    error?: ApolloError;
    context?: DefaultContext;
    refetchQueries: InternalRefetchQueriesInclude;
};

const CustomerContext = createContext<CustomerContext | null>(null);

export function useCustomer(): CustomerContext {
    const context = useContext(CustomerContext);

    if (!context) {
        throw new Error(
            "CustomerContext should be used within a CustomerProvidor"
        );
    }

    return context;
}

export function CustomerProvider({
    children
}: {
    children: JSX.Element | Array<JSX.Element>;
}): JSX.Element {
    const { data: session, status } = useSession();
    const sessionLoading = status === "loading";
    const router = useRouter();
    const accessToken = session?.accessToken;

    const context = useMemo(
        () => ({
            headers: {
                authorization: accessToken ? `Bearer ${accessToken}` : "",
                store: router.locale
            }
        }),
        [accessToken, router.locale]
    );
    const { data, loading, error } = useQuery(GetCustomer, {
        context,
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        skip: !accessToken
    });

    const refetchQueries = useMemo(
        () => [
            {
                context,
                query: GetCustomer
            }
        ],
        [context]
    );

    const value = useMemo(
        () => ({
            context,
            customer: data?.customer,
            error,
            loading: loading || sessionLoading,
            refetchQueries
        }),
        [
            context,
            error,
            loading,
            refetchQueries,
            data?.customer,
            sessionLoading
        ]
    );

    return (
        <CustomerContext.Provider value={value}>
            {children}
        </CustomerContext.Provider>
    );
}
