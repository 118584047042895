import { gql } from "@apollo/client";

export const GetProduct = gql`
    query GetProduct($searchTerm: String!) {
        products(filter: { url_key: { eq: $searchTerm } }) {
            items {
                name
                sku
                stock_status
                uid
                price_per_kilogram
                meta_title
                meta_description
                meta_keyword
                description {
                    html
                }
                benefits
                categories {
                    url_key
                }
                short_description {
                    html
                }
                image {
                    url
                }
                media_gallery {
                    url
                }
                price_range {
                    maximum_price {
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                        fixed_product_taxes {
                            amount {
                                value
                                currency
                            }
                            label
                        }
                    }
                    minimum_price {
                        regular_price {
                            value
                            currency
                        }
                        fixed_product_taxes {
                            amount {
                                value
                                currency
                            }
                            label
                        }
                    }
                }
                special_price
                price_tiers {
                    quantity
                    final_price {
                        value
                        currency
                    }
                }

                ... on SimpleProduct {
                    weight
                }
            }
        }
    }
`;
