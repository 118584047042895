import { gql } from "@apollo/client";

export const PlaceCompleteOrder = gql`
    mutation PlaceCompleteOrder(
        $cartId: String!
        $paymentMethodInput: PaymentMethodInput!
        $billingAddress: BillingAddressInput!
        $email: String!
        $shippingMethods: [ShippingMethodInput]!
    ) {
        setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
            cart {
                id
            }
        }
        setBillingAddressOnCart(
            input: { cart_id: $cartId, billing_address: $billingAddress }
        ) {
            cart {
                id
            }
        }
        setPaymentMethodOnCart(
            input: { cart_id: $cartId, payment_method: $paymentMethodInput }
        ) {
            cart {
                id
            }
        }
        setShippingMethodsOnCart(
            input: { cart_id: $cartId, shipping_methods: $shippingMethods }
        ) {
            cart {
                id
            }
        }
        placeOrder(input: { cart_id: $cartId }) {
            order {
                order_number
                mollie_payment_token
                mollie_redirect_url
            }
        }
    }
`;
