import { gql } from "@apollo/client";

export const ChangeCustomerPassword = gql`
    mutation changeCustomerPassword(
        $currentPassword: String!
        $newPassword: String!
    ) {
        changeCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
        ) {
            email
            firstname
            lastname
            taxvat
        }
    }
`;
