export const purchase = (
    order: MagentoOrder,
    allProducts: Array<MagentoProduct>,
    customer: any
): void => {
    window.dataLayer = window?.dataLayer || [];

    let flattenedItems: Array<{
        brand?: string;
        category?: string;
        id?: string;
        name?: string;
        price?: number;
        quantity?: number;
        variant?: string;
    }> | null = null;

    // For safety reasons, this try/catch block protects the app from crashing in case the data structures change.
    try {
        const skus = order.invoices.reduce(
            (acc: Array<string>, nextInvoice) => {
                const skuStringArr = nextInvoice.items
                    .map(item =>
                        // Here every package's generated SKU gets spliced so that only the products are left
                        removeUntilSecondDash(item.product_sku).split("-")
                    )
                    .flat(1);
                return [...acc, ...skuStringArr];
            },
            []
        );

        const preparedItems = skus
            .filter(sku => sku !== "package" && sku !== "main")
            .map(name => {
                const actualProduct = allProducts.find(
                    product =>
                        product.sku?.toLowerCase().includes(name) &&
                        !product.name?.toLowerCase().includes("pakket")
                );

                return {
                    brand: "Mr. Fillet",
                    category: "Bundle product",
                    id: actualProduct?.sku ?? name,
                    name: name,
                    price:
                        (actualProduct?.price_per_kilogram ?? 0) *
                        (actualProduct?.weight ?? 0),
                    quantity: 1,
                    variant: "1.5kg"
                };
            });

        // Aggregate similar items
        preparedItems.forEach(item => {
            item.quantity = preparedItems.filter(
                checkItem => checkItem.id === item.id
            ).length;
        });

        flattenedItems = [
            ...new Set(preparedItems.map(i => JSON.stringify(i)))
        ].map(i => JSON.parse(i));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
            "Error while preparing individual product data for `purchase` dataLayer event.",
            error
        );
    }

    window?.dataLayer?.push({
        ecommerce: {
            purchase: {
                actionField: {
                    affliation: "Webshop",
                    coupon: order?.invoices?.[0]?.discounts?.[0]?.label ?? "",
                    id: order?.id,
                    paymentMethod: order?.payment_methods?.[0]?.name,
                    revenue: order?.total?.grand_total?.value,
                    shipping: order?.total?.total_shipping?.value,
                    tax: order?.total?.total_tax?.value
                },
                customer: {
                    billingAddress: customer?.billingAddress,
                    email: customer?.email,
                    firstName: customer?.firstName,
                    lastName: customer?.lastName,
                    phoneNumber: customer?.phoneNumber,
                    shippingAddress: customer?.shippingAddress
                },
                products:
                    flattenedItems ??
                    // If preparing the individual product objects is not possible,
                    // default to the package data so that marketeers would have
                    // data in the worst case scenarios.
                    order?.invoices
                        ?.map(invoice =>
                            invoice?.items?.map((item: MagentoInvoiceItem) => ({
                                brand: "Mr. Fillet",
                                category: "Losse producten",
                                id: item?.product_sku,
                                name: item?.product_name,
                                price: item?.product_sale_price?.value,
                                quantity: item?.quantity_invoiced,
                                variant: ""
                            }))
                        )
                        .flat(1)
            }
        },
        event: "purchase"
    });
};

// Helps to remove package SKU from invoice SKU so that we can isolate the products
function removeUntilSecondDash(str: string): string {
    const toArr = str.split("");

    const indexOfFirst = toArr.indexOf("-");
    toArr.splice(indexOfFirst, 1);

    const indexOfSecond = toArr.indexOf("-");

    return toArr.splice(indexOfSecond + 1).join("");
}
