import styled from "styled-components";

import { media } from "constants/media";

export const Card = styled.article`
    margin: 0 auto 42px;
    max-width: calc(100% - 16px);
    min-width: 288px;

    ${media.mdUp} {
        margin: 0 0 64px;
        min-width: 344px;
        width: calc(33% - 13px);
    }
`;

export const StyledIcon = styled.span`
    align-items: center;
    background-color: var(--color-red-50);
    border-radius: 50%;
    bottom: 0;
    content: "";
    display: flex;
    height: 48px;
    justify-content: center;
    position: absolute;
    right: -12px;
    width: 48px;
    z-index: 1;

    circle,
    path {
        stroke: var(--color-beige-40);
    }

    ${media.mdUp} {
        bottom: 0;
        cursor: pointer;
        height: 64px;
        left: 0;
        margin: auto;
        opacity: 0;
        right: 0;
        top: 0;
        transition: opacity var(--default-transition-duration) ease-in-out;
        width: 64px;
    }
`;

export const ImageContainer = styled.div`
    height: 50vw;
    max-height: 200px;
    min-height: 160px;
    position: relative;
    width: calc(100% - 16px);

    ::after {
        background: url("/images/icons/types/fillet.png");
        background-size: cover;
        content: "";
        display: block;
        height: 64px;
        position: absolute;
        right: -24px;
        top: -24px;
        width: 64px;
        z-index: 5;
    }

    ${media.mdUp} {
        cursor: pointer;

        img {
            transition: transform var(--default-transition-duration) ease-in-out;
        }

        &:hover {
            ${StyledIcon} {
                opacity: 1;
                transition: opacity var(--default-transition-duration)
                    ease-in-out;
            }

            img {
                transform: scale(1.2);
                transition: transform var(--default-transition-duration)
                    ease-in-out;
            }
        }
    }
`;
export const Title = styled.p`
    color: var(--color-brown-50);
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 8px 0;
    position: relative;

    ${media.mdUp} {
        cursor: pointer;
    }
`;

export const Info = styled.div`
    align-items: center;
    color: var(--color-brown-50);
    display: flex;
    padding-top: 16px;
    position: relative;

    p {
        align-items: center;
        display: flex;
    }
`;
export const Duration = styled.p<{ isRecipeDetail?: boolean }>`
    color: ${({ isRecipeDetail }) =>
        isRecipeDetail ? "var(--color-beige-50)" : "var(--color-brown-50)"};
    gap: 8px;
    margin-right: 16px;
`;

export const Amount = styled(Duration)``;

export const Tag = styled(Duration)`
    margin: 0 16px 0 auto;
`;

export const Decoration = styled.div`
    height: 2px;
    left: -40px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 120%;

    ${media.mdUp} {
        top: 0;
    }
`;
