import styled, { css } from "styled-components";

import { headerThemeOptions } from "components/layout/Layout";
import Dropdown from "components/shared/dropdown/Dropdown";

import { media } from "constants/media";

function selectHeaderItemsColor(
    theme: headerThemeOptions.light | headerThemeOptions.dark,
    isSticky: boolean
) {
    switch (theme) {
        case headerThemeOptions.light:
            return css`
                color: var(--color-brown-50);
            `;
        case headerThemeOptions.dark:
            return css`
                color: ${isSticky
                    ? " var(--color-brown-40)"
                    : " var(--color-beige-40)"};
            `;
        default:
            return css`
                color: var(--color-beige-40);
            `;
    }
}

export const StyledDropdown = styled(Dropdown)<{
    theme?:
        | headerThemeOptions.light
        | headerThemeOptions.dark
        | headerThemeOptions.recipe;
    isSticky: boolean;
}>`
    background-color: transparent;
    border-width: 1px;
    height: auto;
    margin: 0 auto;
    min-height: auto;
    padding: 4px 8px;
    width: auto;

    /* stylelint-disable-next-line order/properties-alphabetical-order */
    ${({ theme, isSticky }) =>
        theme && selectHeaderItemsColor(theme, isSticky)};

    :hover {
        border-color: var(--color-white);
        border-width: 1px;
    }

    > span {
        line-height: 0;
    }

    + div {
        background-color: transparent;
        border-color: var(--color-white);
        border-width: 1px;
        top: 33px;
        width: auto;

        div {
            background-color: var(--color-white);
            overflow: hidden;

            button {
                background-color: transparent;
                color: inherit;
                line-height: 0;
                text-overflow: unset;
                white-space: nowrap;
                width: auto;
            }
        }
    }

    ${media.mdUp} {
        height: auto;
        min-height: auto;

        :hover {
            border-color: var(--color-brown-40);
            border-width: 1px;
        }

        + div {
            border-color: var(--color-brown-40);

            div {
                border-top-width: 1px;
            }
        }
    }
`;

export const OptionLabelWrapper = styled.span`
    align-items: center;
    display: inline-flex;
    gap: 8px;
    line-height: 0;
`;
