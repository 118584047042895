import styled, { css } from "styled-components";

interface StyledInputProps {
    disabled?: boolean;
    disabledColor?: string;
    hasIcon: boolean;
    hasError: boolean;
    maxLength?: number;
    isValid?: boolean;
}

export const StyledLabel = styled.label<{ disabled: boolean }>`
    color: var(--color-brown-50);
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    position: relative;
`;

export const LabelWrapper = styled.span<{ noWrap?: boolean }>`
    font-weight: 500;

    ${({ noWrap }) =>
        noWrap &&
        css`
            white-space: nowrap;
        `}
`;

export const IconWrapper = styled.div`
    height: 20px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

export const InputWrapper = styled.div`
    position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
    background-color: var(--color-white);
    border: 2px solid transparent;
    color: var(--color-brown-50);
    line-height: 32px;
    padding: 16px 24px;
    transition-duration: var(--default-transition-duration);
    transition-property: box-shadow, border-color, background-color, color;
    transition-timing-function: ease;
    width: 100%;

    + ${IconWrapper} {
        color: var(--color-brown-50);
    }

    &:not(:placeholder-shown) {
        background-color: var(--color-white);
    }

    &::placeholder {
        color: var(--color-beige-60);
    }

    &:hover {
        border: 2px solid var(--color-brown-50);
    }

    &:focus {
        background-color: var(--color-white);
        border: 2px solid var(--color-brown-50);
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        background-color: var(--color-beige-45);
        border: 2px solid transparent;
        color: ${({ disabledColor }) =>
            disabledColor || " var(--color-beige-60)"};
        cursor: not-allowed;
    }

    &:placeholder-shown + ${IconWrapper} {
        color: var(--color-beige-60);
    }

    &:disabled + ${IconWrapper} {
        color: var(--color-brown-50);
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ hasError }) =>
        hasError &&
        css`
            background-color: var(--color-red-50);
            border: 2px solid var(--color-red-50);

            &:hover {
                border: 2px solid var(--color-red-50);
            }

            &:focus,
            &:active {
                background-color: var(--color-white);
            }
        `};

    ${({ isValid }) =>
        isValid &&
        css`
            background-color: var(--color-white);
            border: 2px solid var(--color-green-50);
            color: var(--color-green-50);

            &:hover {
                border: 2px solid var(--color-green-50);
            }

            &:focus,
            &:active {
                border: 2px solid var(--color-green-50);
            }
        `};

    ${({ hasIcon }) =>
        hasIcon &&
        css`
            padding-right: 40px;
        `}; /* stylelint-enable order/order */
    /* stylelint-enable order/properties-alphabetical-order */
`;

export const StyledErrorMessage = styled.span`
    color: var(--color-red-50);
    font-size: var(--type-sub-text-font-size-sub-text);
    line-height: var(--type-sub-text-line-height);
    margin-top: 4px;
`;
