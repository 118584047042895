import styled, { css } from "styled-components";

import { media } from "constants/media";

export const Container = styled.nav<{ isOpen: boolean }>`
    background-color: var(--color-brown-50);
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    padding: 30px 24px;
    position: fixed;
    text-align: center;
    top: 0;
    transform: translateX(-100%);
    transition: 0.5s all ease-in-out;
    width: 0;
    z-index: 30;

    ${media.lgUp} {
        display: none;
    }

    /* stylelint-disable order/order */
    /* stylelint-disable order/properties-alphabetical-order */
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: translateX(0px);
            width: 100%;
        `}/* stylelint-enable order/order */ /* stylelint-enable order/properties-alphabetical-order */
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
`;

export const BasketWrapper = styled.div`
    ul {
        li:first-child {
            display: none;
        }

        li:nth-child(2) {
            svg {
                color: var(--color-beige-50);
            }
        }
    }
`;

export const CloseButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;

    > svg > path {
        &:first-of-type {
            stroke: var(--color-beige-50);
        }

        &:last-of-type {
            fill: var(--color-beige-50);
        }
    }
`;

export const Decoration = styled.div`
    display: block;
    left: -65px;
    margin: auto;
    opacity: 0.24;
    position: absolute;
    right: -65px;
    top: 20px;
    width: 448px;
    z-index: 0;
`;

export const NavigationDesktop = styled.nav`
    display: flex;
    height: 110px;
    justify-content: space-between;
    margin: auto;
    max-width: 1440px;
    padding: 30px 24px 44px;
    padding: 8px 120px 0;
    position: relative;
    width: 100%;
`;
