import {
    DefaultContext,
    InternalRefetchQueriesInclude,
    useMutation,
    useQuery
} from "@apollo/client";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import {
    AddProductsToCart,
    CreateEmptyCart,
    GetCustomerCart,
    GetGuestCart,
    RemoveItemFromCart,
    SetShippingAddressesOnCart,
    UpdateCartQuantity
} from "helpers/api/magento";
import { CART_MAX_AGE_SECONDS } from "constants/util";
import { MergeCarts } from "helpers/api/magento/mutations/mergeCarts";
import { useSuccessModal } from "helpers/context/SuccessModalContext";

export interface AddressInput {
    addressId?: number;
    address?: {
        city: string;
        firstName: string;
        lastName: string;
        postalCode: string;
        street: string;
        houseNumber: string;
        box?: string;
        phoneNumber: string;
        country_code: string;
    };
}

interface CartItemInput {
    cart_item_uid?: string;
    quantity?: number;
    selected_options?: Array<string | undefined>;
    sku?: string;
}

export interface Cart {
    cartId?: string | null;
    updateCartItem: (item: CartItemInput | undefined) => Promise<unknown>;
    addProductsToCart: (
        items: Array<CartItemInput | undefined>,
        showModal?: boolean
    ) => Promise<unknown>;
    removeItemfromCart: (id?: string | undefined) => Promise<unknown>;
    setShippingAddress: (address: AddressInput) => Promise<unknown>;
    cartItemsCount: number;
    items: Array<BundledCartItem | CartItem>;
    coupons?: Array<{ code: string }>;
    total?: number | null;
    prices?: Prices | null;
    shippingAddress?: MagentoAddress | null;
    paymentMethods?: Array<MagentoPaymentMethod>;
    shippingMethods?: Array<MagentoShippingMethod>;
    refetchQueries: InternalRefetchQueriesInclude | [];
    error?: string | null;
    accessToken?: unknown | null;
    loading?: boolean;
    context?: DefaultContext | null;
}

const CartContext = createContext<Cart>({
    accessToken: null,
    addProductsToCart: async () => null,
    cartId: null,
    cartItemsCount: 0,
    context: null,
    coupons: [],
    error: null,
    items: [],
    loading: false,
    paymentMethods: [],
    prices: null,
    refetchQueries: [],
    removeItemfromCart: async () => null,
    setShippingAddress: async () => null,
    shippingAddress: null,
    shippingMethods: [],
    total: 0,
    updateCartItem: async () => null
});

export function useCartContext(): Cart {
    const context = useContext(CartContext);

    if (!context) {
        throw new Error(
            "ShoppingCartContext should be used within CartProivder"
        );
    }

    return context;
}

export function CartProvider({
    children
}: {
    children: JSX.Element | Array<JSX.Element>;
}): JSX.Element {
    const { data: session } = useSession();

    const { setShow } = useSuccessModal();
    const accessToken = session?.accessToken;
    const [cookies, setCookie] = useCookies(["cartId"]);
    const cartId = useMemo(() => cookies?.cartId, [cookies]);
    const router = useRouter();
    const context = useMemo(
        () => ({
            headers: {
                authorization: accessToken ? "Bearer " + accessToken : "",
                store: router.locale
            }
        }),
        [accessToken, router.locale]
    );

    const refetchQueries = useMemo(
        () =>
            accessToken
                ? [
                      {
                          context,
                          query: GetCustomerCart
                      }
                  ]
                : [
                      {
                          context,
                          query: GetGuestCart,
                          variables: { cartId }
                      }
                  ],
        [cartId, accessToken, context]
    );

    const {
        data: guestCart,
        error: guestCartError,
        loading: guestCartLoading
    } = useQuery(GetGuestCart, {
        context,
        fetchPolicy: "cache-and-network",
        skip: !!accessToken || !cartId,
        variables: { cartId }
    });

    const {
        data: customerCart,
        error: customerCartError,
        loading: customerCartLoading
    } = useQuery(GetCustomerCart, {
        context,
        fetchPolicy: "cache-and-network",
        skip: !accessToken
    });

    const customerCartId = customerCart?.customerCart?.id;

    const [handleMergeCarts] = useMutation(MergeCarts, {
        context,
        refetchQueries
    });

    const [
        createEmptyCart,
        { data: emptyCart, error: emptyCartError, loading: emptyCartLoading }
    ] = useMutation(CreateEmptyCart);

    useEffect(() => {
        if (
            !!accessToken &&
            !customerCartLoading &&
            customerCartId &&
            (cartId !== customerCartId || !cartId)
        ) {
            handleMergeCarts({
                variables: {
                    destinationCartId: customerCartId,
                    sourceCartId: cartId
                }
            });

            setCookie("cartId", customerCartId, {
                maxAge: CART_MAX_AGE_SECONDS,
                path: "/"
            });
        }
    }, [
        cartId,
        createEmptyCart,
        emptyCart,
        accessToken,
        customerCartId,
        setCookie,
        customerCartLoading,
        handleMergeCarts
    ]);

    const [handleRemoveItemfromCart] = useMutation(RemoveItemFromCart, {
        context,
        refetchQueries
    });

    const [handleAddProductsToCart] = useMutation(AddProductsToCart, {
        context,
        refetchQueries
    });

    const [handleUpdateCartItem] = useMutation(UpdateCartQuantity, {
        context,
        refetchQueries
    });

    const [
        setShippingAddressesOnCart,
        { loading: setShippingLoading, error: setShippingError }
    ] = useMutation(SetShippingAddressesOnCart, { context, refetchQueries });

    const cart = accessToken ? customerCart?.customerCart : guestCart?.cart;

    const createEmptyCartIfEmpty = useCallback(async () => {
        async function asyncCreateEmptyCart() {
            return await createEmptyCart();
        }
        let cookie = null;

        if (!accessToken && (!cartId || !!guestCartError)) {
            await asyncCreateEmptyCart().then(result => {
                if (result?.data?.createEmptyCart) {
                    cookie = result.data.createEmptyCart;
                    setCookie("cartId", cookie, {
                        maxAge: CART_MAX_AGE_SECONDS,
                        path: "/"
                    });
                }
            });
        }

        return cookie;
    }, [accessToken, cartId, createEmptyCart, guestCartError, setCookie]);

    const addProductsToCart = useCallback(
        async (
            cartItems: Array<BundledCartItem | CartItem | undefined>,
            showModal?: boolean
        ) => {
            const newCartId = await createEmptyCartIfEmpty();

            await handleAddProductsToCart({
                variables: { cartId: newCartId ?? cartId, cartItems }
            }).then(() => setShow(showModal === undefined ? true : showModal));
        },
        [cartId, handleAddProductsToCart, setShow, createEmptyCartIfEmpty]
    );

    const updateCartItem = useCallback(
        async (item: CartItemInput | undefined) => {
            await handleUpdateCartItem({
                variables: {
                    input: {
                        cart_id: cartId,
                        cart_items: [item]
                    }
                }
            });
        },
        [cartId, handleUpdateCartItem]
    );

    const removeItemfromCart = useCallback(
        async (id: string | undefined) => {
            return await handleRemoveItemfromCart({
                variables: {
                    input: {
                        cart_id: cartId,
                        cart_item_uid: id
                    }
                }
            });
        },
        [cartId, handleRemoveItemfromCart]
    );

    const setShippingAddress = useCallback(
        async ({ addressId, address }: AddressInput) => {
            let item;

            if (addressId) {
                item = {
                    customer_address_id: addressId || undefined
                };
            }

            if (address) {
                item = {
                    address: {
                        city: address?.city,
                        country_code: address?.country_code,
                        firstname: address?.firstName,
                        lastname: address?.lastName,
                        postcode: address?.postalCode,
                        street: [
                            address?.street,
                            address?.houseNumber,
                            ` ${address?.box}`
                        ],
                        telephone: address?.phoneNumber || "-"
                    }
                };
            }

            return setShippingAddressesOnCart({
                variables: {
                    cartId,
                    shippingAddress: [item]
                }
            });
        },
        [cartId, setShippingAddressesOnCart]
    );
    const error =
        emptyCartError?.message ||
        guestCartError?.message ||
        customerCartError?.message ||
        setShippingError?.message;

    const loading =
        emptyCartLoading ||
        customerCartLoading ||
        guestCartLoading ||
        setShippingLoading;
    const value = useMemo(
        () => ({
            accessToken,
            addProductsToCart,
            cartId,
            cartItemsCount: cart?.total_quantity ?? 0,
            context,
            coupons: cart?.applied_coupons,
            error,
            items: cart?.items,
            loading,
            paymentMethods: cart?.available_payment_methods ?? [],
            prices: cart?.prices,
            refetchQueries,
            removeItemfromCart,
            setShippingAddress,
            shippingAddress: cart?.shipping_addresses?.[0],
            shippingMethods:
                cart?.shipping_addresses?.[0]?.available_shipping_methods ?? [],
            updateCartItem
        }),
        [
            addProductsToCart,
            updateCartItem,
            setShippingAddress,
            removeItemfromCart,
            cartId,
            accessToken,
            cart?.items,
            context,
            cart?.total_quantity,
            cart?.applied_coupons,
            cart?.prices,
            cart?.available_payment_methods,
            cart?.shipping_addresses,
            error,
            loading,
            refetchQueries
        ]
    );

    return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
}
