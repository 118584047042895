import { PRODUCT_TYPES } from "constants/enum";

// Category UIDs for cross sell products. Test and Acc are different from each other.
export const CROSS_SELL_PRODUCTS_CATEGORY_UIDS = ["MTY=", "MTQ="];

export function GetSimpleProducts(
    items?: Array<MagentoProduct> | [] | null
): Array<MagentoProduct> | [] {
    return (
        items?.filter(
            item =>
                (item?.__typename === PRODUCT_TYPES.SIMPLE_PRODUCT &&
                    !item.categories?.some(category =>
                        CROSS_SELL_PRODUCTS_CATEGORY_UIDS.includes(
                            category?.uid
                        )
                    )) ??
                []
        ) ?? []
    );
}

export function GetBundledProducts(
    items?: Array<MagentoProduct> | [] | null
): Array<MagentoProduct> | [] {
    return (
        items?.filter(
            item => item?.__typename === PRODUCT_TYPES.BUNDLED_PRODUCT
        ) ?? []
    );
}
