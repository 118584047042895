import { gql } from "@apollo/client";

export const GetCustomerAddresses = gql`
    query GetCustomerAddresses {
        customer {
            addresses {
                id
                city
                country_code
                street
                postcode
                default_billing
                default_shipping
                telephone
            }
        }
    }
`;
