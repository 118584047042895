import ChickenRollade from "public/images/icons/types/chicken-rollade.png";
import ChickenTenderloin from "public/images/icons/types/chicken-tenderloin.png";
import Chop from "public/images/icons/types/chop.png";
import DrumStick from "public/images/icons/types/drumstick.png";
import Fillet from "public/images/icons/types/fillet.png";
import Image from "next/image";
import SmallBlocks from "public/images/icons/types/small-blocks.png";
import Thigh from "public/images/icons/types/thigh.png";
import ThighStrips from "public/images/icons/types/thigh-strips.svg";
import WholeChicken from "public/images/icons/types/whole-chicken.png";
import Wings from "public/images/icons/types/wings.png";

import { CROSS_SELL_PRODUCTS_CATEGORY_UIDS } from "helpers/transform/product";
import { CUT_TYPES } from "constants/enum";

export const MAX_CROSS_SELL_PRODUCTS = 20;

export function isCrossSellProduct(
    item: BundledCartItem | CartItem | undefined
): boolean {
    return (
        item?.product?.categories?.some(category =>
            CROSS_SELL_PRODUCTS_CATEGORY_UIDS.includes(category.uid)
        ) ?? false
    );
}

export function getProductTypeImage(
    type?: string,
    width?: number,
    height?: number
): JSX.Element {
    if (type?.includes(CUT_TYPES.WHOLECHICKEN)) {
        return (
            <Image
                src={WholeChicken}
                width={width}
                height={height}
                alt="Hele kip icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.CHICKENROLLADE)) {
        return (
            <Image
                src={ChickenRollade}
                width={width}
                height={height}
                alt="Kipdijrollade icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.THIGHSTRIPS)) {
        return (
            <Image
                src={ThighStrips}
                width={width}
                height={height}
                alt="Dijenreepjes icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.CHOP)) {
        return (
            <Image
                src={Chop}
                width={width}
                height={height}
                alt="Kipkarbonade icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.FILET)) {
        return (
            <Image
                src={Fillet}
                width={width}
                height={height}
                alt="Kipfillet icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.DRUMSTICK)) {
        return (
            <Image
                src={DrumStick}
                width={width}
                height={height}
                alt="Drumstick icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.TIGHS || "kippendij")) {
        return (
            <Image
                src={Thigh}
                width={width}
                height={height}
                alt="Kippendij icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.WINGS)) {
        return (
            <Image
                src={Wings}
                width={width}
                height={height}
                alt="Kipvleugels icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.CHICKENTENDERLOOIN)) {
        return (
            <Image
                src={ChickenTenderloin}
                width={width}
                height={height}
                alt="Kiphaasjes icoon"
                loading="lazy"
            />
        );
    }

    if (type?.includes(CUT_TYPES.SMALLBLOCKS)) {
        return (
            <Image
                src={SmallBlocks}
                width={width}
                height={height}
                alt="Smallblocks icoon"
                loading="lazy"
            />
        );
    }

    return (
        <Image
            src={Wings}
            width={width}
            height={height}
            alt="Drumstick icoon"
            loading="lazy"
        />
    );
}
