import { createContext, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { GetProducts } from "helpers/api/magento";

interface ProductsContextInterface {
    products?: Array<MagentoProduct> | [] | null;
}

interface ProductsContextProviderProps {
    children: JSX.Element | Array<JSX.Element>;
}

const ProductsContext = createContext<ProductsContextInterface>({
    products: []
});

export function ProductsProvider({
    children
}: ProductsContextProviderProps): JSX.Element {
    const { data } = useQuery(GetProducts, {
        variables: { filter: {} }
    });

    const value = useMemo(
        () => ({
            products: data?.products?.items ?? []
        }),
        [data?.products]
    );

    return (
        <ProductsContext.Provider value={value}>
            {children}
        </ProductsContext.Provider>
    );
}

export function useProducts(): ProductsContextInterface {
    const context = useContext(ProductsContext);

    if (!context) {
        throw new Error("useProducts must be called within a ProductsProvider");
    }

    return context;
}
