import { gql } from "@apollo/client";

export const UpdateCustomerAddress = gql`
    mutation UpdateCustomerAddress($id: Int!, $input: CustomerAddressInput!) {
        updateCustomerAddress(id: $id, input: $input) {
            id
            city
            street
            postcode
            default_billing
            default_shipping
        }
    }
`;
